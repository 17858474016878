import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
interface ImageCarouselProps {
	images: any[];
}

export const ImageCarousel = ({ images }: ImageCarouselProps) => {
	return (
		<div className="mb-12">
			<Swiper
				spaceBetween={30}
				centeredSlides={true}
				pagination={{
					clickable: true,
				}}
				modules={[Pagination]}
			>
				{images?.map((image) => (
					<SwiperSlide key={image?.id}>
						<div className="relative h-[400px]">
							<img
								className="absolute top-0 left-0 w-full h-full object-cover"
								src={image?.image_url}
								alt={image?.event_id}
							/>
						</div>
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	);
};
