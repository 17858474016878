import { Event, Order, OrderResponse } from "src/interface";
import { TicketingPurchaseForm } from "./TicketingPurchaseForm";
import { TicketingConfirmationForm } from "./TicketingConfirmationForm";
import { TicketingDetailForm } from "./TicketingDetailForm";
import { TicketingPaymentForm } from "./TicketingPaymentForm";
import { useState } from "react";

interface TicketingFormProps {
  currentStep: number;
  data: Event;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  formState: Order;
  setFormState: React.Dispatch<React.SetStateAction<Order>>;
}

export const TicketingForm = ({
  currentStep,
  data,
  setCurrentStep,
  setFormState,
  formState,
}: TicketingFormProps) => {
  const [orderResponse, setOrderResponse] = useState<OrderResponse>(null);

  switch (currentStep) {
    case 3:
      return (
        <TicketingConfirmationForm
          data={data}
          setCurrentStep={setCurrentStep}
          formState={formState}
          setOrderResponse={setOrderResponse}
        />
      );
    case 2:
      return (
        <TicketingDetailForm
          setCurrentStep={setCurrentStep}
          formState={formState}
          data={data}
          setFormState={setFormState}
        />
      );
    case 4:
      return <TicketingPaymentForm orderResponse={orderResponse} />;
    default:
      return (
        <TicketingPurchaseForm
          data={data}
          setCurrentStep={setCurrentStep}
          setFormState={setFormState}
          formState={formState}
        />
      );
  }
};
