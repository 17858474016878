import { useEffect, useState } from "react";
import { DetailNews } from "./detail-news";
import { connectAPI } from "../../api/api";
import { INewsItem } from "../../interface/generic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faExclamationCircle } from "@fortawesome/pro-duotone-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { handleApiError } from "../../functions/global-functions";
import { Loading } from "src/components/web";

const ManageNews = () => {
	let [openNewsPopup, setOpenNewsPopup] = useState(false);
	let [newsList, setNewsList] = useState([]);
	let [viewNewsItem, setViewNewsItem] = useState();
	let [popupType, setPopupType] = useState("");
	let [isLoading, setIsLoading] = useState(false);

	const getLatestNews = () => {
		setIsLoading(true);
		connectAPI(`partner/news`, "", "get", {
			authorization: `Bearer ${localStorage.getItem("token")}`,
		}).then((res) => {
			if (res.status === 200) {
				const data = res.data.data;
				setNewsList(data);
				setIsLoading(false);
			} else {
				setIsLoading(false);
				const data = res.data.data;
				handleApiError(data);
			}
		});
	};

	const removeNews = (id) => {
		setIsLoading(true);
		connectAPI(`partner/news/${id}`, "", "delete", {
			authorization: `Bearer ${localStorage.getItem("token")}`,
		}).then((res) => {
			if (res.status === 200) {
				getLatestNews();
			} else {
				setIsLoading(false);
			}
		});
	};

	const viewNews = (id) => {
		let itemNews;
		newsList.forEach((item: INewsItem) => {
			if (item.id === id) return (itemNews = item);
		});
		setOpenNewsPopup(true);
		setPopupType("edit");
		setViewNewsItem(itemNews);
	};

	useEffect(() => {
		getLatestNews();
		setPopupType("");
	}, []);

	return (
		<div>
			<div className="sm:flex sm:items-center">
				<div className="sm:flex-auto">
					<h1 className="text-3xl font-medium leading-6 text-gray-900">News</h1>
					<p className="mt-2 text-sm text-gray-700">Manage news disini untuk add, delete dan update.</p>
				</div>
				<div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
					<button
						type="button"
						onClick={() => {
							setOpenNewsPopup(true);
							setPopupType("add");
							setViewNewsItem(null);
						}}
						className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
					>
						Tambah news
					</button>
				</div>
			</div>
			<div className="mt-8 flow-root">
				<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div className="max-w-screen-2xl	mx-auto py-2 sm:px-6 lg:px-8">
						<ul className="divide-y divide-gray-300">
							{isLoading ? (
								<Loading className="mt-2" />
							) : newsList.length >= 1 ? (
								newsList.map((item: INewsItem) => (
									<li key={item.id} className="relative flex justify-between py-5">
										<div className="flex gap-x-4 pr-6 sm:w-1/2 sm:flex-none">
											<img className="h-12 w-12 flex-none rounded-full bg-gray-50" src={item.image_url} alt="" />
											<div className="min-w-0 flex-auto">
												<p className="text-sm font-medium leading-6 text-gray-900">
													<p>{item.title}</p>
												</p>
												<p className="mt-1 flex text-xs leading-5 text-gray-500">
													<p className="relative line-clamp-2">{item.description}</p>
												</p>
											</div>
										</div>
										<div className="flex items-center justify-between gap-x-4 sm:w-1/2 sm:flex-none">
											<div className="hidden sm:block">
												{item.status_id !== 1 ? (
													<span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">
														Inactive
													</span>
												) : (
													<span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
														Active
													</span>
												)}
											</div>
											<div className="flex items-center">
												<FontAwesomeIcon icon={faCalendarAlt as IconProp} className="text-md text-purple-900 mr-2" />
												<p className="text-sm text-gray-500">{item.date}</p>
											</div>
											<div>
												<button
													onClick={() => viewNews(item.id)}
													className="rounded-full bg-white px-2.5 py-1 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 me-2"
												>
													Edit<span className="sr-only">, {item.title}</span>
												</button>
												<button
													onClick={() => removeNews(item.id)}
													className="rounded-full bg-white px-2.5 py-1 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
												>
													Delete
													<span className="sr-only">, {item.title}</span>
												</button>
											</div>
										</div>
									</li>
								))
							) : (
								<p className="text-sm flex items-center mt-3">
									<FontAwesomeIcon icon={faExclamationCircle as IconProp} className="mr-2 text-lg text-red-600" aria-hidden="true" />
									Belum ada news
								</p>
							)}
						</ul>
					</div>
				</div>
			</div>
			{openNewsPopup && <DetailNews popupType={popupType} setEditNews={setOpenNewsPopup} editNews={openNewsPopup} newsItem={viewNewsItem} getLatestNews={getLatestNews} />}
		</div>
	);
};

export default ManageNews;
