import { City, Province, SubDistrict, Village } from "src/interface";
import { MobileNumberComponent, SelectComponent, TextAreaComponent, TextComponent } from "./DetailInputComponent";
import { UseFormRegister } from "react-hook-form";
import { Sort } from "src/utils";

interface BuyerFormProps {
	register: UseFormRegister<any>;
	errors?: any;
	city?: City[];
	province?: Province[];
	subdistrict?: SubDistrict[];
	village?: Village[];
	setValue: any;
	getValues: any;
}

export const BuyerForm = ({ register, errors, city, province, subdistrict, village, setValue, getValues }: BuyerFormProps) => {
	return (
		<div className="p-8 mb-8 rounded-md bg-slate-100">
			<TextComponent
				title={"Nama Lengkap"}
				id={"buyer.full_name"}
				register={register("buyer.full_name")}
				name={"buyer.full_name"}
				type={"text"}
				errors={(errors?.buyer?.full_name?.message as string) || undefined}
			/>
			<TextComponent
				title={"Nomor KTP"}
				id={"buyer.number_identity_card"}
				register={register("buyer.number_identity_card")}
				name={"buyer.number_identity_card"}
				type={"text"}
				maxLength={16}
				errors={(errors?.buyer?.number_identity_card?.message as string) || undefined}
			/>
			<MobileNumberComponent
				title={"Nomor Handphone"}
				id={"buyer.mobile_number"}
				setValue={setValue}
				getValues={getValues}
				maxLength={14}
				name={"buyer.mobile_number"}
				errors={(errors?.buyer?.mobile_number?.message as string) || undefined}
			/>

			<TextComponent title={"Email"} id={"buyer.email"} register={register("buyer.email")} name={"buyer.email"} type={"email"} errors={(errors?.buyer?.email?.message as string) || undefined} />
			<TextAreaComponent title={"Alamat"} id={"buyer.address"} register={register("buyer.address")} name={"buyer.address"} errors={(errors?.buyer?.address?.message as string) || undefined} />
			<SelectComponent<Province>
				title={"Provinsi"}
				id={"buyer.provinsi_id"}
				register={register("buyer.provinsi_id", { valueAsNumber: true })}
				name={"buyer.provinsi_id"}
				errors={(errors?.buyer?.provinsi_id?.message as string) || undefined}
				optiondata={Sort(province, "name")}
				keyname="name"
				keyvalue="id"
			/>
			<SelectComponent<City>
				title={"Kota"}
				id={"buyer.kota_id"}
				register={register("buyer.kota_id", { valueAsNumber: true })}
				name={"buyer.kota_id"}
				errors={(errors?.buyer?.kota_id?.message as string) || undefined}
				optiondata={Sort(city, "name")}
				keyname="name"
				keyvalue="id"
				disabled={!!!city?.length}
			/>
			<SelectComponent<SubDistrict>
				title={"Kecamatan"}
				id={"buyer.kecamatan_id"}
				register={register("buyer.kecamatan_id", { valueAsNumber: true })}
				name={"buyer.kecamatan_id"}
				errors={(errors?.buyer?.kecamatan_id?.message as string) || undefined}
				optiondata={Sort(subdistrict, "name")}
				keyname="name"
				keyvalue="id"
				disabled={!!!subdistrict?.length}
			/>
			<SelectComponent<Village>
				title={"Kelurahan"}
				id={"buyer.kelurahan_id"}
				register={register("buyer.kelurahan_id", { valueAsNumber: true })}
				name={"buyer.kelurahan_id"}
				errors={(errors?.buyer?.kelurahan_id?.message as string) || undefined}
				optiondata={Sort(village, "name")}
				keyname="name"
				keyvalue="id"
				disabled={!!!village?.length}
			/>
		</div>
	);
};
