const CryptoJS = require("crypto-js");

export const EncryptAES = (message: string) => {
  return CryptoJS.AES.encrypt(message, process.env.REACT_APP_SECRET_KEY);
};

export const DecryptAES = (secretMessage: string) => {
  const bytes = CryptoJS.AES.decrypt(
    secretMessage,
    process.env.REACT_APP_SECRET_KEY
  );

  return bytes.toString(CryptoJS.enc.Utf8);
};
