import { Dialog, Transition } from "@headlessui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { Fragment, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { toast } from "react-toastify";
import { Loading } from "src/components/global";
import { apiPostDiscountVoucher, apiPutDiscountVoucher } from "src/services/AccountingService";
import { z } from "zod";

interface ModalDiscountVoucherProps {
	show?: boolean;
	loading?: boolean;
	data?: any;
	refetch?: any;
	onClose?: any;
}

const DiscountVoucherSchema = z.object({
	title: z.string().nonempty("Nama voucher wajib diisi"),
	serial: z.string().nonempty("Serial wajib dipilih"),
	start_expired_at: z.string().nonempty("Tanggal mulai wajib dipilih"),
	end_expired_at: z.string().nonempty("Tanggal berakhir wajib dipilih"),
	quota: z.number(),
	type: z.enum(["fix", "free"]),
	type_value: z.enum(["percentage", "amount", "none"]),
	value: z.number(),
	is_unix: z.boolean(),
	status_id: z.boolean(),
});

type DiscountVoucherForm = z.infer<typeof DiscountVoucherSchema>;

export const ModalDiscountVoucher = ({ show, loading, data, refetch, onClose }: ModalDiscountVoucherProps) => {
	const [currValue, setCurrValue] = useState({
		type_value: data?.type_value || "percentage",
		value: data?.value || 0,
	});
	const defaultValue: DiscountVoucherForm = {
		title: data?.title || "",
		serial: data?.serial || "",
		start_expired_at: data?.start_expired_at || "",
		end_expired_at: data?.end_expired_at || "",
		quota: data?.quota || 0,
		type: data?.type || "fix",
		type_value: data?.type_value || "percentage",
		value: data?.value || 0,
		is_unix: data?.is_unix || 0,
		status_id: data?.status_id || 0,
	};

	const { mutate: postDiscountVoucher, isLoading: loadingPost } = useMutation(
		async (data: any) => await apiPostDiscountVoucher({ data })
	);

	const { mutate: putDiscountVoucher, isLoading: loadingPut } = useMutation(
		async ({ data, id }: { data: any; id: string }) => await apiPutDiscountVoucher({ data, id })
	);

	const {
		register,
		control,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm<DiscountVoucherForm>({
		resolver: zodResolver(DiscountVoucherSchema),
		defaultValues: defaultValue,
	});

	const type = useWatch({
		control,
		name: "type",
	});

	const type_value = useWatch({
		control,
		name: "type_value",
	});

	const value = useWatch({
		control,
		name: "value",
	});

	useEffect(() => {
		if (type === "free") {
			setCurrValue({
				type_value,
				value,
			});
			setValue("type_value", "none");
			setValue("value", 0);
		} else {
			setValue("type_value", currValue?.type_value === "none" ? "percentage" : currValue?.type_value);
			setValue("value", currValue?.value);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [type]);

	return (
		<>
			<Transition appear show={show} as={Fragment}>
				<Dialog
					as="div"
					className="relative z-10"
					onClose={() => {
						onClose?.();
					}}
				>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-black bg-opacity-25" />
					</Transition.Child>

					{loading && <Loading />}
					<div className="fixed inset-0 overflow-y-auto">
						<div className="flex min-h-full items-center justify-center p-4 text-center">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95"
							>
								<Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
									<div className=" flex content-between items-center justify-between">
										<Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
											{data ? "Ubah" : "Tambah"} Diskon & Kupon
										</Dialog.Title>
									</div>
									<div>
										<form
											onSubmit={handleSubmit((value: DiscountVoucherForm) => {
												data
													? putDiscountVoucher(
															{
																data: {
																	...value,
																	serial:
																		data?.serial === value?.serial
																			? undefined
																			: value?.serial,
																},
																id: data?.id,
															},
															{
																onSuccess: (res: any) => {
																	toast.success("Diskon & Kupon berhasil diubah");
																	refetch?.();
																	onClose?.();
																},
																onError: (res: any) => {
																	toast.error(
																		res.data.message || "Terjadi kesalahan"
																	);
																},
															}
													  )
													: postDiscountVoucher(value, {
															onSuccess: (res: any) => {
																toast.success("Diskon & Kupon berhasil dibuat");
																refetch();
																onClose?.();
															},
															onError: (res: any) => {
																toast.error(res.data.message || "Terjadi kesalahan");
															},
													  });
											})}
										>
											<div className="mt-3 flex items-center gap-4">
												<label
													htmlFor="title"
													className="block text-sm font-medium leading-6 text-gray-900 w-[100px]"
												>
													Title
												</label>
												<div className="mt-2 flex-1">
													<input
														id="title"
														name="title"
														type="text"
														autoComplete="title"
														{...register("title")}
														className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-8"
													/>
													{errors?.title?.message ? (
														<p className="text-red-500 text-xs italic mt-2">
															{errors?.title?.message}
														</p>
													) : (
														""
													)}
												</div>
											</div>
											<div className="mt-3 flex items-center gap-4">
												<label
													htmlFor="title"
													className="block text-sm font-medium leading-6 text-gray-900 w-[100px]"
												>
													Serial
												</label>
												<div className="mt-2 flex-1">
													<input
														id="serial"
														name="serial"
														type="text"
														autoComplete="serial"
														{...register("serial")}
														className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-8"
													/>
													{errors?.serial?.message ? (
														<p className="text-red-500 text-xs italic mt-2">
															{errors?.serial?.message}
														</p>
													) : (
														""
													)}
												</div>
											</div>
											<div className="mt-3 flex items-center gap-4">
												<label
													htmlFor="title"
													className="block text-sm font-medium leading-6 text-gray-900 w-[100px]"
												>
													Mulai
												</label>
												<div className="mt-2 flex-1">
													<input
														type="date"
														{...register(`start_expired_at`)}
														className="block w-full rounded-md border-0 py-1.5 pl-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-8"
													/>
													{errors?.start_expired_at?.message ? (
														<p className="text-red-500 text-xs italic mt-2">
															{errors?.start_expired_at?.message}
														</p>
													) : (
														""
													)}
												</div>
											</div>
											<div className="mt-3 flex items-center gap-4">
												<label
													htmlFor="title"
													className="block text-sm font-medium leading-6 text-gray-900 w-[100px]"
												>
													Berakhir
												</label>
												<div className="mt-2 flex-1">
													<input
														type="date"
														{...register(`end_expired_at`)}
														className="block w-full rounded-md border-0 py-1.5 pl-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-8"
													/>
													{errors?.end_expired_at?.message ? (
														<p className="text-red-500 text-xs italic mt-2">
															{errors?.end_expired_at?.message}
														</p>
													) : (
														""
													)}
												</div>
											</div>
											<div className="mt-3 flex items-center gap-4">
												<label
													htmlFor="title"
													className="block text-sm font-medium leading-6 text-gray-900 w-[100px]"
												>
													Quota
												</label>
												<div className="mt-2 flex-1">
													<input
														id="quota"
														name="quota"
														type="number"
														autoComplete="quota"
														{...register("quota", { valueAsNumber: true })}
														className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-8"
													/>
													{errors?.quota?.message ? (
														<p className="text-red-500 text-xs italic mt-2">
															{errors?.quota?.message}
														</p>
													) : (
														""
													)}
												</div>
											</div>
											{/* <div className="mt-3 flex items-center gap-4">
												<label
													htmlFor="title"
													className="block text-sm font-medium leading-6 text-gray-900 w-[100px]"
												>
													Tipe
												</label>
												<div className="mt-2 flex-1">
													<select
														id="type"
														name="type"
														autoComplete="type"
														{...register("type")}
														className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-8"
													>
														<option value="fix">Fix</option>
														<option value="free">Free</option>
													</select>
													{errors?.type?.message ? (
														<p className="text-red-500 text-xs italic mt-2">
															{errors?.type?.message}
														</p>
													) : (
														""
													)}
												</div>
											</div> */}
											{type !== "free" ? (
												<div className="mt-3 flex items-center gap-4">
													<label
														htmlFor="title"
														className="block text-sm font-medium leading-6 text-gray-900 w-[100px]"
													>
														Tipe Value
													</label>
													<div className="mt-2 flex-1">
														<select
															id="type_value"
															name="type_value"
															autoComplete="type_value"
															{...register("type_value")}
															className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-8"
														>
															<option value="percentage">Persentase</option>
															<option value="amount">Amount</option>
														</select>
													</div>
												</div>
											) : (
												""
											)}
											{type !== "free" ? (
												<div className="mt-3 flex items-center gap-4">
													<label
														htmlFor="title"
														className="block text-sm font-medium leading-6 text-gray-900 w-[100px]"
													>
														Nominal
													</label>
													<div className="mt-2 flex-1">
														<input
															id="value"
															name="value"
															type="number"
															autoComplete="value"
															{...register("value", { valueAsNumber: true })}
															className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-8"
														/>
														{errors?.value?.message ? (
															<p className="text-red-500 text-xs italic mt-2">
																{errors?.value?.message}
															</p>
														) : (
															""
														)}
													</div>
												</div>
											) : (
												""
											)}
											<div className="mt-3 flex items-center gap-4">
												<label
													htmlFor="is_unix"
													className="block text-sm font-medium leading-6 text-gray-900 w-[100px]"
												>
													Unik
												</label>
												<div className="mt-2 flex-1">
													<input
														id="is_unix"
														name="is_unix"
														type="checkbox"
														autoComplete="is_unix"
														{...register("is_unix")}
													/>
												</div>
											</div>
											<div className="mt-3 flex items-center gap-4">
												<label
													htmlFor="status_id"
													className="block text-sm font-medium leading-6 text-gray-900 w-[100px]"
												>
													Status
												</label>
												<div className="mt-2 flex-1">
													<input
														id="status_id"
														name="status_id"
														type="checkbox"
														autoComplete="status_id"
														{...register("status_id")}
													/>
												</div>
											</div>
											<div className=" pt-5 items-center flex justify-center">
												<button
													type="submit"
													disabled={loading || loadingPost || loadingPut}
													className="rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 disabled:cursor-not-allowed disabled:bg-blue-200"
												>
													{loading || loadingPost || loadingPut
														? "Sedang diproses"
														: !data
														? "Buat Diskon dan Kupon"
														: "Perbaharui Diskon dan Kupon"}
												</button>
											</div>
										</form>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
		</>
	);
};
