import { ChevronLeft } from "lucide-react";
import { EventTicketForm, Seating, Wysiwyg } from "src/components/web";
import { Event, Order, OrderResponse } from "src/interface";

interface TicketingConfirmationFormProps {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  data: Event;
  formState: Order;
  setOrderResponse: React.Dispatch<React.SetStateAction<OrderResponse>>;
}

export const TicketingConfirmationForm = ({
  data,
  setCurrentStep,
  formState,
  setOrderResponse,
}: TicketingConfirmationFormProps) => {
  return (
    <div>
      <button
        type="button"
        onClick={() => {
          setCurrentStep(2);
        }}
        className="mb-12 flex items-center justify-center rounded bg-white px-2 py-1 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
      >
        <ChevronLeft /> Kembali
      </button>
      <div className="grid">
        <div className="col-span-6">
          {/* {data.seat_images_url ? (
            <div className="mb-4">
              <Seating map={data.seat_images_url} />
            </div>
          ) : (
            ""
          )} */}
          <div className="mb-12">
            <Wysiwyg text={data.event_tnc} />
          </div>
        </div>
        <div className="col-span-6">
          <div className="mb-12">
            <EventTicketForm
              title="Jenis dan jumlah tiket"
              setCurrentStep={setCurrentStep}
              formState={formState}
              data={data}
              showPrice
              proceed
              setOrderResponse={setOrderResponse}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
