import { App } from "src/interface";

const subDomain = window?.location?.host?.split(".tixie.id")?.[0];
const subDomainVercel = window?.location?.host?.includes("tixieteam.vercel.app");

export const AppConfig: App = {
	apiPrefix: process.env.REACT_APP_API_URL,
	masterToken: process.env.REACT_APP_MASTER_TOKEN,
	domain: subDomain,
	subDomain: `${
		process.env.NODE_ENV === "development" ? process.env.REACT_APP_SUB_DOMAIN : subDomainVercel ? "zeus" : subDomain
	}.tixie.id`,
};
