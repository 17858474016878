import { useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import ReactQuill from "react-quill";
import { toast } from "react-toastify";
import { Loading } from "src/components/web";

import { QuillConfig, _quillRemoveFormats } from "src/configs/quill.config";
import { apiGetPartner, apiPostWebsite } from "src/services";

const ManageContent = () => {
	const [content, setContent] = useState("");

	const { data: partnerDetails, isLoading: loadingPartner } = useQuery<any>([`partner`, `website`], apiGetPartner);
	const { mutate: partnerMutate, isLoading: loadingMutate } = useMutation(
		async (data: any) => await apiPostWebsite(data)
	);

	return loadingPartner ? (
		<Loading title="Mohon tunggu..." />
	) : (
		<div>
			<div className="sm:flex sm:items-center">
				<div className="sm:flex-auto">
					<h1 className="text-3xl font-medium leading-6 text-gray-900">Content</h1>
					<p className="mt-2 text-sm text-gray-700">Manage content disini untuk update.</p>
				</div>
			</div>
			<div className="mt-8 flow-root">
				<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div className="max-w-screen-2xl mx-auto py-2 sm:px-6 lg:px-8 flex gap-2">
						<div className="flex-1">
							<ReactQuill
								{...QuillConfig}
								defaultValue={partnerDetails?.data?.data?.content}
								theme="snow"
								onChange={(e) => setContent(_quillRemoveFormats(e))}
							/>
						</div>
						<div
							className="mb-3 bg-slate-100 p-4 flex-1  ql-editor"
							dangerouslySetInnerHTML={{
								__html: content,
							}}
						></div>
					</div>
				</div>
				<div className="p-5 items-center flex justify-center">
					<button
						type="button"
						disabled={loadingMutate}
						onClick={() => {
							partnerMutate(
								{ ...partnerDetails?.data?.data, content: content },
								{
									onSuccess: (res: any) => {
										toast.success("Content berhasil diubah");
									},
								}
							);
						}}
						className="rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 disabled:cursor-not-allowed disabled:bg-blue-200"
					>
						{loadingMutate ? <Loading title="Sedang diproses" /> : "Perbaharui event"}
					</button>
				</div>
			</div>
		</div>
	);
};

export default ManageContent;
