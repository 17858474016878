import ApiService from "./ApiService";

export async function apiGetStatisticTransactionByEventId({
	eventId,
	params,
}: {
	eventId: string;
	params: any;
}): Promise<any> {
	return ApiService.fetchData<any>({
		url: `partner/statistik/transaction/${eventId}`,
		method: "get",
		params,
	}).then((res: any) => res?.data?.data as any);
}

export async function apiGetStatisticTicketByEventId({
	eventId,
	params,
}: {
	eventId: string;
	params: any;
}): Promise<any> {
	return ApiService.fetchData<any>({
		url: `partner/tickets/${eventId}`,
		method: "get",
		params,
	}).then((res: any) => res?.data as any);
}

export async function apiGetStatisticSummerByEventId({ eventId }: { eventId: string }): Promise<any> {
	return ApiService.fetchData<any>({
		url: `partner/statistik/transaction/total/${eventId}`,
		method: "get",
	}).then((res: any) => res?.data?.data as any);
}
