import axios from "axios";

interface Params {
	baseUrl: string;
}

const postConfig: Params = {
	baseUrl: "https://api.tixie.id/api",
};

export const masterToken = "LnJhtMu1VuAZKhB3kW0R";

export function sleep(ms = 500): Promise<void> {
	console.log("Kindly remember to remove `sleep`");
	return new Promise((resolve) => setTimeout(resolve, ms));
}

// axios.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     if (error.response.status === 401) {
//       console.log("PATH", window.location.pathname);
//       if (window.location.pathname.includes("admin")) {
//         docCookies.removeItem("token");
//         window.location.href = "/admin";
//       } else {
//         // window.location.href = "/error";
//       }
//     }
//     if (error.response.status === 500) {
//       // window.location.reload();
//       docCookies.removeItem("token");
//       window.location.href = "/admin";
//     }
//   }
// );

export const connectAPI = async (url?: string, data?: any, method?: any, headers?: any): Promise<any> => {
	return await axios({
		method: method,
		headers: headers,
		url: `${postConfig.baseUrl}/${url}`,
		data,
	})
		.then((response) => {
			return {
				status: response.status,
				data: response.data,
			};
		})
		.catch((error) => {
			if (error.response.status === 500) {
				localStorage.removeItem("token");
				window.location.href = "/admin";
			}
			return {
				data: error.response,
			};
		});
};
