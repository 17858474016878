import { AppConfig } from "src/configs";
import ApiService from "./ApiService";
import { MasterData } from "src/interface";

export async function apiGetMasterData(): Promise<MasterData> {
  return ApiService.fetchData<MasterData>({
    url: `master-data/${AppConfig.subDomain}`,
    method: "get",
    headers: {
      mastertoken: AppConfig.masterToken,
      subdomain: AppConfig.subDomain,
    },
  }).then((res: any) => res?.data?.data as MasterData);
}
