import { Link } from "react-router-dom";
import { AlertDialogTrigger } from "src/components/ui/alert-dialog";

interface BuildEventTableSchemaProps {
	setSelectedEvent: any;
}

export const BuildEventTableSchema = ({ setSelectedEvent }: BuildEventTableSchemaProps) => {
	return [
		{
			header: "Nama",
			cell: (value) => <Link to={`/admin/event/${value.id}`}>{value?.event_title}</Link>,
		},
		{
			header: "Tipe",
			cell: (value) => value?.event_type,
		},
		{
			header: "Venue",
			cell: (value) => value?.venue,
		},
		{
			header: "Status",
			cell: (value) => (value?.status_id === 1 ? "Active" : "Inactive"),
		},
		{
			header: "Edit",
			cell: (value) => (
				<div>
					<Link
						to={`/admin/event/${value?.id}`}
						className="mr-4 rounded bg-white px-2 py-1 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
					>
						Ubah
					</Link>
					<AlertDialogTrigger
						onClick={() =>
							setSelectedEvent({
								id: value.id,
								title: value.event_title,
							})
						}
						className="rounded bg-white px-2 py-1 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
					>
						Hapus
					</AlertDialogTrigger>
				</div>
			),
		},
	];
};
