import { Outlet } from "react-router-dom";
import { Header } from "src/components/web";
import { Loading, NotFound } from "src/components/global";
import { useQuery } from "@tanstack/react-query";
import { MasterData } from "src/interface";
import { apiGetMasterData } from "src/services";

import "../App.css";

function WebLayout() {
  const { data, isLoading, isError } = useQuery<MasterData>({
    queryKey: ["master-data"],
    queryFn: apiGetMasterData,
  });

  return isLoading ? (
    <Loading />
  ) : isError ? (
    <NotFound />
  ) : (
    <>
      <Header data={data} />
      <Outlet />
    </>
  );
}

export default WebLayout;
