import { Sponsor } from "src/interface";

interface SponsorsProps {
	data: Sponsor[];
}

export const Sponsors = ({ data }: SponsorsProps) => {
	return (
		<>
			<h2 className="text-3xl font-medium mb-8 px-2">Our Sponsors</h2>
			<div className="flex flex-wrap items-center gap-4">
				{data?.map((value, index) => (
					<img
						src={value.image_url}
						alt={value.title}
						key={`sponsor-${value.id}-${index}`}
						className="w-48 mr-8"
					/>
				))}
			</div>
		</>
	);
};
