import {
  CommonGetService,
  Event,
  EventRequest,
  Pagination,
} from "src/interface";
import ApiService from "./ApiService";
import { AppConfig } from "src/configs";

export async function apiGetEventById({
  id,
  token,
}: CommonGetService): Promise<Event> {
  return ApiService.fetchData<Event>({
    url: `event/detail/${id}`,
    method: "get",
    headers: {
      mastertoken: AppConfig.masterToken,
      subdomain: AppConfig.subDomain,
      usertoken: token,
    },
  }).then((res: any) => res?.data?.data as Event);
}

export async function apiGetPartnerEvent(): Promise<Pagination<Event>> {
  return ApiService.fetchData<Pagination<Event>>({
    url: `partner/event`,
    method: "get",
  }).then((res: any) => res?.data as Pagination<Event>);
}

export async function apiDeletePartnerEvent({ id }: { id: string }) {
  return ApiService.fetchData({
    url: `partner/event/${id}`,
    method: "delete",
  });
}

export async function apiPostPartnerEvent({ data }: { data: EventRequest }) {
  return ApiService.fetchData({
    url: "partner/event",
    method: "post",
    data,
  }).then((res: any) => res?.data as any);
}

export async function apiUpdatePartnerEvent({
  data,
  id,
}: {
  data: EventRequest;
  id: string;
}) {
  return ApiService.fetchData({
    url: `partner/event/${id}`,
    method: "put",
    data,
  }).then((res: any) => res?.data as any);
}

export async function apiGetPartnerEventById({
  id,
}: {
  id: string;
}): Promise<Event> {
  return ApiService.fetchData<Event>({
    url: `partner/event/${id}`,
    method: "get",
    headers: {
      "Content-Type": "multipart/form-data",
    },
  }).then((res: any) => res?.data?.data as Event);
}
