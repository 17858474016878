import { Disclosure } from "@headlessui/react";
import { City, Event, Order, Province } from "src/interface";
import { useEffect } from "react";
import { z } from "zod";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQuery } from "@tanstack/react-query";
import { apiGetCity } from "src/services/CityService";
import { apiGetSubDistrict } from "src/services/SubDistrictService";
import { apiGetVillage } from "src/services/VillageService";
import { BuyerForm, UserForm } from "src/partials/ticketing/detail";

interface TicketHolderProps {
  title?: string;
  setCurrentStep?: React.Dispatch<React.SetStateAction<number>>;
  data: Event;
  formState: Order;
  province: Province[];
  token: string;
  setFormState?: React.Dispatch<React.SetStateAction<Order>>;
}

const BuyerSchema = z.object({
  full_name: z.string().nonempty("Nama lengkap wajib diisi"),
  mobile_number: z.string().refine((value) => /^\d{8,15}$/.test(value), {
    message: "Nomor handphone harus terdiri dari 8 sampai 15 karakter",
  }),
  address: z.string().nonempty("Alamat wajib diisi"),
  email: z.string().email().nonempty("Email wajib diisi"),
  number_identity_card: z
    .string()
    .min(16)
    .max(16)
    .nonempty("Nomor KTP wajib diisi"),
  provinsi_id: z.number({
    required_error: "Provinsi wajib diisi",
    invalid_type_error: "Provinsi wajib diisi",
  }),
  kota_id: z.number({
    required_error: "Kota wajib diisi",
    invalid_type_error: "Kota wajib diisi",
  }),
  kecamatan_id: z.number({
    required_error: "Kecamatan wajib diisi",
    invalid_type_error: "Kecamatan wajib diisi",
  }),
  kelurahan_id: z.number({
    required_error: "Kelurahan wajib diisi",
    invalid_type_error: "Kelurahan wajib diisi",
  }),
});

const UserSchema = z.object({
  full_name: z.string().nonempty("Nama lengkap wajib diisi"),
  mobile_number: z.string().refine((value) => /^\d{10,15}$/.test(value), {
    message: "Format nomor handphone tidak sesuai",
  }),
  email: z.string().email().nonempty("Email wajib diisi"),
});

const TypeOrderTicketSchema = z.object({
  id: z.number(),
  qty: z.number(),
  price: z.number(),
  users: z.array(UserSchema),
});

const OrderTicketSchema = z.object({
  zone: z.number(),
  types: z.array(TypeOrderTicketSchema),
});

const OrderSchema = z.object({
  buyer: BuyerSchema.optional(),
  ticket: z.array(OrderTicketSchema).optional(),
});
type UserFormSchema = z.infer<typeof UserSchema>;
type TicketForm = z.infer<typeof OrderTicketSchema>;
type OrderForm = z.infer<typeof OrderSchema>;

const convertToOrderForm = (
  data?: Order,
  province?: Province[]
): OrderForm | undefined => {
  const user: UserFormSchema = {
    full_name: "",
    mobile_number: "",
    email: "",
  };

  const ticket: TicketForm[] = data.ticket.map((value) => ({
    zone: value.zone,
    types: value.types.map((types) => {
      const userTicket: UserFormSchema[] = Array.from(
        { length: types.qty },
        (_) => ({ ...user })
      );
      return {
        ...types,
        users: types?.users?.length ? types?.users : userTicket,
      };
    }),
  }));

  return {
    buyer: {
      full_name: "",
      mobile_number: "",
      number_identity_card: "",
      email: "",
      provinsi_id: province?.[0]?.id,
      kota_id: null,
      kecamatan_id: null,
      kelurahan_id: null,
      address: "",
      ...data?.buyer,
    },
    ticket,
  };
};

export const TicketHolder = ({
  setCurrentStep,
  data,
  formState,
  province,
  token,
  setFormState,
}: TicketHolderProps) => {
  const defaultValue: OrderForm = convertToOrderForm(formState, province);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<OrderForm>({
    resolver: zodResolver(OrderSchema),
    defaultValues: defaultValue,
  });

  const province_id = useWatch({
    control,
    name: "buyer.provinsi_id",
  });

  const city_id = useWatch({
    control,
    name: "buyer.kota_id",
  });

  const sub_district_id = useWatch({
    control,
    name: "buyer.kecamatan_id",
  });

  const { data: city } = useQuery<City[]>({
    queryKey: ["city", province_id],
    queryFn: async () => await apiGetCity({ province_id, token }),
    enabled: !!province_id,
  });

  const { data: subdistrict } = useQuery<City[]>({
    queryKey: ["subdistrict", city_id],
    queryFn: async () => await apiGetSubDistrict({ city_id, token }),
    enabled: !!city_id,
  });

  const { data: village } = useQuery<City[]>({
    queryKey: ["village", sub_district_id],
    queryFn: async () => await apiGetVillage({ sub_district_id, token }),
    enabled: !!sub_district_id,
  });

  useEffect(() => {
    if (!formState?.["next"]) {
      setValue("buyer.kota_id", null);
      setValue("buyer.kelurahan_id", null);
      setValue("buyer.kecamatan_id", null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [province_id, city]);

  useEffect(() => {
    if (!formState?.["next"]) {
      setValue("buyer.kelurahan_id", null);
      setValue("buyer.kecamatan_id", null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [city_id, subdistrict]);

  useEffect(() => {
    if (!formState?.["next"]) {
      setValue("buyer.kelurahan_id", null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sub_district_id, village]);

  const { fields } = useFieldArray({
    control,
    name: "ticket",
  });

  return (
    <form
      onSubmit={handleSubmit((value: OrderForm) => {
        const order = { ...formState, ...value, next: true };
        setFormState(order);
        setCurrentStep(3);
      })}
    >
      <h2 className="mb-8 font-medium text-2xl">Data pembeli tiket</h2>
      <BuyerForm
        errors={errors}
        register={register}
        city={city}
        province={province}
        subdistrict={subdistrict}
        village={village}
        setValue={setValue}
        getValues={getValues}
      />
      <h2 className="mb-4 font-medium text-2xl">Data pemegang tiket</h2>
      <div className="rounded-md bg-orange-100 border-orange-200 border-2 p-4 mb-8">
        <div className="text-sm text-yellow-700 flex items-center">
          <ul className="list-disc space-y-1 pl-5">
            <li>
              Silakan mengisi data pemegang tiket. Jika data belum di isi pada
              saat pembelian sekarang, maka data pemegang tiket dapat di isi
              nanti melalui akses member.
            </li>
            <li>
              Pasti kan data yang anda isi adalah benar, perubahan data tiket
              hanya dapat dilakukan sekali dengan request ke customer service.
            </li>
          </ul>
        </div>
      </div>
      <div className="w-full">
        <div className="mx-auto w-full rounded-md bg-slate-100 p-8 mb-8">
          {fields.map((zone, indexZone) => {
            const currTicket = data?.tickets?.find(
              (ticket) => ticket?.zone?.id === zone?.zone
            );
            return (
              <div className="mb-4" key={`zone-${indexZone}-${zone}`}>
                <Disclosure defaultOpen={true}>
                  {({ open }) => (
                    <>
                      <div className="flex w-full items-center justify-between rounded-t-lg bg-purple-100 px-4 py-2 text-left text-md font-medium text-purple-900 focus:outline-none">
                        <div className="flex items-center justify-between w-full pt-2">
                          <span>
                            {currTicket?.zone?.title}
                            <span className="ml-2 inline-flex items-center rounded-md bg-blue-700 px-2.5 py-0.5 text-sm font-medium text-white">
                              {zone?.types?.reduce((prev, curr) => {
                                return curr?.qty + prev;
                              }, 0) || 0}
                            </span>
                          </span>
                        </div>
                      </div>
                      <Disclosure.Panel className="pt-2 pb-2 px-4 text-sm text-gray-500 bg-purple-100">
                        {zone?.types?.map((type, indexType) => {
                          return (
                            <div
                              className="mb-4"
                              key={`type-${indexType}-${zone}-${type.id}`}
                            >
                              <Disclosure defaultOpen={true}>
                                {() => (
                                  <>
                                    <div className="flex w-full items-center justify-between rounded-lg bg-white px-4 py-2 text-left text-md font-medium text-purple-900 focus:outline-none">
                                      <div className="flex items-center justify-between w-full">
                                        <span>
                                          {
                                            currTicket?.event_tickets?.find(
                                              (event_ticket) =>
                                                event_ticket.ticket_type.id ===
                                                type.id
                                            ).ticket_type.title
                                          }
                                          <span className="ml-2 inline-flex items-center rounded-md bg-blue-700 px-2.5 py-0.5 text-sm font-medium text-white">
                                            {type.qty}
                                          </span>
                                        </span>
                                        {/* <div>
                                          {!open ? (
                                            <ChevronDown />
                                          ) : (
                                            <ChevronUp />
                                          )}
                                        </div> */}
                                      </div>
                                    </div>
                                    <Disclosure.Panel className="pt-4 pb-2 text-sm text-gray-500">
                                      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                                        {type.users.map((_, indexUser) => {
                                          return (
                                            <UserForm
                                              register={register}
                                              setValue={setValue}
                                              errors={errors}
                                              ticket_index={indexZone}
                                              getValues={getValues}
                                              type_index={indexType}
                                              user_index={indexUser}
                                              key={`user-${indexZone}-${indexUser}-${indexType}`}
                                            />
                                          );
                                        })}
                                      </div>
                                    </Disclosure.Panel>
                                  </>
                                )}
                              </Disclosure>
                            </div>
                          );
                        })}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </div>
            );
          })}
        </div>

        <button
          type="submit"
          className="w-full justify-center inline-flex items-center rounded-md border border-transparent bg-blue-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-blue-200"
        >
          Beli Tiket
        </button>
      </div>
    </form>
  );
};
