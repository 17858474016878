import { Link } from "react-router-dom";
import { Event } from "src/interface";

interface EventCardProps {
  event: Event;
}

export const EventCarouselCard = ({ event }: EventCardProps) => {
  return (
    <Link to={`/event/${event.id}`}>
      <div className="h-60 mb-5 relative bg-slate-300">
        <img
          className="absolute w-full h-full top-0 left-0 object-cover"
          src={event?.banner_image_url ?? "https://placehold.co/1000@3x.png"}
          alt=""
        />
      </div>
      <p>{event.event_title}</p>
    </Link>
  );
};
