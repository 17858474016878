import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCalendar, faClock, faEye, faInboxIn, faInboxOut } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover } from "@headlessui/react";
import { Link } from "react-router-dom";
import { _getDate, _getTime } from "src/utils";

interface BuildCheckinTableSchemaProps {
	type: "in" | "out";
	putCheckIn?: any;
	refetch?: any;
}

export const BuildCheckinTableSchema = ({ type, putCheckIn, refetch }: BuildCheckinTableSchemaProps) => {
	return [
		{
			header: "Nama Pemegang Tiket",
			cell: (value) => value?.ticket?.buyer?.full_name,
		},
		{
			header: "Event",
			cell: (value) => <Link to={`/admin/event/${value?.ticket?.event?.id}`}>{value?.ticket?.event?.event_title}</Link>,
		},
		{
			header: "Tipe Tiket",
			cell: (value) => value?.ticket?.type?.title,
		},
		{
			header: "Zona/Kategori",
			cell: (value) => value?.ticket?.zone?.title,
		},
		{
			header: "Tiket ID",
			cell: (value) => (
				<Popover className="relative">
					<Popover.Button>
						<button type="button" className="rounded bg-white px-2 py-1 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
							Cek kode tiket
							<FontAwesomeIcon icon={faEye as IconProp} className="ml-2" />
						</button>
					</Popover.Button>

					<Popover.Panel className="fixed z-100 bg-slate-300 p-3 rounded-md mt-1">{value?.ticket?.unique_id}</Popover.Panel>
				</Popover>
			),
		},
		{
			header: `Waktu check${type}`,
			cell: (value) => (
				<div>
					<div>
						<FontAwesomeIcon className="mr-2" aria-hidden="true" icon={faCalendar as IconProp} />
						{_getDate(value?.created_at)}
					</div>
					<div>
						<FontAwesomeIcon className="mr-2" aria-hidden="true" icon={faClock as IconProp} />
						{_getTime(value?.created_at)}
					</div>
				</div>
			),
		},
		{
			header: "Action",
			cell: (value) => (
				<button
					type="button"
					onClick={() => {
						putCheckIn?.(
							{
								id: value?.ticket_id,
								status: type === "in" ? "CheckedOut" : "CheckedIn",
							},
							{
								onSuccess: (res: any) => {
									refetch();
								},
							}
						);
					}}
					className={`justify-center inline-flex items-center rounded-md border border-transparent ${
						type === "in" ? "bg-red-600" : `bg-green-500`
					} px-2 py-1.5 text-base font-medium text-white shadow-sm focus:outline-none `}
				>
					<FontAwesomeIcon aria-hidden="true" icon={(type === "in" ? faInboxOut : faInboxIn) as IconProp} />
				</button>
			),
		},
	];
};
