import { AppConfig } from "src/configs";
import ApiService from "./ApiService";
import { City } from "src/interface";

export async function apiGetCity({
  province_id,
  token,
}: {
  province_id: number;
  token: string;
}): Promise<City[]> {
  return ApiService.fetchData<City[]>({
    url: `kota/${province_id}`,
    method: "get",
    headers: {
      mastertoken: AppConfig.masterToken,
      subdomain: AppConfig.subDomain,
      usertoken: token,
    },
  }).then((res: any) => res?.data?.data as City[]);
}
