import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { Loading } from "src/components/global/Loading";
import { EventForm } from "src/partials/admin/event";
import { apiGetPartnerEventById } from "src/services";

export const UpdateEventTicket = () => {
  const { id } = useParams();

  const {
    isLoading: loadingEvent,
    data: event,
    refetch,
  } = useQuery(
    ["partner", "event", id],
    async () => await apiGetPartnerEventById({ id })
  );

  return loadingEvent || !event ? (
    <Loading />
  ) : (
    <EventForm event={event} refetch={refetch} isLoading={loadingEvent} />
  );
};
