import { BaseTable } from "src/components/admin/Base-table";
import { Pagination } from "src/components/admin/Pagination";
import { BuildStatisticTicketTableSchema } from "src/configs/table/statistic-ticket-table.schema";

export const TableTicketPerEvent = ({ data, isLoading, setPage }: { data: any; isLoading: boolean; setPage: any }) => {
	const table = BuildStatisticTicketTableSchema();

	return (
		<>
			<div className="font-medium text-2xl mt-2">Semua Tiket</div>

			<div className="rounded-md bg-slate-100 mt-2">
				<BaseTable datas={data?.data} column={table} loading={isLoading} />
				<Pagination {...data} setPage={setPage} />
			</div>
		</>
	);
};
