import { Dialog, Switch, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import "draft-js/dist/Draft.css";
import { connectAPI } from "../../api/api";
import { serialize } from "object-to-formdata";
import { ImageUpload } from "../../components/global/imageUpload";
import { Loading } from "../../components/global";
import { Alert, Snackbar } from "@mui/material";
import { IErrorApi } from "../../interface/generic";
import { classNames } from "../../functions/global-functions";

export interface IProps {
	setEditSponsor?: Function;
	editSponsor?: any;
	sponsorItem?: any;
	getLatestSponsor?: Function;
	popupType?: string;
}

export interface IKeys {
	key: any;
	value: any;
}

export const DetailSponsor = (props: IProps) => {
	const closeSponsor = () => {
		props.setEditSponsor(false);
	};
	const handleClose = () => {
		setIsError(false);
	};
	let [enabled, setEnabled] = useState(props.popupType === "edit" ? (props.sponsorItem.status_id === 1 ? true : false) : true);
	let [isError, setIsError] = useState(false);
	let [sponsorItem, setSponsorItem] = useState(props.sponsorItem);
	let [isLoading, setIsLoading] = useState(false);
	let [errorMsg, setErrorMsg] = useState<IErrorApi>({
		message: "",
		errors: null,
	});

	const updateSponsorFunction = (id) => {
		// console.log(sponsorItem)
		// console.log('Updating news', id)
		setIsLoading(true);
		const formData = serialize(sponsorItem);
		connectAPI(`partner/sponsor/${id}`, formData, "post", {
			"Content-Type": "multipart/form-data",
			authorization: `Bearer ${localStorage.getItem("token")}`,
		}).then((res) => {
			if (res.status === 200) {
				const data = res.data.data;
				setIsLoading(false);
				setSponsorItem(data);
				props.getLatestSponsor();
				closeSponsor();
			} else {
				setIsLoading(false);
				const data = res.data;
			}
		});
	};

	const addSponsorFunction = () => {
		setIsLoading(true);
		const formData = serialize(sponsorItem);
		connectAPI(`partner/sponsor`, formData, "post", {
			"Content-Type": "multipart/form-data",
			authorization: `Bearer ${localStorage.getItem("token")}`,
		}).then((res) => {
			const data = res.data.data;
			if (res.status === 200) {
				setIsLoading(false);
				setSponsorItem(data);
				props.getLatestSponsor();
				closeSponsor();
			} else {
				setIsLoading(false);
				setIsError(true);
				setErrorMsg({ ...errorMsg, message: data.message });
			}
		});
	};

	return (
		<>
			<Transition appear show={props.editSponsor} as={Fragment}>
				<Dialog as="div" className="relative z-10" onClose={() => {}}>
					<Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
						<div className="fixed inset-0 bg-black bg-opacity-25" />
					</Transition.Child>

					<div className="fixed inset-0 overflow-y-auto">
						<div className="flex min-h-full items-center justify-center p-4 text-center">
							<Snackbar open={isError} autoHideDuration={4000} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
								<Alert variant="filled" severity="error" onClose={handleClose} sx={{ width: "100%" }}>
									{errorMsg.message}
								</Alert>
							</Snackbar>
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95"
							>
								<Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
									{isLoading && <Loading />}
									<div className=" flex content-between items-center justify-between">
										<Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
											{sponsorItem?.title}
										</Dialog.Title>
										<div className="mt-2">
											<Switch.Group as="div" className="flex items-center">
												<Switch
													checked={enabled}
													onChange={(e) => {
														setEnabled(enabled === true ? false : true);
														setSponsorItem({
															...sponsorItem,
															status_id: enabled === true ? 0 : 1,
														});
													}}
													className={classNames(
														enabled ? "bg-indigo-600" : "bg-gray-200",
														"relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
													)}
												>
													<span
														aria-hidden="true"
														className={classNames(
															enabled ? "translate-x-5" : "translate-x-0",
															"pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
														)}
													/>
												</Switch>
												<Switch.Label as="span" className="ml-3 text-sm">
													<span className="font-medium text-gray-900">{enabled ? "Active" : "Inactive"}</span>
												</Switch.Label>
											</Switch.Group>
										</div>
									</div>

									<ImageUpload setCollectionItem={setSponsorItem} type="single_file" collectionItem={sponsorItem} currentImage={props.sponsorItem?.image_url} />

									<div className="mt-3">
										<label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
											Title
										</label>
										<div className="mt-2">
											<input
												type="text"
												name="title"
												id="title"
												value={sponsorItem?.title}
												disabled={isLoading ? isLoading : false}
												onChange={(e) =>
													setSponsorItem({
														...sponsorItem,
														title: e.target.value,
														order: 1,
														status_id: 1,
													})
												}
												className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 disabled:bg-slate-100 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
												placeholder="Title"
											/>
										</div>
									</div>
									<div className="mt-4 flex justify-between">
										<button
											type="button"
											className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
											onClick={() => {
												props.popupType === "edit" ? updateSponsorFunction(props.sponsorItem.id) : addSponsorFunction(); // update list
											}}
										>
											Submit
										</button>
										<button
											type="button"
											className="inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
											onClick={closeSponsor}
										>
											Cancel
										</button>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
		</>
	);
};
