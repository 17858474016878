import { CheckOrderRequest, Order, OrderResponse } from "src/interface";
import ApiService from "./ApiService";
import { AppConfig } from "src/configs";

export async function apiPostOrder({ order, token }: { order: Order; token: string }): Promise<OrderResponse> {
	return ApiService.fetchData<OrderResponse>({
		url: `orders`,
		method: "post",
		data: order,
		headers: {
			mastertoken: AppConfig.masterToken,
			subdomain: AppConfig.subDomain,
			usertoken: token,
		},
	}).then((res: any) => res?.data?.data as OrderResponse);
}

export async function apiPostCheckOrder({ order, token }: { order: CheckOrderRequest; token: string }): Promise<any> {
	return ApiService.fetchData<any>({
		url: `check/ticket`,
		method: "post",
		data: order,
		headers: {
			mastertoken: AppConfig.masterToken,
			subdomain: AppConfig.subDomain,
			usertoken: token,
		},
	}).then((res: any) => res?.data?.data as any);
}

export async function apiCheckKupon({ serial, token }): Promise<any> {
	return ApiService.fetchData<any>({
		url: `check/voucher`,
		method: `post`,
		data: { serial },
		headers: {
			mastertoken: AppConfig.masterToken,
			subdomain: AppConfig.subDomain,
			usertoken: token,
		},
	}).then((res: any) => res?.data?.data as any);
}
