import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { Loading } from "src/components/web";
import { Province } from "src/interface";
import { CheckTicketContainer } from "src/partials/check";
import { AktivasiTicketForm } from "src/partials/check/AktivasiTicketForm";
import { apiGetProvince } from "src/services";
import { apiPostCheckOrder } from "src/services/OrderService";
import { useToken } from "src/utils";

export const AktivasiTicketPage = () => {
  const { phone_number, ticket_number } = useParams();
  const { token } = useToken();

  const { data, isLoading, isError, refetch } = useQuery(
    ["check-order", phone_number, ticket_number],
    async () =>
      await apiPostCheckOrder({
        order: {
          choose_identity: "phone",
          unique_id: ticket_number,
          no_identity: phone_number,
        },
        token,
      })
  );

  const { data: province, isLoading: loadingProvince } = useQuery<Province[]>({
    queryKey: ["province"],
    queryFn: async () => await apiGetProvince({ token }),
  });

  return isLoading || loadingProvince ? (
    <Loading />
  ) : !isError ? (
    <CheckTicketContainer title="Aktivasi Tiket">
      <AktivasiTicketForm
        data={data?.[0]}
        unique_id={ticket_number}
        refetch={refetch}
        province={province}
      />
    </CheckTicketContainer>
  ) : (
    <div>Error</div>
  );
};
