import { Event } from "src/interface";
import { EventCarouselCard } from "./EventCarouselCard";
import { Loading } from "./Loading";

interface EventsCarouselProps {
  events: Event[];
  loading: boolean;
}

export const EventCarouselListing = ({
  events,
  loading,
}: EventsCarouselProps) => {
  return (
    <div className="max-w-screen-xl mx-auto">
      <div className="mb-8">
        <h2 className="pl-3 border-l-8 border-blue-500 text-3xl font-medium">
          Events
        </h2>
      </div>
      {loading ? (
        <Loading title="Loading events..." />
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-8">
          {events?.map((event: Event, index: number) => (
            <EventCarouselCard
              event={event}
              key={`event-carousel-card-${event.id}-${index}`}
            />
          ))}
        </div>
      )}
    </div>
  );
};
