import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";

interface StepsProps {
  title?: string;
  currentStep?: number;
}

const BuildSteps = ({ currentStep }) => {
  const checkStatus = (id) => {
    if (currentStep === id) return "current";
    if (id <= currentStep) return "done";
  };

  return [
    {
      id: 1,
      name: "Beli Tiket",
      href: "#",
      status: checkStatus(1),
    },
    {
      id: 2,
      name: "Detail",
      href: "#",
      status: checkStatus(2),
    },
    {
      id: 3,
      name: "Konfirmasi",
      href: "#",
      status: checkStatus(3),
    },

    {
      id: 4,
      name: "Pembayaran",
      href: "#",
      status: checkStatus(4),
    },
  ];
};

export const Steps = ({ title, currentStep }: StepsProps) => {
  const steps = BuildSteps({ currentStep });

  return (
    <>
      <nav aria-label="Progress">
        <ol className="divide-y divide-gray-300 rounded-md border bg-slate-100  md:flex md:divide-y-0">
          {steps.map((step, stepIdx) => (
            <li key={step.name} className="relative md:flex md:flex-1">
              {step.status === "current" || step.status === "done" ? (
                <div className="group flex w-full items-center hover:cursor-pointer">
                  <span className="flex items-center px-6 py-4 text-sm font-medium">
                    <span
                      className={`flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full ${
                        step.status === "current"
                          ? "bg-blue-600 group-hover:bg-blue-800"
                          : "bg-blue-900"
                      }`}
                    >
                      <FontAwesomeIcon
                        icon={faCheckCircle as IconProp}
                        className="text-md text-white"
                        aria-hidden="true"
                      />
                    </span>
                    <span
                      className={`ml-4 text-sm font-medium ${
                        step.status === "current"
                          ? "text-gray-900"
                          : "text-blue-900"
                      }`}
                    >
                      {step.name}
                    </span>
                  </span>
                </div>
              ) : (
                <div
                  className="flex items-center px-6 py-4 text-sm font-medium hover:cursor-pointer"
                  aria-current="step"
                >
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-blue-600">
                    <span className="text-blue-600">0{step.id}</span>
                  </span>
                  <span className="ml-4 text-sm font-medium text-blue-600">
                    {step.name}
                  </span>
                </div>
              )}

              {stepIdx !== steps.length - 1 ? (
                <>
                  <div
                    className="absolute top-0 right-0 hidden h-full w-5 md:block"
                    aria-hidden="true"
                  >
                    <svg
                      className="h-full w-full text-gray-300"
                      viewBox="0 0 22 80"
                      fill="none"
                      preserveAspectRatio="none"
                    >
                      <path
                        d="M0 -2L20 40L0 82"
                        vectorEffect="non-scaling-stroke"
                        stroke="currentcolor"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </>
              ) : null}
            </li>
          ))}
        </ol>
      </nav>
    </>
  );
};
