interface PaginationProps {
	from: number;
	to: number;
	total: number;
	current_page: number;
	last_page: number;
	setPage: any;
}

export const Pagination = ({ from, to, total, current_page, last_page, setPage }: PaginationProps) => {
	return (
		<div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
			<div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
				<div>
					<p className="text-sm text-gray-700">
						Showing <span className="font-medium">{from || 0}</span> to{" "}
						<span className="font-medium">{to || 0}</span> of{" "}
						<span className="font-medium">{total || 0}</span> results
					</p>
				</div>
				<div>
					<nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
						<button
							disabled={current_page === 1}
							onClick={() => setPage((prev) => prev - 1)}
							className="relative inline-flex items-center rounded-l-md px-2 py-2 disabled:cursor-not-allowed text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
						>
							<span className="sr-only">Previous</span>
							<svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
								<path
									fillRule="evenodd"
									d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
									clipRule="evenodd"
								/>
							</svg>
						</button>
						{Array.from({ length: last_page }, (_, index) => (
							<button
								type="button"
								onClick={() => setPage(index + 1)}
								className={`relative z-10 inline-flex items-center ${
									index + 1 === current_page ? "bg-indigo-600 text-white " : "bg-white text-gray-400"
								} px-4 py-2 text-sm border-gray-300 border`}
							>
								{index + 1}
							</button>
						))}

						<button
							disabled={current_page === last_page}
							onClick={() => setPage((prev) => prev + 1)}
							className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300"
						>
							<span className="sr-only">Next</span>
							<svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
								<path
									fillRule="evenodd"
									d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
									clipRule="evenodd"
								/>
							</svg>
						</button>
					</nav>
				</div>
			</div>
		</div>
	);
};
