import ApiService from "./ApiService";

export async function apiGetCheckIn({ type }: { type: "in" | "out" }) {
  return ApiService.fetchData({
    url: `partner/check/${type}`,
    method: "get",
  }).then((res: any) => res?.data?.data as any);
}

export async function apiGetCheckInByEventId({
  type,
  eventId,
}: {
  type: "in" | "out";
  eventId: string;
}) {
  return ApiService.fetchData({
    url: `partner/check/${type}/${eventId}`,
    method: "get",
  }).then((res: any) => res?.data?.data as any);
}

export async function apiPostCheckIn(data: any) {
  return ApiService.fetchData({
    url: `partner/scan/checkin`,
    method: "post",
    data,
  }).then((res: any) => res?.data?.data as any);
}

export async function apiPutCheckIn({
  id,
  status,
}: {
  id: string;
  status: "CheckedIn" | "CheckedOut";
}) {
  return ApiService.fetchData({
    url: `partner/checkin/${id}`,
    method: "put",
    data: { status },
  }).then((res: any) => res?.data?.data as any);
}
