import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faXmark } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { ChangePassForm } from "./setting/account/ChangePassForm";

export const PopupChangePass = ({ data, setData }: { data: any; setData: any }) => {
	return (
		<Transition appear show={!!data} as={Fragment}>
			<Dialog
				as="div"
				className="relative z-10"
				onClose={() => {
					setData(null);
				}}
			>
				<Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
					<div className="fixed inset-0 bg-black bg-opacity-25" />
				</Transition.Child>

				<div className="fixed inset-0 overflow-y-auto">
					<div className="flex min-h-full items-center justify-center p-4 text-center">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
								<div className="flex items-center justify-between w-full">
									<h3 className="text-black text-lg font-medium">Ganti Kata Sandi</h3>

									<button type="button" onClick={() => setData(null)}>
										<FontAwesomeIcon icon={faXmark as IconProp} className="mr-2 text-lg text-black" aria-hidden="true" />
									</button>
								</div>
								<div>
									<ChangePassForm data={data} />
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	);
};
