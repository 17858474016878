import { useMutation, useQuery } from "@tanstack/react-query";
import { apiGetCheckIn, apiGetCheckInByEventId, apiPutCheckIn } from "src/services";
import { Loading as GlobalLoading } from "src/components/global";
import { BaseTable } from "src/components/admin/Base-table";
import { BuildCheckinTableSchema } from "src/configs/table";
import { useState } from "react";
import debounce from "lodash/debounce";

export const CheckinForm = ({ type = "in", eventId }: { type?: "in" | "out"; eventId?: string }) => {
	const [q, setQ] = useState("");

	const { data, isLoading, refetch } = useQuery<any>([`admin`, `check`, type, eventId], async () => {
		if (eventId) {
			return await apiGetCheckInByEventId({ type: "in", eventId });
		} else {
			return await apiGetCheckIn({ type });
		}
	});

	const { mutate: putCheckIn, isLoading: loadingChangeStatus } = useMutation(async (data: { id: string; status: "CheckedOut" | "CheckedIn" }) => await apiPutCheckIn(data));

	const tableSchema = BuildCheckinTableSchema({
		type,
		refetch,
		putCheckIn,
	});

	const debouncedSearch = debounce((searchQuery) => {
		setQ(searchQuery);
	}, 300);

	const handleSearchChange = (event) => {
		const searchQuery = event.target.value;
		debouncedSearch(searchQuery);
	};

	return (
		<>
			{loadingChangeStatus && <GlobalLoading />}
			<div className="mt-3">
				<div className="bg-slate-300 rounded-md px-5 py-3 mt-8  text-right justify-between flex">
					<h3 className="font-medium text-xl">{type === "in" ? "Check in" : "Check out"}</h3>
					<div className="flex items-center">
						Total user {type === "in" ? "check in" : "check out"} <span className="ml-2 rounded-sm bg-slate-800 text-white flex items-center px-1 justify-center">{data?.total}</span>
					</div>
				</div>
				<div className="mt-5 flow-root">
					<div>
						<input type="search" onChange={(e) => handleSearchChange(e)} className="mb-2 rounded w-1/4" placeholder="Cari ..." />
					</div>
					<div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
							<div className="relative">
								<BaseTable
									datas={data?.data?.filter((value) => {
										const checkFullName = value?.ticket?.buyer?.full_name?.toLowerCase()?.includes(q.toLowerCase());
										const checkEventName = value?.ticket?.event?.event_title?.toLowerCase()?.includes(q.toLowerCase());
										const checkTicketNumber = value?.ticket?.unique_id?.toLowerCase()?.includes(q.toLowerCase());

										return checkFullName || checkEventName || checkTicketNumber;
									})}
									column={tableSchema}
									loading={isLoading}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
