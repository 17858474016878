import { useState } from "react";
import { Zone } from "src/interface";

export const FilterStatistic = ({
	setParams,
	type,
	zone,
	status_order,
	payment_method,
	params,
}: // status_ticket,
{
	setParams: any;
	type: Zone[];
	zone: Zone[];
	status_order: any[];
	payment_method: any[];
	params: any;
	// status_ticket: any[];
}) => {
	const [formValue, setFormValue] = useState<any>({});
	return (
		<>
			<div className="font-medium text-md">Pencarian</div>
			<div className="mt-2 flex-col flex md:flex-row items-center gap-4">
				<div className="w-full md:w-auto">
					<input
						type="date"
						value={params?.date || null}
						onChange={(e) =>
							setParams((prev) => ({
								...prev,
								date: e?.target?.value || undefined,
							}))
						}
						className="block w-full border-0 py-1.5 pl-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-8"
					/>
				</div>
				<div className="flex border-gray-300 border w-full md:w-auto">
					{/* <select
            onChange={(e) => {
              setParams((prev) => ({
                ...prev,
                ticket_type_id: e?.target?.value || undefined,
              }));
            }}
            className="block w-full border-0 border-r border-r-gray-300 py-1.5 text-gray-900 sm:max-w-xs sm:text-sm sm:leading-8"
          >
            <option selected value={""}>
              Tipe Tiket
            </option>
            {type?.map((value) => (
              <option key={`type-${value.id}`} value={value?.id}>
                {value.title}
              </option>
            ))}
          </select>

          <select
            onChange={(e) => {
              setParams((prev) => ({
                ...prev,
                ticket_zone_id: e?.target?.value || undefined,
              }));
            }}
            className="block w-full border-0 border-r border-r-gray-300 py-1.5 text-gray-900 sm:max-w-xs sm:text-sm sm:leading-8"
          >
            <option selected value={""}>
              Zona/Kategori Tiket
            </option>
            {zone?.map((value) => (
              <option key={`zone-${value.id}`} value={value?.id}>
                {value.title}
              </option>
            ))}
          </select> */}

					<input
						type="text"
						value={formValue?.number_identity_card || ""}
						onChange={(e) => {
							setFormValue((prev) => ({
								...prev,
								number_identity_card: e.target.value,
							}));
						}}
						onKeyDown={(e: any) => {
							if (e.keyCode === 13) {
								setParams((prev) => ({
									...prev,
									number_identity_card: e?.target?.value || undefined,
								}));
							}
						}}
						placeholder="KTP"
						className="block w-full border-0 border-r border-r-gray-300  py-1.5 text-gray-900 sm:max-w-xs sm:text-sm sm:leading-8"
					/>

					<input
						type="text"
						value={formValue?.ticket_number || ""}
						onChange={(e) => {
							setFormValue((prev) => ({
								...prev,
								ticket_number: e.target.value,
							}));
						}}
						onKeyDown={(e: any) => {
							if (e.keyCode === 13) {
								setParams((prev) => ({
									...prev,
									ticket_number: e?.target?.value || undefined,
								}));
							}
						}}
						placeholder="Tiket ID"
						className="block w-full border-0 py-1.5 text-gray-900 sm:max-w-xs sm:text-sm sm:leading-8"
					/>
				</div>

				<div className="flex border-gray-300 border w-full md:w-auto">
					<input
						type="text"
						value={formValue?.transaction_id || ""}
						onChange={(e) => {
							setFormValue((prev) => ({
								...prev,
								transaction_id: e.target.value,
							}));
						}}
						onKeyDown={(e: any) => {
							if (e.keyCode === 13) {
								setParams((prev) => ({
									...prev,
									transaction_id: e?.target?.value || undefined,
								}));
							}
						}}
						placeholder="Transaksi ID"
						className="block w-full border-0 border-r border-r-gray-300  py-1.5 text-gray-900 sm:max-w-xs sm:text-sm sm:leading-8"
					/>

					<select
						onChange={(e) => {
							setParams((prev) => ({
								...prev,
								payment_method: e?.target?.value || undefined,
							}));
						}}
						value={params?.payment_method || ""}
						className="block w-full border-0 border-r border-r-gray-300 py-1.5 text-gray-900 sm:max-w-xs sm:text-sm sm:leading-8"
					>
						<option selected value={""}>
							Metode Pembayaran
						</option>
						{payment_method?.map((value) => (
							<option key={`metode-pembayaran-${value.payment_method}`} value={value?.payment_method}>
								{value.payment_method}
							</option>
						))}
					</select>

					<select
						onChange={(e) => {
							setParams((prev) => ({
								...prev,
								order_status_id: e?.target?.value || undefined,
							}));
						}}
						value={params?.order_status_id || ""}
						className="block w-full border-0 border-r border-r-gray-300 py-1.5 text-gray-900 sm:max-w-xs sm:text-sm sm:leading-8"
					>
						<option selected value={""}>
							Status Pembelian
						</option>
						{status_order?.map((value) => (
							<option key={`status-order-${value.id}`} value={value?.id}>
								{value.status}
							</option>
						))}
					</select>
				</div>

				<button
					className="flex border-gray-300 border py-2.5 w-full md:w-[100px] text-center items-center justify-center"
					onClick={() => {
						setParams({});
						setFormValue({});
					}}
				>
					Reset
				</button>

				{/* <div className="flex border-gray-300 border">
          <select
            onChange={(e) => {
              setParams((prev) => ({
                ...prev,
                ticket_status_id: e?.target?.value || undefined,
              }));
            }}
            className="block w-full border-0 border-r border-r-gray-300 py-1.5 text-gray-900 sm:max-w-xs sm:text-sm sm:leading-8"
          >
            <option selected value={""}>
              Status Aktivasi
            </option>
            {status_ticket?.map((value) => (
              <option key={`status-order-${value.id}`} value={value?.id}>
                {value.status_label}
              </option>
            ))}
          </select>

          <input
            type="text"
            placeholder="KTP"
            onKeyDown={(e: any) => {
              if (e.keyCode === 13) {
                setParams((prev) => ({
                  ...prev,
                  number_identity_card: e?.target?.value || undefined,
                }));
              }
            }}
            className="block w-full border-0 py-1.5 text-gray-900 sm:max-w-xs sm:text-sm sm:leading-8"
          />
        </div> */}
			</div>
		</>
	);
};
