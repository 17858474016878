import { useState } from "react";
import { Link } from "react-router-dom";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../../components/ui/alert-dialog";
import { Loading } from "src/components/web";
import { useMutation, useQuery } from "@tanstack/react-query";
import { apiDeletePartnerEvent, apiGetPartnerEvent } from "src/services";
import { BaseTable } from "src/components/admin/Base-table";
import { BuildEventTableSchema } from "src/configs/table";

function ManageEvent() {
  let [selectedEvent, setSelectedEvent] = useState({
    title: "",
    id: "",
  });

  const {
    isLoading: loadingEvent,
    data: listEvent,
    refetch,
  } = useQuery(["partner", "event"], apiGetPartnerEvent);

  const { mutate: removeEvent, isLoading: loadingRemoveEvent } = useMutation(
    async (id: string) => await apiDeletePartnerEvent({ id })
  );

  const table = BuildEventTableSchema({
    setSelectedEvent,
  });

  return loadingRemoveEvent ? (
    <Loading className="mt-2" />
  ) : (
    <div>
      <AlertDialog>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle className="font-medium">
              Konfirmasi
            </AlertDialogTitle>
            <AlertDialogDescription>
              Mohon konfirmasi hapus event
              <span className="font-medium">{selectedEvent.title}</span>
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel className="rounded-md bg-gray-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600">
              Batal
            </AlertDialogCancel>
            <AlertDialogAction
              disabled={loadingRemoveEvent || loadingEvent}
              className="rounded-md bg-red-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
              onClick={() =>
                removeEvent(selectedEvent.id, {
                  onSuccess: (_) => {
                    refetch();
                  },
                })
              }
            >
              Hapus
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-3xl text-gray-900 font-medium">Events</h1>
            <p className="mt-2 text-sm text-gray-700">Manage event disini</p>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <Link
              to={`create`}
              className="block rounded-md bg-indigo-600 py-1.5 px-3 text-center text-sm font-medium leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Buat Event
            </Link>
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="relative">
                <div className="rounded-md bg-slate-100">
                  <BaseTable
                    datas={listEvent?.data}
                    column={table}
                    loading={loadingEvent}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </AlertDialog>
    </div>
  );
}

export default ManageEvent;
