import { Fragment, useEffect, useState } from "react";
import { connectAPI } from "../../api/api";
import { ITicketZone } from "../../interface/generic";
import { Transition, Dialog } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Alert, Snackbar } from "@mui/material";
import { faGridHorizontal, faTrashCan } from "@fortawesome/pro-duotone-svg-icons";
import { Loading } from "../web";

export interface IProps {}

export const TicketZone = (props: IProps) => {
	let [isLoading, setIsLoading] = useState(false);
	let [ticketZone, setTicketZone] = useState([]);
	let [isOpen, setIsOpen] = useState(false);
	let [ticketZoneDetails, setTicketZoneDetails] = useState<ITicketZone>();
	let [currentZoneId, setCurrentZoneId] = useState("");
	let [popupType, setPopupType] = useState("");
	let [isError, setIsError] = useState(false);

	const closeModal = () => {
		setIsOpen(false);
	};

	const handleClose = () => {
		setIsError(false);
	};

	const openModal = () => {
		setIsOpen(true);
	};

	const ticketZoneCreate = (load?) => {
		if (ticketZoneDetails.title === "" || ticketZoneDetails.description === "") {
			setIsError(true);
		} else {
			setIsLoading(true);
			const loginToken = localStorage.getItem("token");
			connectAPI(`partner/zone`, load, "post", {
				authorization: `Bearer ${loginToken}`,
			}).then((res) => {
				if (res.status === 200) {
					setIsLoading(false);
					const data = res.data.data;
					ticketZoneList();
					closeModal();
				} else {
					setIsLoading(false);
				}
			});
		}
	};

	const ticketZoneUpdate = (load?, id?) => {
		if (ticketZoneDetails.title === "" || ticketZoneDetails.description === "") {
			setIsError(true);
		} else {
			setIsLoading(true);
			const loginToken = localStorage.getItem("token");
			connectAPI(`partner/zone${id ? "/" + id : ""}`, load, "put", {
				authorization: `Bearer ${loginToken}`,
			}).then((res) => {
				if (res.status === 200) {
					setIsLoading(false);
					const data = res.data.data;
					ticketZoneList();
					closeModal();
					setCurrentZoneId("");
				} else {
					setIsLoading(false);
				}
			});
		}
	};

	const ticketZoneDelete = (load?, id?) => {
		setIsLoading(true);
		const loginToken = localStorage.getItem("token");
		connectAPI(`partner/zone${id ? "/" + id : ""}`, load, "delete", {
			authorization: `Bearer ${loginToken}`,
		}).then((res) => {
			if (res.status === 200) {
				setIsLoading(false);
				const data = res.data.data;
				ticketZoneList();
				closeModal();
				setCurrentZoneId("");
			} else {
				setIsLoading(false);
			}
		});
	};

	const ticketZoneList = (load?) => {
		setIsLoading(true);
		const loginToken = localStorage.getItem("token");
		connectAPI(`partner/zone`, load, "get", {
			authorization: `Bearer ${loginToken}`,
		}).then((res) => {
			if (res.status === 200) {
				setIsLoading(false);
				const data = res.data.data;
				setTicketZone(data);
			}
		});
	};

	useEffect(() => {
		ticketZoneList();
	}, []);

	return (
		<>
			{isLoading ? (
				<Loading />
			) : (
				<div className="rounded border bg-slate-100  p-5">
					<div className="flex items-center mb-2 justify-between ">
						<p className="text-lg font-medium">Kategori</p>
						<button
							type="button"
							className="rounded bg-white px-2 py-1 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
							onClick={(e) => {
								openModal();
								setPopupType("create");
								setTicketZoneDetails({ title: "", description: "" });
							}}
						>
							Tambah kategori
						</button>
					</div>
					<div className="divide-y divide-gray-300">
						{ticketZone.map((zone) => {
							return (
								<div className="py-4 flex items-center hover:cursor-pointer relative" key={zone.id}>
									<FontAwesomeIcon className="text-blue-500 text-md mr-2" aria-hidden="true" icon={faGridHorizontal as IconProp} />

									<p
										onClick={(e) => {
											setTicketZoneDetails({
												...ticketZoneDetails,
												title: zone.title,
												description: zone.description,
											});
											setCurrentZoneId(zone.id);
											openModal();
											setPopupType("update");
										}}
										data-zone-id={zone.id}
									>
										{zone.title}
									</p>
									<FontAwesomeIcon
										className="text-red-500 absolute right-0"
										aria-hidden="true"
										onClick={(e) => {
											e.preventDefault();
											ticketZoneDelete("", zone.id);
										}}
										icon={faTrashCan as IconProp}
									/>
								</div>
							);
						})}
					</div>
				</div>
			)}
			<Transition appear show={isOpen} as={Fragment}>
				<Dialog as="div" className="relative z-10" onClose={() => {}}>
					<Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
						<div className="fixed inset-0 bg-black bg-opacity-25" />
					</Transition.Child>

					<div className="fixed inset-0 overflow-y-auto">
						<div className="flex min-h-full items-center justify-center p-4 text-center">
							<Snackbar open={isError} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
								<Alert variant="filled" severity="error" onClose={handleClose} sx={{ width: "100%" }}>
									Nama atau Deskripsi tiket tidak bisa kosong
								</Alert>
							</Snackbar>
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95"
							>
								<Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
									<Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
										{ticketZoneDetails?.title}
									</Dialog.Title>
									<div className="mt-2">
										<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
											<label htmlFor="ticket_title" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
												Nama tiket
											</label>
											<div className="mt-2 sm:col-span-2 sm:mt-0">
												<input
													id="ticket_title"
													name="ticket_title"
													type="text"
													autoComplete="ticket_title"
													value={ticketZoneDetails?.title}
													onChange={(e) => {
														setTicketZoneDetails({
															...ticketZoneDetails,
															title: e.target.value,
														});
													}}
													className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
												/>
											</div>
										</div>
										<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
											<label htmlFor="ticket_description" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
												Deskripsi tiket
											</label>
											<div className="mt-2 sm:col-span-2 sm:mt-0">
												<input
													id="ticket_description"
													name="ticket_description"
													type="text"
													autoComplete="ticket_description"
													value={ticketZoneDetails?.description}
													onChange={(e) => {
														setTicketZoneDetails({
															...ticketZoneDetails,
															description: e.target.value,
														});
													}}
													className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
												/>
											</div>
										</div>
									</div>

									<div className="mt-4 flex justify-between">
										<button
											type="button"
											className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
											onClick={(e) => {
												if (popupType === "update") {
													ticketZoneUpdate(ticketZoneDetails, currentZoneId);
												}
												if (popupType === "create") {
													ticketZoneCreate(ticketZoneDetails);
												}
											}}
										>
											{popupType === "update" && "Ganti"}
											{popupType === "create" && "Tambah"}
										</button>
										<button
											type="button"
											className="inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
											onClick={(e) => {
												closeModal();
											}}
										>
											Cancel
										</button>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
		</>
	);
};
