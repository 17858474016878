import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tab } from "@headlessui/react";
import { Fragment } from "react";
import { EVENT_FORM_NAVIGATION } from "src/constants";
import { Event } from "src/interface";
import { EventAboutForm } from "./EventAboutForm";
import { EventGalleryForm } from "./EventGalleryForm";
import { EventTicketForm } from "./EventTicketForm";
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useQuery } from "@tanstack/react-query";
import { EventStatisticForm } from "./EventStatisticForm";
import { useParams } from "react-router-dom";
import CheckInPage from "src/pages/admin/check-in";
import { Loading } from "src/components/global";
import { apiGetPartner } from "src/services";

interface EventFormProps {
	event?: Event;
	refetch?: <TPageData>(
		options?: RefetchOptions & RefetchQueryFilters<TPageData>
	) => Promise<QueryObserverResult<Event, unknown>>;
	isLoading?: boolean;
}

export const EventForm = ({ event, refetch, isLoading }: EventFormProps) => {
	const eventFormNavigation = EVENT_FORM_NAVIGATION(event);

	const { data: partnerDetails, isLoading: loadingPartner } = useQuery<any>([`partner`, `website`], apiGetPartner);

	const { id } = useParams();

	return (
		<div>
			<h1 className="text-3xl font-medium mb-5">{event?.event_title || "Buat Event Baru"}</h1>
			<Tab.Group>
				<Tab.List className="border-b border-slate-300 flex">
					{eventFormNavigation.map((menu, index) => (
						<Tab as={Fragment} key={`menu-${menu.name}-${index}`}>
							{({ selected }) => (
								<button
									disabled={menu.disabled}
									className={`${menu.disabled && "opacity-50"} ${
										selected
											? "border-b-2 border-blue-600 font-medium text-slate-900"
											: "border-b-2 border-white"
									} mr-12 py-2 text-slate-500 focus-visible:outline-0`}
								>
									<FontAwesomeIcon
										className={`${menu.disabled ? "text-gray-500" : menu.iconColor} mr-3 text-lg`}
										aria-hidden="true"
										icon={menu.icon as IconProp}
									/>
									{menu.name}
								</button>
							)}
						</Tab>
					))}
				</Tab.List>
				<Tab.Panels>
					<Tab.Panel>
						{loadingPartner ? (
							<Loading />
						) : (
							<EventAboutForm data={event} refetch={refetch} users={partnerDetails?.data?.data?.users} />
						)}
					</Tab.Panel>
					<Tab.Panel>
						<EventGalleryForm data={event} refetch={refetch} />
					</Tab.Panel>
					<Tab.Panel>
						<EventTicketForm data={event} isLoading={isLoading} refetch={refetch} />
					</Tab.Panel>
					<Tab.Panel>
						<CheckInPage eventId={id} />
					</Tab.Panel>
					<Tab.Panel>
						<EventStatisticForm />
					</Tab.Panel>
				</Tab.Panels>
			</Tab.Group>
		</div>
	);
};
