import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useForm, useWatch } from "react-hook-form";
import { City, Province, SubDistrict, Village } from "src/interface";
import { apiGetCity, apiGetSubDistrict, apiGetVillage, apiPutActivateTicket } from "src/services";
import { Sort, useToken } from "src/utils";
import { z } from "zod";
import { SelectComponent } from "../ticketing/detail/DetailInputComponent";
import { toast } from "react-toastify";
import { Loading } from "src/components/web";
import { useEffect } from "react";

interface AktivasiTicketFormProps {
	data: any;
	unique_id: string;
	refetch: any;
	province: Province[];
}

const AktivasiTicketSchema = z.object({
	full_name: z.string().nonempty("Nama lengkap wajib diisi"),
	number_identity_card: z
		.string()
		.max(16, "Nomor KTP tidak boleh lebih dari 16 digit")
		.nonempty("Nomor KTP wajib diisi"),
	mobile_number: z.string().nonempty("Nomor telepon wajib diisi"),
	unique_id: z.string().nonempty("Nomor tiket wajib diisi"),
	address: z.string().nonempty("Alamat wajib diisi"),
	provinsi_id: z.number({
		required_error: "Provinsi wajib diisi",
		invalid_type_error: "Provinsi wajib diisi",
	}),
	kota_id: z.number({
		required_error: "Kota wajib diisi",
		invalid_type_error: "Kota wajib diisi",
	}),
	kecamatan_id: z.number({
		required_error: "Kecamatan wajib diisi",
		invalid_type_error: "Kecamatan wajib diisi",
	}),
	kelurahan_id: z.number({
		required_error: "Kelurahan wajib diisi",
		invalid_type_error: "Kelurahan wajib diisi",
	}),
});

type AktivasiTicket = z.infer<typeof AktivasiTicketSchema>;

export const AktivasiTicketForm = ({ data, unique_id, refetch, province }: AktivasiTicketFormProps) => {
	const defaultValue: AktivasiTicket = {
		full_name: data?.full_name ?? "",
		number_identity_card: data?.number_identity_card ?? "",
		mobile_number: data?.mobile_number ?? "",
		unique_id,
		address: data?.address ?? "",
		provinsi_id: data?.provinsi_id ?? province?.[0]?.id,
		kota_id: data?.kota_id ?? null,
		kecamatan_id: data?.kecamatan_id ?? null,
		kelurahan_id: data?.kelurahan_id ?? null,
	};

	const { token } = useToken();

	const {
		register,
		handleSubmit,
		control,
		setValue,
		formState: { errors },
	} = useForm<AktivasiTicket>({
		resolver: zodResolver(AktivasiTicketSchema),
		defaultValues: defaultValue,
	});

	const province_id = useWatch({
		control,
		name: "provinsi_id",
	});

	const city_id = useWatch({
		control,
		name: "kota_id",
	});

	const sub_district_id = useWatch({
		control,
		name: "kecamatan_id",
	});

	const { data: city } = useQuery<City[]>({
		queryKey: ["city", province_id],
		queryFn: async () => await apiGetCity({ province_id, token }),
		enabled: !!province_id,
	});

	const { data: subdistrict } = useQuery<City[]>({
		queryKey: ["subdistrict", city_id],
		queryFn: async () => await apiGetSubDistrict({ city_id, token }),
		enabled: !!city_id,
	});

	const { data: village } = useQuery<City[]>({
		queryKey: ["village", sub_district_id],
		queryFn: async () => await apiGetVillage({ sub_district_id, token }),
		enabled: !!sub_district_id,
	});

	const { mutate: activateTicket, isLoading: loadingActivateTicket } = useMutation(
		async (dataForm) => await apiPutActivateTicket({ token, id: data?.id, data: dataForm })
	);

	useEffect(() => {
		if (data?.ticket?.ticket_status?.status_label !== "Active") {
			setValue("kota_id", null);
			setValue("kelurahan_id", null);
			setValue("kecamatan_id", null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [province_id, city]);

	useEffect(() => {
		if (data?.ticket?.ticket_status?.status_label !== "Active") {
			setValue("kelurahan_id", null);
			setValue("kecamatan_id", null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [city_id, subdistrict]);

	useEffect(() => {
		if (data?.ticket?.ticket_status?.status_label !== "Active") {
			setValue("kelurahan_id", null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sub_district_id, village]);

	return (
		<form
			onSubmit={handleSubmit((value) =>
				activateTicket(value, {
					onSuccess: (res) => {
						toast.success("Aktivasi tiket berhasil");
						refetch();
					},
				})
			)}
		>
			{data?.ticket?.event?.event_type !== "Online" ? (
				<div className="bg-white p-5 mb-5 flex items-center justify-center">
					<img
						src={
							data?.ticket?.ticket_status?.status_label === "Active"
								? data?.ticket?.qrcode_url
								: `/barcode.png`
						}
						alt={data?.id}
					/>
				</div>
			) : data?.ticket?.ticket_status?.status_label === "Active" ? (
				<div className="bg-white p-5 mb-5 flex items-center justify-center text-sm text-gray-900">
					<a href={data?.ticket.event?.online_url} target="_blank" rel="noreferrer">
						{data?.ticket.event?.online_url}
					</a>
				</div>
			) : (
				""
			)}

			<div className="mb-3">
				<label htmlFor="full_name" className="block text-sm font-medium leading-6 text-gray-900">
					Nama Lengkap
				</label>
				<div>
					<input
						type="text"
						{...register("full_name")}
						name="full_name"
						id="full_name"
						disabled={data?.ticket?.ticket_status?.status_label === "Active"}
						autoComplete="full_name"
						className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-200"
					/>
					{errors?.full_name?.message ? (
						<p className="text-red-500 text-xs italic mt-2">{errors?.full_name?.message}</p>
					) : (
						""
					)}
				</div>
			</div>
			<div className="mb-3">
				<label htmlFor="unique_id" className="block text-sm font-medium leading-6 text-gray-900">
					Nomor Tiket
				</label>
				<div>
					<input
						type="text"
						{...register("unique_id")}
						name="unique_id"
						id="unique_id"
						autoComplete="unique_id"
						disabled
						className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-200"
					/>
					{errors?.unique_id?.message ? (
						<p className="text-red-500 text-xs italic mt-2">{errors?.unique_id?.message}</p>
					) : (
						""
					)}
				</div>
			</div>

			<div className="mb-3">
				<label htmlFor="mobile_number" className="block text-sm font-medium leading-6 text-gray-900">
					Nomor Ponsel
				</label>
				<div>
					<input
						type="text"
						{...register("mobile_number")}
						name="mobile_number"
						id="mobile_number"
						autoComplete="mobile_number"
						maxLength={14}
						disabled
						className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-200"
					/>
					{errors?.mobile_number?.message ? (
						<p className="text-red-500 text-xs italic mt-2">{errors?.mobile_number?.message}</p>
					) : (
						""
					)}
				</div>
			</div>
			<div className="mb-3">
				<label htmlFor="number_identity_card" className="block text-sm font-medium leading-6 text-gray-900">
					NIK
				</label>
				<div>
					<input
						type="text"
						{...register("number_identity_card")}
						name="number_identity_card"
						disabled={data?.ticket?.ticket_status?.status_label === "Active"}
						id="number_identity_card"
						maxLength={16}
						autoComplete="number_identity_card"
						className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-200"
					/>
					{errors?.number_identity_card?.message ? (
						<p className="text-red-500 text-xs italic mt-2">{errors?.number_identity_card?.message}</p>
					) : (
						""
					)}
				</div>
			</div>
			<div className="mb-3">
				<label htmlFor="address" className="block text-sm font-medium leading-6 text-gray-900">
					Alamat
				</label>
				<div>
					<input
						type="text"
						{...register("address")}
						disabled={data?.ticket?.ticket_status?.status_label === "Active"}
						name="address"
						id="address"
						autoComplete="address"
						className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-200"
					/>
					{errors?.address?.message ? (
						<p className="text-red-500 text-xs italic mt-2">{errors?.address?.message}</p>
					) : (
						""
					)}
				</div>
			</div>
			<SelectComponent<Province>
				title={"Provinsi"}
				id={"provinsi_id"}
				disabled={data?.ticket?.ticket_status?.status_label === "Active" || !!!province?.length}
				register={register("provinsi_id", { valueAsNumber: true })}
				name={"provinsi_id"}
				errors={(errors?.provinsi_id?.message as string) || undefined}
				optiondata={Sort(province, "name")}
				keyname="name"
				keyvalue="id"
			/>
			<SelectComponent<City>
				title={"Kota"}
				id={"kota_id"}
				register={register("kota_id", { valueAsNumber: true })}
				disabled={data?.ticket?.ticket_status?.status_label === "Active" || !!!city?.length}
				name={"kota_id"}
				errors={(errors?.kota_id?.message as string) || undefined}
				optiondata={Sort(city, "name")}
				keyname="name"
				keyvalue="id"
			/>
			<SelectComponent<SubDistrict>
				title={"Kecamatan"}
				id={"kecamatan_id"}
				register={register("kecamatan_id", { valueAsNumber: true })}
				disabled={data?.ticket?.ticket_status?.status_label === "Active" || !!!subdistrict?.length}
				name={"kecamatan_id"}
				errors={(errors?.kecamatan_id?.message as string) || undefined}
				optiondata={Sort(subdistrict, "name")}
				keyname="name"
				keyvalue="id"
			/>
			<SelectComponent<Village>
				title={"Kelurahan"}
				id={"kelurahan_id"}
				register={register("kelurahan_id", { valueAsNumber: true })}
				disabled={data?.ticket?.ticket_status?.status_label === "Active" || !!!village?.length}
				name={"kelurahan_id"}
				errors={(errors?.kelurahan_id?.message as string) || undefined}
				optiondata={Sort(village, "name")}
				keyname="name"
				keyvalue="id"
			/>
			{data?.ticket?.ticket_status?.status_label !== "Active" ? (
				<button
					type="submit"
					disabled={loadingActivateTicket}
					className={`w-full justify-center inline-flex items-center rounded-md border border-transparent bg-blue-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:bg-blue-200`}
				>
					{loadingActivateTicket ? <Loading title="Sedang diproses" /> : "Aktifkan Tiket"}
				</button>
			) : (
				""
			)}
		</form>
	);
};
