interface WysiwygProps {
	text?: string;
}

export const Wysiwyg = ({ text }: WysiwygProps) => {
	return (
		<>
			<div className="mb-12">
				<div className="ql-editor px-0" dangerouslySetInnerHTML={{ __html: text }} />
			</div>
		</>
	);
};
