import ApiService from "./ApiService";

export async function apiGetAccounting() {
	return ApiService.fetchData({
		url: `partner/accounting`,
		method: "get",
	});
}

export async function apiGetDiscountVoucher() {
	return ApiService.fetchData({
		url: `partner/vouchers`,
		method: "get",
	});
}

export async function apiPostDiscountVoucher({ data }) {
	return ApiService.fetchData({
		url: `partner/vouchers`,
		method: "post",
		data,
	});
}

export async function apiPutDiscountVoucher({ data, id }) {
	return ApiService.fetchData({
		url: `partner/vouchers/${id}`,
		method: "put",
		data,
	});
}

export async function apiDeleteDiscountVoucher({ id }) {
	return ApiService.fetchData({
		url: `partner/vouchers/${id}`,
		method: "delete",
	});
}
