import { Fragment, useEffect } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { DateTime } from "src/interface";
import { _getDate } from "src/utils";

export interface EventOverviewProps {
	dateAndTime: DateTime[];
	selectedDate: DateTime;
	setSelectedDate: React.Dispatch<React.SetStateAction<DateTime>>;
}

export const EventOverview = ({ dateAndTime, selectedDate, setSelectedDate }: EventOverviewProps) => {
	useEffect(() => {
		setSelectedDate(dateAndTime[0]);
	}, [dateAndTime, setSelectedDate]);

	return (
		<>
			<h2 className="mb-3 font-medium text-2xl">Tanggal dan Jam event</h2>
			<div className="bg-orange-300 p-5 rounded-md">
				<div>
					<Listbox value={selectedDate} onChange={setSelectedDate}>
						<Listbox.Label>Tanggal dan Jam</Listbox.Label>
						<div className="relative mt-1">
							<Listbox.Button className="relative w-full cursor-default rounded bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-blue-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
								<span className="block truncate">
									<span className="mr-2">{_getDate(selectedDate?.date || dateAndTime[0]?.date)}</span>
									<span className="mr-2">-</span>
									<span>{selectedDate?.time || dateAndTime[0]?.time}</span>
								</span>
								<span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
									<FontAwesomeIcon className="text-sm text-gray-400" aria-hidden="true" icon={faChevronDown as IconProp} />
								</span>
							</Listbox.Button>
							<Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
								<Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
									{dateAndTime.map((dt, id) => (
										<Listbox.Option
											key={id}
											className={({ active }) => `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? "bg-green-100 text-green-900" : "text-gray-900"}`}
											value={dt}
										>
											{({ selected }) => (
												<>
													<span className={`block truncate ${selected ? "font-medium" : "font-normal"}`}>
														<span className="mr-2">{_getDate(dt?.date)}</span>
														<span className="mr-2">-</span>
														{selectedDate?.time || dateAndTime[0]?.time}
													</span>
													{selected ? (
														<span className="absolute inset-y-0 left-0 flex items-center pl-3 text-green-600">
															<FontAwesomeIcon icon={faCheck as IconProp} className="text-md" aria-hidden="true" />
														</span>
													) : null}
												</>
											)}
										</Listbox.Option>
									))}
								</Listbox.Options>
							</Transition>
						</div>
					</Listbox>
				</div>
			</div>
		</>
	);
};
