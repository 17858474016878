import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tab } from "@headlessui/react";
import { Fragment } from "react";
import { CHECKIN_FORM_NAVIGATION } from "src/constants/admin/checkin/checkin-form-navigation.constant";
import { CheckinForm } from "src/partials/admin/checkin";

interface CheckInPageProps {
  eventId?: string;
}

const CheckInPage = ({ eventId }: CheckInPageProps) => {
  return (
    <>
      {!eventId ? (
        <>
          <h1 className="text-3xl font-medium leading-6 text-gray-900">
            Checkin
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Manage checkin disini untuk add, delete dan update.
          </p>
        </>
      ) : (
        ""
      )}
      <div className="mt-4">
        <Tab.Group>
          <Tab.List className="border-b border-slate-300 flex">
            {CHECKIN_FORM_NAVIGATION.map((menu, index) => (
              <Tab as={Fragment} key={`menu-${menu.name}-${index}`}>
                {({ selected }) => (
                  <button
                    disabled={menu.disabled}
                    className={`${menu.disabled && "opacity-50"} ${
                      selected
                        ? "border-b-2 border-blue-600 font-medium text-slate-900"
                        : "border-b-2 border-white"
                    } mr-12 py-2 text-slate-500 focus-visible:outline-0`}
                  >
                    <FontAwesomeIcon
                      className={`${
                        menu.disabled ? "text-gray-500" : menu.iconColor
                      } mr-3 text-lg`}
                      aria-hidden="true"
                      icon={menu.icon as IconProp}
                    />
                    {menu.name}
                  </button>
                )}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel>
              <CheckinForm eventId={eventId || null} />
            </Tab.Panel>
            <Tab.Panel>
              <CheckinForm type="out" eventId={eventId || null} />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </>
  );
};

export default CheckInPage;
