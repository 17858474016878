import { AppConfig } from "src/configs";
import ApiService from "./ApiService";
import { SubDistrict } from "src/interface";

export async function apiGetSubDistrict({
  city_id,
  token,
}: {
  city_id: number;
  token: string;
}): Promise<SubDistrict[]> {
  return ApiService.fetchData<SubDistrict[]>({
    url: `kecamatan/${city_id}`,
    method: "get",
    headers: {
      mastertoken: AppConfig.masterToken,
      subdomain: AppConfig.subDomain,
      usertoken: token,
    },
  }).then((res: any) => res?.data?.data as SubDistrict[]);
}
