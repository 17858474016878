import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Fragment, useState } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { faBars } from "@fortawesome/pro-duotone-svg-icons";
import { MasterData } from "src/interface";
import { useNavigate } from "react-router-dom";
import { Loading } from "../global";

const navigation = [
  {
    name: "Event",
    href: "#",
  },
  {
    name: "News",
    href: "#",
  },
  {
    name: "Ticket",
    href: "/admin/event",
  },
  {
    name: "Cek Tiket",
    href: "/admin/event",
  },
];

interface HeaderProps {
  data?: MasterData;
}

export const Header = ({ data }: HeaderProps) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <div className="bg-slate-900">
        <div className="max-w-screen-xl mx-auto px-5 lg:px-0">
          <div
            className="grid grid-cols-2 gap-4 py-3 justify-between lg:px-0 cursor-pointer"
            onClick={() => navigate("/")}
          >
            <img
              className="h-12 object-cover"
              src={data?.logo_url ?? `/logo-white.png`}
              alt={data?.partner_name}
            />
            <div className="flex justify-end items-center">
              <button
                type="button"
                className="inline-flex items-center justify-center rounded-md text-gray-500 hover:text-white md:hidden"
                onClick={() => setSidebarOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>

                <FontAwesomeIcon
                  className="text-2xl"
                  aria-hidden="true"
                  icon={faBars as IconProp}
                />
              </button>
              <div className="space-x-4 hidden md:flex justify-end">
                {/* <a
                  className="text-white hover:underline hover:transition-all underline-offset-4"
                  href="/"
                >
                  Upcoming Events
                </a>
                <a
                  className="text-white hover:underline hover:transition-all underline-offset-4"
                  href="/"
                >
                  News
                </a>
                <a
                  className="text-white hover:underline hover:transition-all underline-offset-4"
                  href="/"
                >
                  Tickets
                </a> */}
                <a
                  className="text-white hover:underline hover:transition-all underline-offset-4 border-r border-slate-800 pr-4"
                  href="/"
                >
                  Event
                </a>
                <a
                  className="text-white hover:underline hover:transition-all underline-offset-4"
                  href="/ticket-check"
                >
                  Cek Tiket
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-slate-900 focus:outline-none">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="h-0 flex-1 overflow-y-auto pt-5 pb-4">
                  <div className="flex flex-shrink-0 items-center px-4">
                  <img
              className="h-12 object-cover"
              src={data?.logo_url ?? `/logo-white.png`}
              alt={data?.partner_name}
            />
                  </div>
                  <nav aria-label="Sidebar" className="mt-5">
                    <div className="px-4 flex flex-col">
                      {/* {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={
                            "text-white group flex items-center rounded-md px-2 py-2 text-base font-medium"
                          }
                        >
                          {item.name}
                        </a>
                      ))} */}
                      <a
                  className="text-white hover:underline hover:transition-all underline-offset-4 border-b border-slate-800 pb-4 mb-4"
                  href="/"
                >
                  Event
                </a>
                       <a
                  className="text-white hover:underline hover:transition-all underline-offset-4"
                  href="/ticket-check"
                >
                  Cek Tiket
                </a>
                    </div>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0" aria-hidden="true">
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
