import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCalendar, faClock } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { _getDate, _getTime } from "src/utils";

export const BuildStatisticTicketTableSchema = () => {
	return [
		{
			header: "Tanggal",
			cell: (value) => {
				return (
					<>
						<div>
							<FontAwesomeIcon className="mr-2" aria-hidden="true" icon={faCalendar as IconProp} />
							{_getDate(value?.created_at)}
						</div>
						<div>
							<FontAwesomeIcon className="mr-2" aria-hidden="true" icon={faClock as IconProp} />
							{_getTime(value?.created_at)}
						</div>
					</>
				);
			},
		},
		{
			header: "No. Tiket",
			cell: (value) => <span>{value.unique_id}</span>,
		},
		{
			header: "Nama pemegang tiket",
			cell: (value) => <span>{value.buyer.full_name}</span>,
		},
		{
			header: "Kategori",
			cell: (value) => <span>{value.zone.title}</span>,
		},
		{
			header: "Tipe",
			cell: (value) => <span>{value.type.title}</span>,
		},
		{
			header: "Status",
			cell: (value) => <span>{value.ticket_status.status_label}</span>,
		},
	];
};
