interface EventDetailsProps {
	details?: string;
}

export const EventDetails = ({ details }: EventDetailsProps) => {
	return (
		<div className="mb-12">
			<div className="text-gray-600">
				<div className="ql-editor px-0" dangerouslySetInnerHTML={{ __html: details }} />
			</div>
		</div>
	);
};
