import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { MultiSelect } from "react-multi-select-component";
import { Loading } from "src/components/web";
import { GetInsightProvince } from "src/services/InsightService";

export const InsightLocation = ({ listEvent }) => {
	const [selected, setSelected] = useState([]);
	const [series, setSeries] = useState<any>([
		{
			name: "jumlah",
			data: [],
		},
	]);
	const [options, setOptions] = useState<any>({
		chart: {
			height: 350,
			type: "bar",
		},
		plotOptions: {
			bar: {
				borderRadius: 10,
				dataLabels: {
					position: "top", // top, center, bottom
				},
			},
		},
		dataLabels: {
			enabled: true,
			offsetY: -20,
			style: {
				fontSize: "12px",
				colors: ["#304758"],
			},
		},
		xaxis: {
			categories: [],
			position: "bottom",
			axisBorder: {
				show: true,
			},
			axisTicks: {
				show: true,
			},
			tooltip: {
				enabled: true,
			},
		},
	});

	const { data: insightProvince, isLoading: loadingInsightProvince } = useQuery<any>(
		[`insight`, `province`, selected],
		async () =>
			await GetInsightProvince({ event_id: selected?.map((item) => item?.value)?.toString() || undefined })
	);

	useEffect(() => {
		if (insightProvince?.length) {
			const xaxis = insightProvince?.map((item) => item?.province_name);
			setOptions((prev) => ({ ...prev, xaxis: { ...prev?.xaxis, categories: xaxis } }));
			const seriesData = insightProvince?.map((item) => item?.total_buyer);
			setSeries([
				{
					name: "jumlah",
					data: seriesData,
				},
			]);
		}
	}, [insightProvince]);

	return (
		<div className="mt-10">
			<div className="flex items-center justify-between">
				<h1 className="text-slate-500 text-xl font-medium">Event di terpilih untuk di tampilkan</h1>
				<MultiSelect
					options={listEvent?.map((item) => ({
						label: item?.event_title,
						value: item?.id,
					}))}
					value={selected}
					onChange={setSelected}
					labelledBy="Select"
					className="w-[200px] max-w-[200px]"
				/>
			</div>

			{loadingInsightProvince ? (
				<Loading />
			) : !insightProvince ? (
				<div className="text-center mt-10">Data Tidak tersedia</div>
			) : (
				<div className="mt-10">
					<ReactApexChart options={options} series={series} type="bar" height={350} />
				</div>
			)}
		</div>
	);
};
