import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faXmark } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useMutation,
} from "@tanstack/react-query";
import { serialize } from "object-to-formdata";
import { useState } from "react";
import { toast } from "react-toastify";
import { ImageUpload } from "src/components/global/imageUpload";
import { Event } from "src/interface";
import {
  apiDeleteGalleryImage,
  apiPostBannerEvent,
  apiPostGallery,
} from "src/services/GalleryService";

interface EventGalleryFormProps {
  data?: Event;
  refetch?: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<QueryObserverResult<Event, unknown>>;
}

export const EventGalleryForm = ({ data, refetch }: EventGalleryFormProps) => {
  const [eventImage, setEventImage] = useState<any>();
  const [imageGallery, setImageGallery] = useState<any>();

  const { mutate: postBannerEvent, isLoading: loadingBannerEvent } =
    useMutation(
      async ({ formData, id }: { formData: FormData; id: string }) =>
        await apiPostBannerEvent({ formData, id })
    );

  const { mutate: postGallery, isLoading: loadingGallery } = useMutation(
    async (formData: FormData) => await apiPostGallery({ formData })
  );

  const { mutate: deleteGallery, isLoading: loadingDeleteGallery } =
    useMutation(async (id: string) => await apiDeleteGalleryImage(id));

  return (
    <>
      <div className="mt-10">
        <h2 className="text-2xl font-medium border-b pb-3">Galeri gambar</h2>
      </div>
      <div className="space-y-8 border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:pb-0">
        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
          <label
            htmlFor="event_name"
            className="block text-sm font-medium leading-8 text-gray-900 sm:pt-1.5"
          >
            Banner
          </label>
          <div className="mt-2 sm:col-span-2 sm:mt-0">
            <ImageUpload
              setCollectionItem={setEventImage}
              collectionItem={eventImage}
              loading={loadingBannerEvent}
              projectId={data.id}
              type={"banner_image_url"}
              imageFunction={() => {
                const formData = serialize(eventImage);
                postBannerEvent(
                  { formData, id: data?.id },
                  {
                    onSuccess: (res) => {
                      toast.success("Banner berhasil diupload");
                      refetch();
                    },
                  }
                );
              }}
            />

            {data?.banner_image_url ? (
              <>
                <p className="mt-5 text-sm font-medium mb-3 uppercase">
                  Galeri gambar
                </p>
                <div className="grid grid-cols-1 gap-4  p-4 bg-slate-100">
                  <img src={data?.banner_image_url} alt="" />
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
          <label
            htmlFor="event_name"
            className="block text-sm font-medium leading-8 text-gray-900 sm:pt-1.5"
          >
            Galeri gambar event
          </label>
          <div className="mt-2 sm:col-span-2 sm:mt-0">
            <ImageUpload
              multipleImage
              setCollectionItem={setImageGallery}
              collectionItem={imageGallery}
              loading={loadingGallery}
              projectId={data?.id}
              type={"image_url"}
              imageFunction={() => {
                const formData = serialize(imageGallery);
                postGallery(formData, {
                  onSuccess: (res) => {
                    toast.success("Gambar berhasil diupload");
                    refetch();
                  },
                });
              }}
            />

            {data?.images?.length ? (
              <>
                <p className="mt-5 text-sm font-medium mb-3 uppercase">
                  Galeri gambar
                </p>
                <div className="grid grid-cols-4 gap-4  p-4 bg-slate-100">
                  {data?.images?.map((image, i) => {
                    return (
                      <div
                        key={i}
                        className="h-32 relative border p-3 hover:shadow-md hover:cursor-pointer hover:border-slate-700"
                      >
                        <button
                          disabled={loadingDeleteGallery}
                          className="absolute -top-2 -right-2 text-md z-20 p-1 bg-red-500 w-5 h-5 flex items-center justify-center rounded-full disabled:cursor-not-allowed disabled:bg-red-200"
                          onClick={(e) => {
                            deleteGallery(image?.id?.toString(), {
                              onSuccess: (res) => {
                                toast.success("Gambar berhasil dihapus");
                                refetch();
                              },
                            });
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faXmark as IconProp}
                            className="text-white text-xs"
                          />
                        </button>
                        <img
                          src={image.image_url}
                          className="absolute w-full h-full top-0 left-0 object-cover"
                          alt=""
                        />
                      </div>
                    );
                  })}
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};
