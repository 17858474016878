import { AppConfig } from "src/configs";
import ApiService from "./ApiService";
import { User } from "src/interface";

export async function apiGetUser() {
	return ApiService.fetchData({
		url: `partner/profile`,
		method: "get",
	}).then((res: any) => res.data.data as any);
}

export async function apiGetUserDetail() {
	return ApiService.fetchData({
		url: `partner/website`,
		method: "get",
	}).then((res: any) => res.data.data);
}

export async function apiPostWebsite(data: any) {
	return ApiService.fetchData({
		url: `partner/website`,
		method: "post",
		data,
	});
}

export async function apiPostLogin(data) {
	return ApiService.fetchData({
		url: `login`,
		method: `post`,
		data,
		headers: {
			mastertoken: AppConfig.masterToken,
			subdomain: AppConfig.subDomain,
		},
	});
}

export async function apiPostLogout() {
	return ApiService.fetchData({
		url: `logout`,
		method: `get`,
	});
}

export async function apiGetPartner() {
	return ApiService.fetchData({
		url: `partner/website`,
		method: `get`,
	});
}

export async function apiGetUserProfile() {
	return ApiService.fetchData({
		url: `partner/profile`,
		method: `get`,
	});
}

export async function apiGetPartnerAccount({ id }: { id: string }): Promise<User> {
	return ApiService.fetchData({
		url: `partner/users/${id}`,
		method: "get",
	}).then((res: any) => res?.data?.data as User);
}

export async function apiPutPartnerAccount({ id, data }: { id: string; data: any }) {
	return ApiService.fetchData({
		url: `partner/users/${id}`,
		method: "post",
		data,
	});
}

export async function apiPostPartnerAccount({ data }: { data: any }) {
	return ApiService.fetchData({
		url: `partner/users`,
		method: "post",
		data,
	});
}

export async function apiDeletePartnerAccount({ id }: { id: string }) {
	return ApiService.fetchData({
		url: `partner/users/${id}`,
		method: "delete",
	});
}
