import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";

export interface IProps {
  text?: string;
}

export const Loading = (props: IProps) => {
  return (
    <div className="fixed w-full h-full bg-slate-900/70 z-40 items-center justify-center flex top-0 start-0">
      <div className="bg-slate-950 w-96 rounded p-4 flex flex-col items-center justify-center">
        {/* <FontAwesomeIcon
          icon={faSpinnerThird as IconProp}
          className="text-xl mb-2 text-white"
          aria-hidden="true"
          spin
        /> */}
        
        <div className="text-white">{props.text ? props.text : "Loading"}</div>
      </div>
    </div>
  );
};
