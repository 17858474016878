import { Fragment, useEffect, useState } from "react";
import { Dialog, Popover, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
	faBrowser,
	faCameraViewfinder,
	faChevronCircleDown,
	faCogs,
	faGauge,
	faPersonToPortal,
	faTicket,
	faDollarCircle,
	faLightbulbOn,
} from "@fortawesome/pro-duotone-svg-icons";
import { Alert, Snackbar } from "@mui/material";
import { classNames } from "../functions/global-functions";
import { faBells } from "@fortawesome/pro-regular-svg-icons";
import dayjs from "dayjs";
import { DATE_FORMAT_DD_MMM_YYYY, TIME_FORMAT_HH_MM } from "src/constants";
import { faCalendarLines, faClockEight } from "@fortawesome/pro-solid-svg-icons";
import { useLocalStorage, useToken } from "src/utils";
import { useQuery } from "@tanstack/react-query";
import { apiGetPartner, apiGetUserProfile } from "src/services";
import { Loading } from "src/components/global";
import { PermissionNavigation } from "src/utils/permissions";
import { PopupChangePass } from "src/partials/admin/PopupChangePass";

export default function AdminLayout() {
	const location = useLocation();
	const navigate = useNavigate();

	const [sidebarOpen, setSidebarOpen] = useState(false);
	const [isError, setIsError] = useState(false);

	const [showPopoverProfile, setShowPopoverProfile] = useState(false);
	const [modalChangePass, setModalChangePass] = useState(null);

	const partnerStorage = useLocalStorage("partner");
	const { token, removeToken } = useToken("token");

	const navigation = [
		{
			name: "Dashboard",
			href: "/admin",
			icon: faGauge,
			current: false,
			iconColor: "text-slate-500",
			state: null,
			permissions: [2, 3, 4, 5],
		},
		{
			name: "Website",
			href: "/admin/website",
			icon: faBrowser,
			current:
				location.pathname.includes("website") ||
				location.pathname.includes("news") ||
				location.pathname.includes("sponsor"),
			iconColor: "text-rose-500",
			state: null,
			permissions: [2, 3],
		},
		{
			name: "Event",
			href: "/admin/event",
			icon: faTicket,
			current: location.pathname.includes("event"),
			iconColor: "text-blue-500",
			state: null,
			permissions: [2, 4],
		},
		{
			name: "Portal",
			href: "/admin/checkin",
			icon: faPersonToPortal,
			current: location.pathname.includes("checkin"),
			iconColor: "text-yellow-500",
			state: null,
			permissions: [2, 4],
		},
		{
			name: "Scan",
			href: "/admin/scan",
			icon: faCameraViewfinder,
			current: location.pathname.includes("scan"),
			iconColor: "text-green-500",
			state: null,
			permissions: [2, 4, 5],
		},
		{
			name: "Pengaturan",
			href: "/admin/setting",
			icon: faCogs,
			current: location.pathname.includes("setting"),
			iconColor: "text-slate-500",
			state: null,
			permissions: [2, 4],
		},
		{
			name: "Finance",
			href: "/admin/finance",
			icon: faDollarCircle,
			current: location.pathname.includes("finance"),
			iconColor: "text-green-500",
			state: null,
			permissions: [2],
		},
		{
			name: "Insight",
			href: "/admin/insight",
			icon: faLightbulbOn,
			current: location.pathname.includes("insight"),
			iconColor: "text-green-500",
			state: null,
			permissions: [2],
		},
	];

	const handleClose = () => {
		setIsError(false);
	};

	const {
		data: userDetails,
		isError: isErrorPartnerProfile,
		isLoading: loadingUser,
	} = useQuery<any>([`partner`, `profile`], apiGetUserProfile, {
		enabled: !!token,
	});

	const {
		data: partnerDetails,
		isError: isErrorPartnerWebsite,
		isLoading: loadingPartner,
	} = useQuery<any>([`partner`, `website`], apiGetPartner, {
		enabled: !!token,
	});

	const _logout = () => {
		navigate("/admin/login");
	};

	useEffect(() => {
		if (partnerDetails?.data?.data) {
			partnerStorage.setItem(JSON.stringify(partnerDetails?.data?.data));
		}
	}, [partnerDetails, partnerStorage]);

	useEffect(() => {
		if (isErrorPartnerProfile || isErrorPartnerWebsite || !token) {
			_logout();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isErrorPartnerProfile, isErrorPartnerWebsite, token]);

	return loadingUser || loadingPartner ? (
		<Loading />
	) : (
		<>
			<Snackbar
				open={isError}
				autoHideDuration={4000}
				onClose={handleClose}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<Alert variant="filled" severity="error" onClose={handleClose} sx={{ width: "100%" }}></Alert>
			</Snackbar>

			<div className="flex h-full absolute w-full bg-slate-100">
				<Transition.Root show={sidebarOpen} as={Fragment}>
					<Dialog as="div" className="relative z-40 lg:hidden" onClose={setSidebarOpen}>
						<Transition.Child
							as={Fragment}
							enter="transition-opacity ease-linear duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="transition-opacity ease-linear duration-300"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
						</Transition.Child>

						<div className="fixed inset-0 z-40 flex">
							<Transition.Child
								as={Fragment}
								enter="transition ease-in-out duration-300 transform"
								enterFrom="-translate-x-full"
								enterTo="translate-x-0"
								leave="transition ease-in-out duration-300 transform"
								leaveFrom="translate-x-0"
								leaveTo="-translate-x-full"
							>
								<Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white focus:outline-none">
									<Transition.Child
										as={Fragment}
										enter="ease-in-out duration-300"
										enterFrom="opacity-0"
										enterTo="opacity-100"
										leave="ease-in-out duration-300"
										leaveFrom="opacity-100"
										leaveTo="opacity-0"
									>
										<div className="absolute top-0 right-0 -mr-12 pt-2">
											<button
												type="button"
												className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
												onClick={() => setSidebarOpen(false)}
											>
												<span className="sr-only">Close sidebar</span>
												<XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
											</button>
										</div>
									</Transition.Child>
									<div className="h-0 flex-1 overflow-y-auto pt-5 pb-4">
										<div className="flex flex-shrink-0 items-center px-4">
											<img className="h-8 w-auto" src="/tixie-logo.png" alt="Tixie" />
										</div>
										<nav aria-label="Sidebar" className="mt-5">
											<div className="space-y-1 px-2">
												{PermissionNavigation(navigation, userDetails?.data?.data?.roles).map(
													(item) => (
														<Link
															key={item.name}
															to={item.href}
															className={classNames(
																item.current
																	? "bg-slate-300 text-slate-900"
																	: "text-slate-800 hover:bg-slate-50 hover:text-slate-900",
																"group flex items-center rounded-md px-2 py-2 text-base font-medium"
															)}
															state={item.state ? { props: item.state } : null}
														>
															{item.icon && (
																<FontAwesomeIcon
																	className={`${item.iconColor} mr-2 text-lg w-8`}
																	aria-hidden="true"
																	icon={item.icon as IconProp}
																/>
															)}
															{item.name}
														</Link>
													)
												)}
												<Link
													to="/admin/login"
													className={classNames(
														"text-white hover:bg-red-600 bg-red-500",
														"group flex items-center justify-center rounded-md px-2 py-2 text-base font-medium"
													)}
												>
													LOGOUT
												</Link>
											</div>
										</nav>
									</div>
								</Dialog.Panel>
							</Transition.Child>
							<div className="w-14 flex-shrink-0" aria-hidden="true">
								{/* Force sidebar to shrink to fit close icon */}
							</div>
						</div>
					</Dialog>
				</Transition.Root>

				{/* Static sidebar for desktop */}
				<div className="hidden lg:flex lg:flex-shrink-0">
					<div className="flex w-72 flex-col">
						{/* Sidebar component, swap this element with another sidebar if you like */}
						<div className="flex min-h-0 flex-1 flex-col ">
							<div className="flex flex-1 flex-col overflow-y-auto pt-20 pb-4">
								<div className="flex flex-shrink-0 items-center pl-10">
									<img className="h-10 w-auto" src="/tixie-logo.png" alt="Tixie" />
								</div>
								<nav className="mt-20 flex-1 px-5" aria-label="Sidebar">
									<div className="space-y-1 px-2">
										{PermissionNavigation(navigation, userDetails?.data?.data?.roles).map(
											(item) => (
												<Link
													key={item.name}
													to={item.href}
													className={classNames(
														item.current
															? "bg-white text-slate-900"
															: "text-slate-600 hover:bg-white hover:text-slate-900",
														"group flex items-center rounded-md px-5 py-2 text-md font-medium"
													)}
													state={item.state ? { props: item.state } : null}
												>
													<FontAwesomeIcon
														className={`${item.iconColor} mr-2 text-lg w-8`}
														aria-hidden="true"
														icon={item.icon as IconProp}
													/>
													{item.name}
												</Link>
											)
										)}
									</div>
								</nav>
							</div>
						</div>
					</div>
				</div>
				<div className="flex min-w-0 flex-1 flex-col overflow-hidden">
					<div className="lg:hidden">
						<div className="flex items-center justify-between border-b border-gray-200 bg-gray-50 px-4 py-1.5">
							<div>
								<img className="h-8 w-auto" src="/tixie-logo.png" alt="Tixie" />
							</div>
							<div>
								<button
									type="button"
									className="-mr-3 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900"
									onClick={() => setSidebarOpen(true)}
								>
									<span className="sr-only">Open sidebar</span>
									<Bars3Icon className="h-6 w-6" aria-hidden="true" />
								</button>
							</div>
						</div>
					</div>
					<div className="relative z-0 flex flex-1 flex-col overflow-hidden">
						<main className="relative z-0 flex-1 overflow-y-auto focus:outline-none xl:order-last px-5">
							<div className="mx-auto max-w-7xl px-4 py-5 sm:px-6 sm:py-5 lg:px-8 mt-12 bg-white rounded-xl flex justify-between">
								<div className="flex gap-4 items-center w-full">
									<Popover className="relative">
										<Popover.Button
											onClick={() => setShowPopoverProfile((prev) => !prev)}
											className="md:border-r border-r-slate-300 flex items-center mr-7 pr-7 outline-none"
										>
											<div className="rounded-full w-10 h-10 overflow-hidden">
												<img
													src={userDetails?.data?.data?.picture_path}
													alt=""
													className="w-full"
												/>
											</div>
											<div className="ml-2">
												<div className="font-medium">
													{userDetails?.data?.data?.firstname}{" "}
													{userDetails?.data?.data?.lastname}
												</div>

												<div className="text-slate-600 text-left">
													{userDetails?.data?.data?.roles?.[0]?.title}
												</div>
											</div>
											<div>
												<FontAwesomeIcon
													icon={faChevronCircleDown as IconProp}
													className="ml-2 text-lg text-black"
													aria-hidden="true"
												/>
											</div>
										</Popover.Button>
										{showPopoverProfile && (
											<Popover.Panel
												onBlur={() => setShowPopoverProfile(false)}
												static
												className="absolute z-100 w-full bg-slate-300 py-2 px-4 rounded-md mt-1"
											>
												<div className="pb-2">
													<button
														onClick={(e) => {
															e.stopPropagation();
															navigate(`/admin/account/${userDetails?.data?.data?.id}`);
															setShowPopoverProfile(false);
														}}
														className="text-slate-600 w-full text-left border-l-slate-300 hover:text-slate-950"
													>
														Profile
													</button>
												</div>
												<div className="border-t pt-2">
													<button
														onClick={(e) => {
															e.stopPropagation();
															setModalChangePass(userDetails?.data?.data);
															setShowPopoverProfile(false);
														}}
														className="text-slate-600 w-full text-left border-l-slate-300 hover:text-slate-950"
													>
														Ganti Kata Sandi
													</button>
												</div>
											</Popover.Panel>
										)}
									</Popover>
									<div className="flex flex-1 justify-end">
										<div className="py-2 flex items-center mr-7">
											<FontAwesomeIcon
												icon={faBells as IconProp}
												className="text-xl mr-2 text-green-600"
												aria-hidden="true"
											/>
											<span className="text-sm text-green-600">Belum ada notifikasi</span>
										</div>
										<div className="py-2 items-center mr-7 hidden md:flex">
											<FontAwesomeIcon
												icon={faCalendarLines as IconProp}
												className="text-xl mr-2 text-slate-600"
												aria-hidden="true"
											/>
											<span className="text-sm text-slate-600 mr-5">
												{dayjs(new Date()).format(DATE_FORMAT_DD_MMM_YYYY)}
											</span>
											<FontAwesomeIcon
												icon={faClockEight as IconProp}
												className="text-xl mr-2 text-slate-600"
												aria-hidden="true"
											/>
											<span className="text-sm text-slate-600">
												{dayjs(new Date()).format(TIME_FORMAT_HH_MM)}
											</span>
										</div>
										<div className="items-center hidden md:flex">
											<Link
												to="/admin/login"
												className="text-slate-600 border-l border-l-slate-300 pl-5 ml-5 hover:text-slate-950"
											>
												Logout
											</Link>
										</div>
									</div>
								</div>
							</div>
							{partnerDetails?.data?.data ? (
								<div className="mx-auto max-w-7xl px-4 py-10 sm:px-6 sm:py-10 lg:px-8 m-12 bg-white rounded-xl">
									<Outlet context={partnerDetails?.data?.data} />
								</div>
							) : (
								""
							)}
						</main>
					</div>
				</div>
			</div>

			{modalChangePass ? <PopupChangePass data={modalChangePass} setData={setModalChangePass} /> : ""}
		</>
	);
}
