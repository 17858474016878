import { Route, Routes } from "react-router-dom";
import { useState } from "react";
import AdminLayout from "../layout/admin";
import WebLayout from "../layout/web";

import ManageEvent from "./admin/manage-event";
import EventPage from "./event";
import LandingPage from "./landing";
import TicketingPage from "./ticketing";
import Website from "./admin/website";
import ManageNews from "./admin/manage-news";
import ManageSponsor from "./admin/manage-sponsor";
import Setting from "./admin/setting";
import { Alert, Snackbar } from "@mui/material";
import ErrorPage from "./error";
import { ErrorContext } from "../functions/context";
import Dashboard from "./admin/dashboard";
import { EventForm } from "src/partials/admin/event";
import { OrderFailed, OrderSuccess } from "./Order";
import { CheckTicketPage } from "./CheckTicketPage";
import { AktivasiTicketPage } from "./AktivasiTicketPage";
import { UpdateEventTicket } from "./admin/update-event-ticket";
import CheckInPage from "./admin/check-in";
import BlankLayout from "src/layout/blank";
import { Login } from "./login";
import Finance from "./admin/finance";
import { AccountPage } from "./admin/account-page";
import { AccountForm } from "src/partials/admin/setting/account";
import ScanPage from "./admin/scan-page";
import { OrderSuccessFree } from "./Order/OrderSuccessFree";
import ManageContent from "./admin/manage-content";
import { InsightPage } from "./admin/insight-page";

function Routing() {
	let [globalError, setGlobalError] = useState("");

	const handleClose = () => {
		setGlobalError("");
	};
	return (
		<>
			{globalError && (
				<Snackbar
					open={globalError && true}
					autoHideDuration={2000}
					onClose={handleClose}
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
				>
					<Alert variant="filled" severity="error" onClose={handleClose} sx={{ width: "100%" }}>
						{globalError}
					</Alert>
				</Snackbar>
			)}
			<ErrorContext.Provider value={{ globalError, setGlobalError }}>
				<Routes>
					<Route element={<WebLayout />}>
						<Route path="/" element={<LandingPage />} />
						<Route path="event/:id" element={<EventPage />} />
						<Route path="landing" element={<LandingPage />} />
						<Route path="ticketing/:id/:dateId" element={<TicketingPage />} />
						<Route path="ticket-check/:phone_number/:ticket_number" element={<AktivasiTicketPage />} />
						<Route path="ticket-check" element={<CheckTicketPage />} />
						<Route path="order/failed" element={<OrderFailed />} />
						<Route path="order/success/:id" element={<OrderSuccess />} />
						<Route path="order/success" element={<OrderSuccessFree />} />
						<Route path="error" element={<ErrorPage />} />
					</Route>
					<Route element={<BlankLayout />}>
						<Route path="/admin/login" element={<Login />} />
					</Route>
					<Route element={<AdminLayout />}>
						<Route path="/admin" element={<Dashboard />} />
						<Route path="/admin/website" element={<Website />} />
						<Route path="/admin/news" element={<ManageNews />} />
						<Route path="/admin/sponsor" element={<ManageSponsor />} />
						<Route path="/admin/content" element={<ManageContent />} />
						<Route path="/admin/event" element={<ManageEvent />} />
						<Route path="/admin/event/create" element={<EventForm />} />
						<Route path="/admin/event/:id" element={<UpdateEventTicket />} />
						<Route path="/admin/checkin" element={<CheckInPage />} />
						<Route path="/admin/scan" element={<ScanPage />} />
						<Route path="/admin/finance" element={<Finance />} />
						<Route path="/admin/setting" element={<Setting />} />
						<Route path="/admin/account" element={<AccountForm />} />
						<Route path="/admin/account/:id" element={<AccountPage />} />
						<Route path="/admin/insight" element={<InsightPage />} />
					</Route>
				</Routes>
			</ErrorContext.Provider>
		</>
	);
}

export default Routing;
