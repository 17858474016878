import { useQuery } from "@tanstack/react-query";
import { ApexOptions } from "apexcharts";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { MultiSelect } from "react-multi-select-component";
import { Loading } from "src/components/web";
import { GetInsightPayment } from "src/services/InsightService";

const options: ApexOptions = {
	chart: {
		type: "bar",
		height: 350,
		stacked: true,
		toolbar: {
			show: false,
		},
	},
	plotOptions: {
		bar: {
			horizontal: true,
			dataLabels: {
				total: {
					enabled: true,
					offsetX: 0,
					style: {
						fontSize: "13px",
						fontWeight: 900,
					},
				},
			},
		},
	},
	stroke: {
		width: 1,
		colors: ["#fff"],
	},
	xaxis: {
		categories: ["e-wallet", "Direct Debit", "Kartu kredit", "Virtual Account"],
	},
	yaxis: {
		title: {
			text: undefined,
		},
	},
	legend: {
		position: "bottom",
		horizontalAlign: "center",
	},
};

export const InsightPayment = ({ listEvent }) => {
	const [selected, setSelected] = useState([]);
	const [series, setSeries] = useState<any>([]);

	const { data: insightPayment, isLoading: loadingInsightPayment } = useQuery<any>(
		[`insight`, `payment`, selected],
		async () => await GetInsightPayment({ event_id: selected?.map((item) => item?.value)?.toString() || undefined })
	);

	const _getInsightPaymentTotalPayIndex = (paymentMethod, index) => {
		return insightPayment?.find((v) => v.payment_method === paymentMethod)?.total_pay?.[index] || 0;
	};

	const seriesList = [
		"< Rp. 500,000",
		"Rp. 500,000 - Rp. 1,500,000",
		"Rp. 1,500,000 - Rp. 2,500,000",
		"> Rp. 2,500,000",
	];

	useEffect(() => {
		const _series = [];
		if (insightPayment) {
			seriesList.forEach((item, index) => {
				_series.push({
					name: item,
					data: [
						_getInsightPaymentTotalPayIndex("e-wallet", index),
						_getInsightPaymentTotalPayIndex("direct_debit", index),
						_getInsightPaymentTotalPayIndex("CREDIT_CARD", index),
						_getInsightPaymentTotalPayIndex("virtual_account", index),
					],
				});
			});
		} else {
			seriesList.forEach((item, index) => {
				_series.push({
					name: item,
					data: [0, 0, 0, 0],
				});
			});
		}
		setSeries(_series);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [insightPayment]);

	return (
		<div className="mt-10">
			<div className="flex items-center justify-between">
				<h1 className="text-slate-500 text-xl font-medium">Event di terpilih untuk di tampilkan</h1>
				<MultiSelect
					options={listEvent?.map((item) => ({
						label: item?.event_title,
						value: item?.id,
					}))}
					value={selected}
					onChange={setSelected}
					labelledBy="Select"
					className="w-[200px] max-w-[200px]"
				/>
			</div>

			{loadingInsightPayment ? (
				<Loading />
			) : (
				<div className="mt-10">
					<ReactApexChart options={options} series={series} type="bar" height={350} />
				</div>
			)}
		</div>
	);
};
