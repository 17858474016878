import { ReactNode } from "react";

interface CheckTicketContainerProps {
  title: "Cek Tiket" | "Aktivasi Tiket";
  children: ReactNode;
}

export const CheckTicketContainer = ({
  title,
  children,
}: CheckTicketContainerProps) => {
  return (
    <div className="max-w-screen-xl mx-auto py-16">
      <h2 className="text-4xl font-medium mb-16 text-center">{title}</h2>
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="rounded-md bg-slate-100 p-5">{children}</div>
      </div>
    </div>
  );
};
