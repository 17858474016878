import ApiService from "./ApiService";

export async function apiPostBannerEvent({
  formData,
  id,
}: {
  formData: FormData;
  id: string;
}) {
  return ApiService.fetchData({
    url: `partner/event/images/${id}`,
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  });
}

export async function apiPostGallery({ formData }: { formData: FormData }) {
  return ApiService.fetchData({
    url: `partner/event/gallery`,
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  });
}

export async function apiDeleteGalleryImage(id) {
  return ApiService.fetchData({
    url: `partner/event/gallery/${id}`,
    method: "delete",
  });
}
