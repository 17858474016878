interface BannerProps {
  image?: string;
}

export const Banner = ({ image }: BannerProps) => {
  return (
    <div className="relative h-128">
      <img
        className="absolute w-full h-full top-0 left-0 object-cover"
        src={image || "https://placehold.co/1000@3x.png"}
        alt=""
      />
    </div>
  );
};
