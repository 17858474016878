import { useQuery } from "@tanstack/react-query";
import { ApexOptions } from "apexcharts";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { MultiSelect } from "react-multi-select-component";
import { Loading } from "src/components/web";
import { GetInsightTicket } from "src/services/InsightService";

const SummaryBox = ({ title, value, desc = "" }) => {
	return (
		<div className="py-3 px-5 bg-green-50 rounded-lg">
			<p className="text-green-700 text-md font-medium">{title}</p>
			<p className="mt-12 mb-4 text-6xl text-green-900 font-medium">{value}</p>
		</div>
	);
};

export const InsightActivity = ({ listEvent }) => {
	const [selected, setSelected] = useState([]);
	const [series, setSeries] = useState<any>([0]);

	const { data: insightTicket, isLoading: loadingInsightTicket } = useQuery<any>(
		[`insight`, `ticket`, selected],
		async () => await GetInsightTicket({ event_id: selected?.map((item) => item?.value)?.toString() || undefined })
	);

	const options: ApexOptions = {
		chart: {
			type: "radialBar",
			offsetY: -20,
			sparkline: {
				enabled: true,
			},
		},
		plotOptions: {
			radialBar: {
				startAngle: -90,
				endAngle: 90,
				track: {
					background: "#e7e7e7",
					strokeWidth: "97%",
					margin: 5, // margin is in pixels
					dropShadow: {
						enabled: true,
						top: 2,
						left: 0,
						color: "#999",
						opacity: 1,
						blur: 2,
					},
				},
				dataLabels: {
					name: {
						show: false,
					},
					value: {
						color: "black",
						fontSize: "32px",
						formatter: (data) => "",
					},
				},
			},
		},
		fill: {
			type: "gradient",
			gradient: {
				shade: "light",
				shadeIntensity: 0.4,
				inverseColors: false,
				opacityFrom: 1,
				opacityTo: 1,
				stops: [0, 50, 53, 91],
			},
		},
	};

	useEffect(() => {
		if (insightTicket) {
			setSeries([
				insightTicket?.total_checkin ? (insightTicket?.total_checkin / insightTicket?.total_ticket) * 100 : 0,
			]);
		}
	}, [insightTicket]);
	return (
		<div className="mt-10">
			<div className="flex items-center justify-between">
				<h1 className="text-slate-500 text-xl font-medium">Event di terpilih untuk di tampilkan</h1>
				<MultiSelect
					options={listEvent?.map((item) => ({
						label: item?.event_title,
						value: item?.id,
					}))}
					value={selected}
					onChange={setSelected}
					labelledBy="Select"
					className="w-[200px] max-w-[200px]"
				/>
			</div>

			{loadingInsightTicket || !insightTicket ? (
				<Loading className="mt-10" />
			) : (
				<div className="mt-10">
					<div className="flex flex-col-reverse xl:flex-row items-center gap-2">
						<div className="w-[500px] text-center relative">
							<ReactApexChart options={options} series={series} type="radialBar" />
							<p className="text-md text-slate-500">
								Jumlah user yang hadir dari <br /> jumlah tiket terjual
							</p>

							<div className="absolute right-[46px] top-[172px] text-black text-md">
								{insightTicket?.total_ticket}
							</div>

							<div className="absolute top-[172px] right-1/2 text-xl">{insightTicket?.total_checkin}</div>
						</div>
						<div className="grid grid-cols-1 md:grid-cols-3 w-full md:w-auto gap-10">
							<SummaryBox title={"Tiket aktif"} value={insightTicket?.total_active} />
							<SummaryBox title={"User check-in"} value={insightTicket?.total_checkin} />
							<SummaryBox title={"User tidak hadir"} value={insightTicket?.tota_sisa} />
						</div>
					</div>
				</div>
			)}
		</div>
	);
};
