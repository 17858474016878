import { News } from "src/interface";
import { NewsCard } from "./NewsCard";
import { Loading } from "./Loading";
import { useState } from "react";
import { NewsDetail } from "src/partials/news";

interface NewsProps {
	news: News[];
	loading: boolean;
}

export const NewsListing = ({ news, loading }: NewsProps) => {
	const [dataNews, setDataNews] = useState(null);
	return (
		<>
			{loading ? (
				<Loading title="Loading news terakhir..." />
			) : !news.length ? (
				""
			) : (
				<div className="max-w-screen-xl mx-auto">
					<div className="mb-8">
						<h2 className="pl-3 border-l-8 border-blue-500 text-3xl font-medium">What's the latest</h2>
					</div>
					<div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-8">
						{news?.map((item: News, index: number) => (
							<NewsCard
								news={item}
								key={`news-card-${item.id}-${index}`}
								_onReadMoreClick={setDataNews}
							/>
						))}
					</div>
				</div>
			)}

			{dataNews ? <NewsDetail show={!!dataNews} _onClose={() => setDataNews(null)} data={dataNews} /> : ""}
		</>
	);
};
