import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faEye, faEyeSlash } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import { COUNTRY_PHONE_NUMBER } from "src/constants";

export const SelectComponent = <T,>({
	title,
	errors,
	optiondata = [],
	keyvalue,
	keyname,
	register,
	...props
}: {
	title: string;
	register?: UseFormRegisterReturn<string>;
	errors?: string;
	id: string;
	name: string;
	optiondata: T[];
	keyvalue: keyof T;
	keyname: keyof T;
	disabled?: boolean;
}) => {
	return (
		<div className="mb-4">
			<label htmlFor={props.id} className="block text-sm font-medium text-gray-700">
				{title}
			</label>
			<select
				{...register}
				{...props}
				className={`block w-full rounded-md shadow-sm outline-none sm:text-sm ${
					errors ? `border-red-500` : "border-gray-300"
				} disabled:bg-gray-200`}
			>
				<option value={null} disabled>
					Pilih
				</option>
				{optiondata.map((v: any, i) => (
					<option key={`${props.id}-${i}`} value={v?.[keyvalue]}>
						{v?.[keyname]}
					</option>
				))}
			</select>
			{errors ? <p className="text-red-500 text-xs italic mt-2">{errors}</p> : ""}
		</div>
	);
};

export const TextAreaComponent = ({
	title,
	errors,
	register,
	...props
}: {
	title: string;
	register?: UseFormRegisterReturn<string>;
	errors?: string;
	id: string;
	name: string;
}) => {
	return (
		<div className="mb-4">
			<label htmlFor={props.id} className="block text-sm font-medium text-gray-700">
				{title}
			</label>
			<div className="mt-1">
				<textarea
					rows={4}
					{...register}
					{...props}
					className={`block w-full rounded-md resize-none shadow-sm outline-none sm:text-sm ${
						errors ? `border-red-500` : "border-gray-300"
					}`}
				></textarea>
				{errors ? <p className="text-red-500 text-xs italic mt-2">{errors}</p> : ""}
			</div>
		</div>
	);
};

export const TextComponent = ({
	title,
	errors,
	register,
	...props
}: {
	title?: string;
	register?: UseFormRegisterReturn<string>;
	errors?: string;
	id: string;
	name: string;
	type: React.HTMLInputTypeAttribute;
	maxLength?: number | undefined;
	placeholder?: string;
	onChange?: any;
}) => {
	const [type, setType] = useState("password");
	return (
		<div className="mb-4">
			{title ? (
				<label htmlFor={props.id} className="block text-sm font-medium text-gray-700">
					{title}
				</label>
			) : (
				""
			)}
			<div className="mt-1">
				<div className="relative">
					<input
						{...register}
						{...props}
						type={props?.type === "password" ? type : props?.type}
						className={`block w-full rounded-md shadow-sm outline-none sm:text-sm ${
							errors ? `border-red-500` : "border-gray-300"
						}`}
					/>
					{props?.type === "password" ? (
						<button
							type="button"
							onClick={() => {
								setType(type === "password" ? "text" : "password");
							}}
							className="absolute top-1/2 -translate-y-1/2 right-2"
						>
							<FontAwesomeIcon
								icon={(type === "password" ? faEye : faEyeSlash) as IconProp}
								className="mr-2"
								aria-hidden="true"
							/>
						</button>
					) : (
						""
					)}
				</div>
				{errors ? <p className="text-red-500 text-xs italic mt-2">{errors}</p> : ""}
			</div>
		</div>
	);
};

export const MobileNumberComponent = ({
	title,
	errors,
	setValue,
	getValues,
	...props
}: {
	title: string;
	errors?: string;
	id: string;
	name: string;
	setValue: any;
	getValues: any;
	maxLength?: number | undefined;
}) => {
	const [numberCode, setNumberCode] = useState("62");
	const [phoneNumber, setPhoneNumber] = useState("");

	const [initComp, setInitComp] = useState(false);

	useEffect(() => {
		let result = "";
		if (phoneNumber) {
			result = `0${phoneNumber}`;
		}

		if (initComp)
			setValue(props?.name, result, {
				shouldValidate: true,
				shouldTouch: true,
				shouldDirty: true,
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [numberCode, phoneNumber]);

	useEffect(() => {
		const defaultNumber = getValues(props?.name);
		setInitComp(true);

		if (defaultNumber) {
			setPhoneNumber(defaultNumber.slice(1));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="mb-4">
			<label htmlFor={props.id} className="block text-sm font-medium text-gray-700">
				{title}
			</label>
			<div className="mt-1">
				<div className="relative flex">
					<select
						defaultValue={"62"}
						onChange={(e) => setNumberCode(e.target.value)}
						className={`block rounded-l-md shadow-sm outline-none sm:text-sm ${
							errors ? `border-red-500` : "border-gray-300"
						}`}
					>
						{COUNTRY_PHONE_NUMBER.map((value, index) => (
							<option value={value.code} key={`code-number-${index}-${value?.name}`}>
								{value.flag} {value.label}
							</option>
						))}
					</select>
					<input
						type={"text"}
						inputMode="numeric"
						{...props}
						value={phoneNumber}
						onChange={(e) => {
							if (isNaN(+e.target.value)) return;
							setPhoneNumber(e.target.value);
						}}
						className={`block w-full border-l-0 rounded-r-md shadow-sm outline-none sm:text-sm focus:ring-0 focus:ring-transparent ${
							errors ? `focus:border-red-500 border-red-500` : "focus:border-gray-300 border-gray-300"
						}`}
					/>
				</div>
				{errors ? <p className="text-red-500 text-xs italic mt-2">{errors}</p> : ""}
			</div>
		</div>
	);
};
