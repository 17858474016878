import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { Loading } from "src/components/global";
import { User } from "src/interface";
import { AccountForm } from "src/partials/admin/setting/account";
import { apiGetPartnerAccount } from "src/services";

export const AccountPage = () => {
  const { id } = useParams();

  const {
    data: accountData,
    isLoading,
    refetch,
  } = useQuery<User>(
    [`partner`, `account`, `user`, id],
    async () => await apiGetPartnerAccount({ id })
  );

  return isLoading ? (
    <Loading />
  ) : (
    <AccountForm data={accountData} refetch={refetch} />
  );
};
