export const useLocalStorage = (keyname) => {
  const data = localStorage.getItem(keyname);

  const setItem = (data: any) => {
    localStorage.setItem(keyname, data);
  };

  const removeItem = () => {
    localStorage.removeItem(keyname);
  };
  return { setItem, data, removeItem };
};
