import { z } from "zod";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/pro-duotone-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useMutation } from "@tanstack/react-query";
import { Event, EventRequest, User } from "src/interface";
import { apiPostPartnerEvent, apiUpdatePartnerEvent } from "src/services";
import { Loading } from "src/components/web";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import { toast } from "react-toastify";
import { QuillConfig, _quillRemoveFormats } from "src/configs/quill.config";
import "react-quill/dist/quill.snow.css";

const EventSchema = z.object({
	type: z.literal("event"),
	status_id: z.number().min(0).max(1),
	date_time: z.array(
		z.object({
			date: z.string().regex(/^\d{4}-\d{2}-\d{2}$/),
			time: z.string().regex(/^\d{2}:\d{2}:\d{2}$/),
			time_to: z.string().regex(/^\d{2}:\d{2}:\d{2}$/),
		})
	),
	event_title: z.string().nonempty("Nama event wajib diisi"),
	event_owner_id: z.string().nonempty("Event owner wajib dipilih"),
	event_description: z.string(),
	event_tnc: z.string(),
	agreement_list: z.array(
		z.object({
			title: z.string(),
			description: z.string(),
		})
	),
	address: z.string().nonempty("Alamat wajib diisi"),
	event_type: z.string(),
	online_url: z.string(),
	venue: z.string(),
	price_range: z.string(),
});

type EventSchemaForm = z.infer<typeof EventSchema>;

interface EventAboutFormProps {
	data?: Event;
	refetch?: <TPageData>(
		options?: RefetchOptions & RefetchQueryFilters<TPageData>
	) => Promise<QueryObserverResult<Event, unknown>>;
	users: User[];
}

export const EventAboutForm = ({ data, refetch, users }: EventAboutFormProps) => {
	const defaultValue: EventSchemaForm = {
		type: "event",
		status_id: data?.status_id ?? 0,
		date_time: data?.date_time?.length
			? (data?.date_time as any)
			: [{ date: "", time: "Mulai", time_to: "Selesai" }],
		event_title: data?.event_title ?? "",
		event_description: data?.event_description ?? "",
		event_tnc: data?.event_tnc ?? "",
		event_owner_id: data?.event_owner_id || users.find((value) => value?.roles?.[0]?.id === 2)?.id || "",
		agreement_list: data?.agreement_list?.length ? data?.agreement_list : [{ title: "", description: "" }],
		address: data?.address ?? "",
		event_type: data?.event_type ?? "Offline",
		online_url: data?.online_url ?? "",
		venue: data?.venue ?? "Indoor",
		price_range: data?.price_range ?? "",
	};

	const navigate = useNavigate();

	const {
		register,
		control,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm<EventSchemaForm>({
		resolver: zodResolver(EventSchema),
		defaultValues: defaultValue,
	});

	const event_description = useWatch({
		control,
		name: "event_description",
	});

	const event_tnc = useWatch({
		control,
		name: "event_tnc",
	});

	const event_type = useWatch({
		control,
		name: "event_type",
	});

	const {
		fields: date_time,
		append,
		remove,
	} = useFieldArray({
		control,
		name: "date_time",
	});

	const {
		fields: agreement_list,
		append: appendAgreement,
		remove: removeAgreement,
	} = useFieldArray({
		control,
		name: "agreement_list",
	});

	const { mutate: postEvent, isLoading: loadingPostEvent } = useMutation(
		async (data: EventRequest) => await apiPostPartnerEvent({ data })
	);

	const { mutate: putEvent, isLoading: loadingPutEvent } = useMutation(
		async ({ data, id }: { data: EventRequest; id: string }) => await apiUpdatePartnerEvent({ data, id })
	);

	return (
		<>
			<div className="mt-10">
				<h2 className="text-2xl font-medium border-b pb-3">Tentang event</h2>
			</div>
			<form
				onSubmit={handleSubmit((value: EventSchemaForm) => {
					data
						? putEvent(
								{ data: value, id: data?.id },
								{
									onSuccess: (res: any) => {
										toast.success("Event berhasil diubah");
										refetch();
									},
								}
						  )
						: postEvent(value, {
								onSuccess: (res: any) => {
									toast.success("Event berhasil dibuat");
									navigate(`/admin/event/${res?.data?.id}`);
								},
						  });
				})}
			>
				<div className="space-y-8 border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:pb-0">
					<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
						<label
							htmlFor="date_time"
							className="block text-sm font-medium leading-8 text-gray-900 sm:pt-1.5"
						>
							Tanggal
						</label>
						<div className="w-full col-span-2 flex flex-col gap-2">
							{date_time?.map((dateTime, index) => {
								return (
									<div
										key={`date-time-${index}-${dateTime?.id}`}
										className={`sm:col-span-2 sm:mt-0 flex relative`}
									>
										<input
											type="date"
											{...register(`date_time.${index}.date`)}
											className="block w-full rounded-md border-0 py-1.5 pl-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-8"
										/>
										<select
											id="time"
											name="time"
											className="ml-3 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-8"
											{...register(`date_time.${index}.time`)}
										>
											<option value="Mulai">Mulai</option>
											{Array.from({ length: 24 }).map((_, index) => (
												<option
													key={`time-${index}`}
													value={`${
														index >= 10 ? (index === 24 ? `00` : index) : `0${index}`
													}:00:00`}
												>{`${
													index >= 10 ? (index === 24 ? `00` : index) : `0${index}`
												}:00:00`}</option>
											))}
										</select>
										<select
											id="time_to"
											name="time_to"
											className="ml-3 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-8"
											{...register(`date_time.${index}.time_to`)}
										>
											<option value="Selesai">Selesai</option>
											{Array.from({ length: 24 }).map((_, index) => (
												<option
													key={`time-to-${index}`}
													value={`${
														index >= 10 ? (index === 24 ? `00` : index) : `0${index}`
													}:00:00`}
												>{`${
													index >= 10 ? (index === 24 ? `00` : index) : `0${index}`
												}:00:00`}</option>
											))}
										</select>
										{index === 0 ? (
											<button
												type="button"
												disabled={!(date_time?.length < 5)}
												onClick={() => {
													append({
														date: "",
														time: "Mulai",
														time_to: "Selesai",
													});
												}}
												className="px-5 border-2 w-4 flex items-center justify-center rounded-lg ml-2 "
											>
												<FontAwesomeIcon icon={faPlus as IconProp} />
											</button>
										) : (
											<button
												type="button"
												onClick={() => {
													remove(index);
												}}
												className="px-5 border-2 w-4 flex items-center justify-center rounded-lg ml-2 "
											>
												<FontAwesomeIcon icon={faMinus as IconProp} />
											</button>
										)}
									</div>
								);
							})}
						</div>
					</div>

					<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
						<label
							htmlFor="event_title"
							className="block text-sm font-medium leading-8 text-gray-900 sm:pt-1.5"
						>
							Nama event
						</label>
						<div className="mt-2 sm:col-span-2 sm:mt-0">
							<input
								id="event_title"
								name="event_title"
								type="text"
								autoComplete="event_title"
								{...register("event_title")}
								className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-8"
							/>
							{errors?.event_title?.message ? (
								<p className="text-red-500 text-xs italic mt-2">{errors?.event_title?.message}</p>
							) : (
								""
							)}
						</div>
					</div>

					<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
						<label
							htmlFor="event_type"
							className="block text-sm font-medium leading-8 text-gray-900 sm:pt-1.5"
						>
							Event Owner
						</label>
						<div className="mt-2 sm:col-span-2 sm:mt-0">
							<select
								id="event_owner_id"
								name="event_owner_id"
								autoComplete="event_owner_id"
								{...register("event_owner_id")}
								className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-8"
							>
								{users
									?.filter((v) => [2, 3].includes(v?.roles?.[0]?.id))
									.map((value, idx) => (
										<option value={value.id} key={`users-owner-${value?.id}-${idx}`}>
											{value?.firstname} {value?.lastname}
										</option>
									))}
							</select>
							{errors?.event_owner_id?.message ? (
								<p className="text-red-500 text-xs italic mt-2">{errors?.event_owner_id?.message}</p>
							) : (
								""
							)}
						</div>
					</div>

					<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
						<label
							htmlFor="description"
							className="block text-sm font-medium leading-8 text-gray-900 sm:pt-1.5"
						>
							Deskripsi
						</label>
						<div className="mt-2 sm:col-span-2 sm:mt-0">
							{event_description ? (
								<div
									className="mb-3 bg-slate-100 p-4 ql-editor"
									dangerouslySetInnerHTML={{
										__html: event_description,
									}}
								></div>
							) : (
								""
							)}
							<ReactQuill
								{...QuillConfig}
								defaultValue={event_description}
								onChange={(e) => {
									setValue("event_description", _quillRemoveFormats(e));
								}}
							/>
							{errors?.event_description?.message ? (
								<p className="text-red-500 text-xs italic mt-2">{errors?.event_description?.message}</p>
							) : (
								""
							)}
						</div>
					</div>
					<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
						<label
							htmlFor="event_tnc"
							className="block text-sm font-medium leading-8 text-gray-900 sm:pt-1.5"
						>
							Syarat dan ketentuan
						</label>
						<div className="mt-2 sm:col-span-2 sm:mt-0">
							{event_tnc ? (
								<div
									className="mb-3 bg-slate-100 p-4 ql-editor"
									dangerouslySetInnerHTML={{
										__html: event_tnc,
									}}
								></div>
							) : (
								""
							)}
							<ReactQuill
								{...QuillConfig}
								defaultValue={event_tnc}
								theme="snow"
								onChange={(e) => {
									setValue("event_tnc", _quillRemoveFormats(e));
								}}
							/>
							{errors?.event_tnc?.message ? (
								<p className="text-red-500 text-xs italic mt-2">{errors?.event_tnc?.message}</p>
							) : (
								""
							)}
						</div>
					</div>
					<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
						<label
							htmlFor="event_type"
							className="block text-sm font-medium leading-8 text-gray-900 sm:pt-1.5"
						>
							Tipe event
						</label>
						<div className="mt-2 sm:col-span-2 sm:mt-0">
							<select
								id="event_type"
								name="event_type"
								autoComplete="event_type"
								{...register("event_type")}
								className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-8"
							>
								<option value="Online">Online</option>
								<option value="Offline">Offline</option>
							</select>
						</div>
					</div>
					{event_type === "Online" ? (
						<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
							<label
								htmlFor="online_url"
								className="block text-sm font-medium leading-8 text-gray-900 sm:pt-1.5"
							>
								Event URL
							</label>
							<div className="mt-2 sm:col-span-2 sm:mt-0">
								<input
									id="online_url"
									name="online_url"
									type="text"
									autoComplete="online_url"
									{...register("online_url")}
									className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-8"
								/>
								{errors?.online_url?.message ? (
									<p className="text-red-500 text-xs italic mt-2">{errors?.online_url?.message}</p>
								) : (
									""
								)}
							</div>
						</div>
					) : (
						""
					)}
					<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
						<label htmlFor="venue" className="block text-sm font-medium leading-8 text-gray-900 sm:pt-1.5">
							Tipe venue
						</label>
						<div className="mt-2 sm:col-span-2 sm:mt-0">
							<select
								id="venue"
								name="venue"
								autoComplete="venue"
								{...register("venue")}
								className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-8"
							>
								<option value="Outdoor">Outdoor</option>
								<option value="Indoor">Indoor</option>
							</select>
						</div>
					</div>
					<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
						<label
							htmlFor="address"
							className="block text-sm font-medium leading-8 text-gray-900 sm:pt-1.5"
						>
							Tempat venue
						</label>
						<div className="mt-2 sm:col-span-2 sm:mt-0">
							<input
								id="address"
								name="address"
								type="text"
								autoComplete="address"
								{...register("address")}
								className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-8"
							/>
							{errors?.address?.message ? (
								<p className="text-red-500 text-xs italic mt-2">{errors?.address?.message}</p>
							) : (
								<p className="text-xs italic text-gray-400">Contoh: Gelora Bung Karno</p>
							)}
						</div>
					</div>
					<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
						<label
							htmlFor="event_name"
							className="block text-sm font-medium leading-8 text-gray-900 sm:pt-1.5"
						>
							Harga kisaran tiket
						</label>
						<div className="mt-2 sm:col-span-2 sm:mt-0">
							<input
								id="event_name"
								name="event_name"
								type="text"
								autoComplete="event_name"
								{...register("price_range")}
								className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-8"
							/>
							{errors?.price_range?.message ? (
								<p className="text-red-500 text-xs italic mt-2">{errors?.price_range?.message}</p>
							) : (
								<p className="text-xs italic text-gray-400">Contoh: Rp. 213,109 - Rp. 699,590</p>
							)}
						</div>
					</div>
					<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
						<label
							htmlFor="agreement_list"
							className="block text-sm font-medium leading-8 text-gray-900 sm:pt-1.5"
						>
							Persetujuan
						</label>
						<div className="w-full col-span-2 flex flex-col gap-2">
							{agreement_list?.map((agreement, index) => {
								return (
									<div
										key={`agreement-${index}-${agreement?.id}`}
										className={`sm:col-span-2 sm:mt-0 flex gap-2`}
									>
										<div>
											{index === 0 && (
												<label
													htmlFor={`agreement_list.${index}.title`}
													className="block text-sm font-medium leading-8 text-gray-900 sm:pt-1.5"
												>
													Nama
												</label>
											)}
											<div className="mt-2 sm:col-span-2 sm:mt-0">
												<input
													id={`agreement_list.${index}.title`}
													name={`agreement_list.${index}.title`}
													type="text"
													autoComplete={`agreement_list.${index}.title`}
													{...register(`agreement_list.${index}.title`)}
													className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-8"
												/>
												{errors?.agreement_list?.[index]?.title?.message ? (
													<p className="text-red-500 text-xs italic mt-2">
														{errors?.agreement_list?.[index]?.title?.message}
													</p>
												) : (
													""
												)}
											</div>
										</div>
										<div className="w-full">
											{index === 0 && (
												<label
													htmlFor={`agreement_list.${index}.description`}
													className="block text-sm font-medium leading-8 text-gray-900 sm:pt-1.5"
												>
													Deskripsi
												</label>
											)}
											<div className="mt-2 sm:col-span-2 sm:mt-0">
												<textarea
													rows={4}
													id={`agreement_list.${index}.description`}
													name={`agreement_list.${index}.description`}
													{...register(`agreement_list.${index}.description`)}
													className={`block w-full rounded-md resize-none shadow-sm outline-none sm:text-sm ${
														errors?.agreement_list?.[index]?.description?.message
															? `border-red-500`
															: "border-gray-300"
													}`}
												></textarea>

												{errors?.agreement_list?.[index]?.description?.message ? (
													<p className="text-red-500 text-xs italic mt-2">
														{errors?.agreement_list?.[index]?.description?.message}
													</p>
												) : (
													""
												)}
											</div>
										</div>

										{index === 0 ? (
											<div>
												<div className="block text-sm font-medium leading-8 text-gray-900 sm:pt-1.5">
													&nbsp;
												</div>
												<div className="mt-2 sm:col-span-2 sm:mt-0">
													<button
														type="button"
														disabled={!(agreement_list?.length < 5)}
														onClick={() => {
															appendAgreement({
																title: "",
																description: "",
															});
														}}
														className="block px-4 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-8"
													>
														<FontAwesomeIcon icon={faPlus as IconProp} />
													</button>
												</div>
											</div>
										) : (
											<div>
												<div className="mt-2 sm:col-span-2 sm:mt-0">
													<button
														type="button"
														onClick={() => {
															removeAgreement(index);
														}}
														className="block px-4 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-8"
													>
														<FontAwesomeIcon icon={faMinus as IconProp} />
													</button>
												</div>
											</div>
										)}
									</div>
								);
							})}
						</div>
					</div>
					<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
						<label htmlFor="venue" className="block text-sm font-medium leading-8 text-gray-900 sm:pt-1.5">
							Status
						</label>
						<div className="mt-2 sm:col-span-2 sm:mt-0">
							<select
								id="status_id"
								name="status_id"
								autoComplete="status_id"
								{...register("status_id", { valueAsNumber: true })}
								className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-8"
							>
								<option value="1">Aktif</option>
								<option value="0">Tidak Aktif</option>
							</select>
						</div>
					</div>
				</div>
				<div className="bg-slate-100 p-5 items-center flex justify-center">
					<button
						type="submit"
						disabled={loadingPostEvent || loadingPutEvent}
						className="rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 disabled:cursor-not-allowed disabled:bg-blue-200"
					>
						{loadingPostEvent || loadingPutEvent ? (
							<Loading title="Sedang diproses" />
						) : !data ? (
							"Buat Event"
						) : (
							"Perbaharui event"
						)}
					</button>
				</div>
			</form>
		</>
	);
};
