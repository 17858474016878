import { Disclosure, Tab } from "@headlessui/react";
import { Fragment } from "react";
import { Loading } from "src/components/web";
import { useQuery } from "@tanstack/react-query";
import { apiGetAccounting } from "src/services/AccountingService";
import { DiscountVoucher } from "src/partials/finance/DiscountVoucher";

function Finance() {
	const subNavigation = [
		{
			name: "Accounting",
		},
		{
			name: "Diskon & Kupon",
		},
		// {
		// 	name: "Payments & Payout",
		// },
	];

	const { data, isLoading } = useQuery<any>([`partner`, `accounting`], apiGetAccounting);

	return (
		<>
			<div>
				<div className="sm:flex sm:items-center">
					<div className="sm:flex-auto">
						<h1 className="text-3xl text-gray-900 font-medium">Finance</h1>
						<p className="mt-2 text-sm text-gray-700">Mengelola dana dan keuangan</p>
					</div>
				</div>
				<div className="mt-8 flow-root">
					<Tab.Group>
						<Tab.List className="border-b border-slate-300 flex">
							{subNavigation.map((item) => (
								<Tab as={Fragment} key={item.name}>
									{({ selected }) => (
										<button
											className={`${
												selected
													? "border-b-2 border-blue-600 font-medium text-slate-900"
													: "border-b-2 border-white "
											} mr-10 py-2 text-slate-500`}
										>
											{item.name}
										</button>
									)}
								</Tab>
							))}
						</Tab.List>
						<Tab.Panels>
							<Tab.Panel>
								{isLoading ? (
									<Loading className="mt-5" />
								) : (
									<div className="mt-10 space-y-8 border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:pb-0">
										<div>
											<div className="px-4 sm:px-0">
												<h3 className="text-lg font-medium leading-7 text-gray-900">
													Tier & Rate
												</h3>
												<p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
													Detail tier dan rate untuk payout/payments
												</p>
											</div>
											<div className="mt-6 border-t border-gray-100">
												<dl className="divide-y divide-gray-100">
													<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
														<dt className="text-sm font-medium leading-6 text-gray-900">
															Total jumlah penjualan tiket
														</dt>
														<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
															{data?.data?.data?.total_ticket}
														</dd>
													</div>
													<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
														<dt className="text-sm font-medium leading-6 text-gray-900">
															Payment processing fee
														</dt>
														<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
															{data?.data?.data?.payment_fee}
														</dd>
													</div>
													<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
														<dt className="text-sm font-medium leading-6 text-gray-900">
															Platform fee
														</dt>
														<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
															{data?.data?.data?.platform_fee}
														</dd>
													</div>
												</dl>
											</div>
										</div>
									</div>
								)}
							</Tab.Panel>
							<Tab.Panel>
								<DiscountVoucher />
							</Tab.Panel>
							<Tab.Panel>
								{isLoading ? (
									<Loading className="mt-5" />
								) : (
									<div className="mt-10 space-y-8 border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:pb-0">
										<div className="grid grid-cols-2 gap-10">
											<div>
												<p className="text-lg mb-4 font-medium">Detail rekening</p>
												<div className="mb-3">
													<label
														htmlFor="nama"
														className="block text-sm font-medium leading-6 text-gray-600"
													>
														Nama account
													</label>
													<div className="mt-2">
														<input
															type="nama"
															name="nama"
															id="nama"
															className="block w-full rounded-md border-0 py-1.5 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-indigo-600 sm:text-sm sm:leading-6"
														/>
													</div>
												</div>
												<div className="mb-3">
													<label
														htmlFor="account-number"
														className="block text-sm font-medium leading-6 text-gray-600"
													>
														Nomor account
													</label>
													<div className="mt-2">
														<input
															type="account-number"
															name="account-number"
															id="account-number"
															className="block w-full rounded-md border-0 py-1.5 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-indigo-600 sm:text-sm sm:leading-6"
														/>
													</div>
												</div>
												<div className="mb-5">
													<label
														htmlFor="bank"
														className="block text-sm font-medium leading-6 text-gray-600"
													>
														Bank
													</label>
													<div className="mt-2">
														<input
															type="bank"
															name="bank"
															id="bank"
															className="block w-full rounded-md border-0 py-1.5 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-indigo-600 sm:text-sm sm:leading-6"
														/>
													</div>
												</div>
												<div>
													<button
														type="button"
														className="w-full rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
														onClick={(e) => {}}
													>
														Perbaharui data
													</button>
												</div>
											</div>
											<div className="px-5">
												<p className="text-lg mb-4 font-medium">Tahap pembayaran</p>
												<div className="bg-green-100 p-4 mb-4">
													<Disclosure>
														<Disclosure.Button className="py-2 text-lg font-medium w-full text-left flex justify-between">
															<span>Stage 1 payout - 20% </span>
															<span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
																Application
															</span>
														</Disclosure.Button>
														<Disclosure.Panel>
															<div
																className="mt-6 bg-white rounded-md p-5"
																aria-hidden="true"
															>
																<p className="mb-5">Status progress</p>
																<div className="overflow-hidden rounded-full bg-gray-200">
																	<div
																		className="h-2 rounded-full bg-indigo-600"
																		style={{ width: "0%" }}
																	/>
																</div>
																<div className="mt-6 hidden grid-cols-4 text-sm font-medium text-gray-600 sm:grid">
																	<div className="">Application</div>
																	<div className="text-center">Approval</div>
																	<div className="text-center">Paid out</div>
																	<div className="text-right">Complete</div>
																</div>
															</div>
															<button
																type="button"
																className="mt-3 w-full rounded-md bg-green-600 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
																onClick={(e) => {}}
															>
																Request payment
															</button>
														</Disclosure.Panel>
													</Disclosure>
												</div>
												<div className="bg-slate-50 p-4 mb-4">
													<Disclosure>
														<Disclosure.Button
															disabled
															className="py-2 text-lg font-medium w-full text-left"
														>
															Stage 2 payout - 50%
														</Disclosure.Button>
														<Disclosure.Panel>
															<div
																className="mt-6 bg-white rounded-md p-5"
																aria-hidden="true"
															>
																<p className="mb-5">Status progress</p>
																<div className="overflow-hidden rounded-full bg-gray-200">
																	<div
																		className="h-2 rounded-full bg-indigo-600"
																		style={{ width: "0%" }}
																	/>
																</div>
																<div className="mt-6 hidden grid-cols-4 text-sm font-medium text-gray-600 sm:grid">
																	<div className="">Application</div>
																	<div className="text-center">Approval</div>
																	<div className="text-center">Paid out</div>
																	<div className="text-right">Complete</div>
																</div>
															</div>
														</Disclosure.Panel>
													</Disclosure>
												</div>
												<div className="bg-slate-50 p-4">
													<Disclosure>
														<Disclosure.Button
															disabled
															className="py-2 text-lg font-medium w-full text-left"
														>
															Stage 3 payout - 30%
														</Disclosure.Button>
														<Disclosure.Panel>
															<div
																className="mt-6 bg-white rounded-md p-5"
																aria-hidden="true"
															>
																<p className="mb-5">Status progress</p>
																<div className="overflow-hidden rounded-full bg-gray-200">
																	<div
																		className="h-2 rounded-full bg-indigo-600"
																		style={{ width: "0%" }}
																	/>
																</div>
																<div className="mt-6 hidden grid-cols-4 text-sm font-medium text-gray-600 sm:grid">
																	<div className="">Application</div>
																	<div className="text-center">Approval</div>
																	<div className="text-center">Paid out</div>
																	<div className="text-right">Complete</div>
																</div>
															</div>
														</Disclosure.Panel>
													</Disclosure>
												</div>
											</div>
										</div>
									</div>
								)}
							</Tab.Panel>
						</Tab.Panels>
					</Tab.Group>
				</div>
			</div>
		</>
	);
}

export default Finance;
