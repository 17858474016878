import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faUser, faXmark } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { currencyFormatter } from "src/functions/global-functions";
import { _getDate, _getTime } from "src/utils";

export const PopupTransactionDetail = ({ data, setData }: { data: any; setData: any }) => {
	const DescLeft = ({ label, value }: any) => (
		<div>
			<div className="text-black text-sm font-medium p-0">{label}</div>
			<div className="text-slate-400 text-sm">{value}</div>
		</div>
	);

	return (
		<Transition appear show={!!data} as={Fragment}>
			<Dialog
				as="div"
				className="relative z-10"
				onClose={() => {
					setData(null);
				}}
			>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-black bg-opacity-25" />
				</Transition.Child>

				<div className="fixed inset-0 overflow-y-auto">
					<div className="flex min-h-full items-center justify-center p-4 text-center">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
								<div className="flex items-center justify-between w-full">
									<h3 className="text-black text-lg font-medium">Detail Transaksi</h3>

									<button type="button" onClick={() => setData(null)}>
										<FontAwesomeIcon
											icon={faXmark as IconProp}
											className="mr-2 text-lg text-black"
											aria-hidden="true"
										/>
									</button>
								</div>
								<div className="mt-2 flex">
									<div className="flex-1 p-3 pl-0 flex-col flex gap-2">
										<DescLeft label={"Nomor transaksi"} value={data?.order_number || "-"} />
										<DescLeft
											label={"Jumlah total pembelian"}
											value={currencyFormatter().format(data?.grand_total) || "-"}
										/>
										<DescLeft
											label={"Metode Pembayaran"}
											value={data?.payment?.payment_method || "-"}
										/>
										<DescLeft label={"Status"} value={data?.status?.status || "-"} />
										<DescLeft
											label={"Tanggal dan jam pembelian"}
											value={
												<span>
													{_getDate(data?.created_at) || "-"} -{" "}
													{_getTime(data?.created_at) || "-"}
												</span>
											}
										/>
									</div>
									<div className="flex-1 p-3 bg-slate-200 rounded-lg flex-col flex gap-4">
										<div className="text-black font-medium">
											<FontAwesomeIcon icon={faUser as IconProp} className="mr-2 text-lg" /> Data
											pembeli / buyer
										</div>
										<DescLeft label={"Nama lengkap"} value={data?.buyer?.full_name || "-"} />
										<DescLeft label={"Nomor handphone"} value={data?.buyer?.mobile_number || "-"} />
										<DescLeft label={"Email"} value={data?.buyer?.email || "-"} />
										<DescLeft label={"No KTP"} value={data?.buyer?.number_identity_card || "-"} />
									</div>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	);
};
