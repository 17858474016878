import {
	faCalendarCirclePlus,
	faEnvelopeOpenDollar,
	faMapLocation,
	faMoneyFromBracket,
} from "@fortawesome/pro-duotone-svg-icons";
import { Tab } from "@headlessui/react";
import { useQuery } from "@tanstack/react-query";
import { apiGetPartnerEvent, apiGetUserProfile } from "src/services";
import { Loading as LoadingGlobal } from "src/components/global";
import { PermissionNavigation } from "src/utils/permissions";
import { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { InsightTransaction } from "src/partials/insight/InsightTransaction";
import { InsightPayment } from "src/partials/insight/InsightPayment";
import { InsightActivity } from "src/partials/insight/InsightActivitis";
import { InsightLocation } from "src/partials/insight/InsightLocation";

const subNavigation = [
	{
		name: "Transaksi",
		icon: faMoneyFromBracket,
		color: "text-cyan-400",
		permissions: [2],
	},
	{
		name: "Pembayaran",
		icon: faEnvelopeOpenDollar,
		color: "text-emerald-500",
		permissions: [2],
	},
	{
		name: "Aktivasi",
		icon: faCalendarCirclePlus,
		color: "text-indigo-500",
		permissions: [2],
	},
	{
		name: "Lokasi",
		icon: faMapLocation,
		color: "text-pink-500",
		permissions: [2],
	},
];

export const InsightPage = () => {
	const { data: userDetails, isLoading: loadingUser } = useQuery<any>([`partner`, `profile`], apiGetUserProfile);
	const { isLoading: loadingEvent, data: listEvent } = useQuery(["partner", "event"], apiGetPartnerEvent);
	return loadingUser || loadingEvent ? (
		<LoadingGlobal />
	) : (
		<>
			<div className="sm:flex sm:items-center">
				<div className="sm:flex-auto">
					<h1 className="text-3xl text-gray-900 font-medium">Insight</h1>
					<p className="mt-2 text-sm text-gray-700">Business case forecast and ideas</p>
				</div>
			</div>
			<div className="mt-8 flow-root">
				<Tab.Group>
					<Tab.List className="border-b border-slate-300 flex">
						{PermissionNavigation(subNavigation, userDetails?.data?.data?.roles).map((item) => (
							<Tab as={Fragment} key={item.name}>
								{({ selected }) => (
									<button
										className={`${
											selected
												? "border-b-2 border-blue-600 font-medium text-slate-900"
												: "border-b-2 border-white"
										} mr-10 py-2 text-slate-500 outline-none`}
									>
										<FontAwesomeIcon
											className={`${item.color} mr-2 text-lg w-8`}
											aria-hidden="true"
											icon={item.icon as IconProp}
										/>
										{item.name}
									</button>
								)}
							</Tab>
						))}
					</Tab.List>
					<Tab.Panels>
						<Tab.Panel>
							<InsightTransaction listEvent={listEvent?.data} />
						</Tab.Panel>
						<Tab.Panel>
							<InsightPayment listEvent={listEvent?.data} />
						</Tab.Panel>
						<Tab.Panel>
							<InsightActivity listEvent={listEvent?.data} />
						</Tab.Panel>
						<Tab.Panel>
							<InsightLocation listEvent={listEvent?.data} />
						</Tab.Panel>
					</Tab.Panels>
				</Tab.Group>
			</div>
		</>
	);
};
