import dayjs from "dayjs";
import { DATE_FORMAT_DD_MM_YYYY } from "src/constants";

export const _getTime = (d, isEmpty = "-") => {
	if (!d) return isEmpty;
	const [, time] = d.split("T");
	if (!time) return isEmpty;
	return time.split(".000000Z");
};

export const _getDate = (d, isEmpty = "-", format = DATE_FORMAT_DD_MM_YYYY) => {
	if (!d) return isEmpty;
	const [date] = d.split("T");
	if (!date) return isEmpty;
	return dayjs(date).format(format);
};
