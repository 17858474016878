import ApiService from "./ApiService";

export async function GetInsight() {
	return ApiService.fetchData({
		url: `partner/insight/summary`,
		method: "get",
	}).then((res: any) => res.data.data as any);
}

export async function GetInsightPerEvent(params) {
	return ApiService.fetchData({
		url: `partner/insight/summary/event`,
		method: "get",
		params,
	}).then((res: any) => res.data.data as any);
}

export async function GetInsightPayment(params) {
	return ApiService.fetchData({
		url: `partner/insight/payment/event`,
		method: "get",
		params,
	}).then((res: any) => res.data.data as any);
}

export async function GetInsightTicket(params) {
	return ApiService.fetchData({
		url: `partner/insight/ticket/event`,
		method: "get",
		params,
	}).then((res: any) => res.data.data as any);
}

export async function GetInsightProvince(params) {
	return ApiService.fetchData({
		url: `partner/insight/provinsi/event`,
		method: "get",
		params,
	}).then((res: any) => res.data.data as any);
}
