import ApiService from "./ApiService";

export async function apiUpdateNew({ id, data }) {
	return ApiService.fetchData({
		url: `partner/news/${id}`,
		method: "post",
		data,
	});
}

export async function apiPostNew({ data }) {
	return ApiService.fetchData({
		url: `partner/news`,
		method: "post",
		data,
	});
}
