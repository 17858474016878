import { classNames } from "../../functions/global-functions";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-duotone-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const navigate = [
	{
		name: "News",
		initials: "N",
		href: "/admin/news",
		description: "Manage news disini untuk add, delete dan update.",
		bgColor: "bg-pink-600",
	},
	{
		name: "Sponsors",
		initials: "S",
		href: "/admin/sponsor",
		description: "Manage sponsor disini untuk add, delete dan update.",
		bgColor: "bg-purple-600",
	},
	{
		name: "Contents",
		initials: "C",
		href: "/admin/content",
		description: "Manage content disini untuk update.",
		bgColor: "bg-green-600",
	},
];

function Website() {
	return (
		<div>
			<h2 className="text-3xl font-medium mb-10">Manage website</h2>
			<div>
				<ul className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-2">
					{navigate.map((project) => (
						<li key={project.name} className="col-span-1 flex rounded-md shadow-sm">
							<div
								className={classNames(
									project.bgColor,
									"flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white"
								)}
							>
								{project.initials}
							</div>
							<div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
								<div className="flex-1 truncate px-4 py-2 text-sm">
									<a href={project.href} className="font-medium text-gray-900 hover:text-gray-600">
										{project.name}
									</a>
									<p className="text-gray-500">{project.description}</p>
								</div>
								<div className="flex-shrink-0 pr-2">
									<Link
										to={project.href}
										className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
									>
										<FontAwesomeIcon icon={faArrowRight as IconProp} className="text-md " />
									</Link>
								</div>
							</div>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
}

export default Website;
