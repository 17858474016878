import { MobileNumberComponent, TextComponent } from "./DetailInputComponent";
import { UseFormRegister } from "react-hook-form";

interface UserFormProps {
	register: UseFormRegister<any>;
	errors: any;
	ticket_index: number;
	type_index: number;
	user_index: number;
	setValue: any;
	getValues: any;
}

export const UserForm = ({ register, errors, ticket_index, type_index, user_index, setValue, getValues }: UserFormProps) => {
	const prefixForm = `ticket.${ticket_index}.types.${type_index}.users.${user_index}`;

	return (
		<div className="bg-white p-4 rounded-lg">
			<TextComponent
				title={"Nama Lengkap"}
				id={`${prefixForm}.full_name`}
				register={register(`${prefixForm}.full_name`)}
				name={`${prefixForm}.full_name`}
				type={"text"}
				errors={(errors?.ticket?.[ticket_index]?.types?.[type_index].users?.[user_index]?.full_name?.message as string) || undefined}
			/>
			<MobileNumberComponent
				title={"Nomor Handphone"}
				id={`${prefixForm}.mobile_number`}
				name={`${prefixForm}.mobile_number`}
				maxLength={14}
				errors={(errors?.ticket?.[ticket_index]?.types?.[type_index].users?.[user_index]?.mobile_number?.message as string) || undefined}
				setValue={setValue}
				getValues={getValues}
			/>
			<TextComponent
				title={"Email"}
				id={`${prefixForm}.email`}
				register={register(`${prefixForm}.email`)}
				name={`${prefixForm}.email`}
				type={"text"}
				errors={(errors?.ticket?.[ticket_index]?.types?.[type_index].users?.[user_index]?.email?.message as string) || undefined}
			/>
		</div>
	);
};
