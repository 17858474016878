import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faUser, faXmark } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { toast } from "react-toastify";
import { Loading } from "src/components/web";

export const PopupCheckin = ({ data, setData, putCheckIn, loading, setTicketNumber }: any) => {
	const DescLeft = ({ label, value }: any) => (
		<div>
			<div className="text-black text-sm font-medium p-0">{label}</div>
			<div className="text-slate-400 text-sm">{value}</div>
		</div>
	);

	return (
		<Transition appear show={!!data} as={Fragment}>
			<Dialog
				as="div"
				className="relative z-10"
				onClose={() => {
					setData(null);
				}}
			>
				<Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
					<div className="fixed inset-0 bg-black bg-opacity-25" />
				</Transition.Child>

				<div className="fixed inset-0 overflow-y-auto">
					<div className="flex min-h-full items-center justify-center p-4 text-center">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
								<div className="flex items-center justify-between w-full">
									<h3 className="text-black text-lg font-medium">Detail Tiket {data?.unique_id}</h3>

									<button type="button" onClick={() => setData(null)}>
										<FontAwesomeIcon icon={faXmark as IconProp} className="mr-2 text-lg text-black" aria-hidden="true" />
									</button>
								</div>
								<div className="mt-4 flex">
									<div className="flex-1 p-3 pl-0 flex-col flex gap-2">
										<DescLeft label={"Nama Event"} value={data?.event?.event_title || "-"} />
										<DescLeft label={"Penyelenggara"} value={data?.partner?.partner_name || "-"} />
										<DescLeft label={"Zone"} value={`${data?.zone?.title}` || "-"} />
										<DescLeft label={"Tipe"} value={`${data?.type?.title}` || "-"} />
										<DescLeft label={"Status"} value={data?.ticket_status?.status_label || "-"} />
									</div>
									<div className="flex-1 p-3 bg-slate-200 rounded-lg flex-col flex gap-4">
										<div className="text-black font-medium">
											<FontAwesomeIcon icon={faUser as IconProp} className="mr-2 text-lg" /> Data pembeli / buyer
										</div>
										<DescLeft label={"Nama lengkap"} value={data?.buyer?.full_name || "-"} />
										<DescLeft label={"Nomor handphone"} value={data?.buyer?.mobile_number || "-"} />
										<DescLeft label={"Email"} value={data?.buyer?.email || "-"} />
										<DescLeft label={"No KTP"} value={data?.buyer?.number_identity_card || "-"} />
									</div>
								</div>
								<div className="flex pt-6 items-center justify-center w-full">
									<button
										type="button"
										disabled={loading}
										onClick={() => {
											putCheckIn(
												{ id: data?.id, status: "CheckedIn" },
												{
													onSuccess: (res) => {
														setTicketNumber("");
														setData(null);
														toast.success(`Checkin tiket ${data?.unique_id} berhasil`);
													},
													onError: (res: any) => {
														toast.error(res?.response?.data?.error);
														setTicketNumber("");
													},
												}
											);
										}}
										className="rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 disabled:cursor-not-allowed disabled:bg-blue-200"
									>
										{loading ? <Loading /> : "Checkin"}
									</button>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	);
};
