import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Loading } from "src/components/web";
import { CheckOrderRequest } from "src/interface";
import { apiPostCheckOrder } from "src/services/OrderService";
import { useToken } from "src/utils";
import { z } from "zod";

const CheckTicketSchema = z.object({
  choose_identity: z.string(),
  unique_id: z.string().nonempty("Nomor Tiket Wajib Diisi"),
  no_identity: z.string().nonempty("Nomor Handphone Wajib Diisi"),
});

type CheckTicket = z.infer<typeof CheckTicketSchema>;

export const CheckTicketForm = () => {
  const defaultValue: CheckTicket = {
    choose_identity: "phone",
    unique_id: "",
    no_identity: "",
  };

  const [showAler, setShowAlert] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CheckTicket>({
    resolver: zodResolver(CheckTicketSchema),
    defaultValues: defaultValue,
  });

  const { token } = useToken();

  const { mutate, isLoading, isError } = useMutation(
    async (order: CheckOrderRequest) =>
      await apiPostCheckOrder({ order, token })
  );

  const navigate = useNavigate();

  return (
    <form
      onSubmit={handleSubmit((value: CheckTicket) =>
        mutate(value as CheckOrderRequest, {
          onSuccess: (res) => {
            navigate(`/ticket-check/${value?.no_identity}/${value?.unique_id}`);
          },
          onError: () => setShowAlert(true),
        })
      )}
    >
      {isError && showAler ? (
        <div className="text-center relative bg-red-200 w-full rounded-lg border-red-500 text-red-500 text-sm py-2 mb-2 border">
          Tiket tidak ditemukan
          <button
            type="button"
            onClick={() => setShowAlert(false)}
            className="absolute right-4 top-1/2 -translate-y-1/2 border rounded-full border-red-500 font-bold bg-red-200 text-red-500 w-5 h-5 pb-[0.5px] cursor-pointer text-xs flex items-center justify-center"
          >
            x
          </button>
        </div>
      ) : (
        ""
      )}
      <div className="mb-5">
        <label
          htmlFor="no_identity"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Nomor ponsel
        </label>
        <div className="mt-2">
          <input
            type="text"
            {...register("no_identity")}
            name="no_identity"
            id="no_identity"
            autoComplete="no_identity"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
          {errors?.no_identity?.message ? (
            <p className="text-red-500 text-xs italic mt-2">
              {errors?.no_identity?.message}
            </p>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="mb-5">
        <label
          htmlFor="unique_id"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Nomor tiket
        </label>
        <div className="mt-2">
          <input
            {...register("unique_id")}
            type="text"
            name="unique_id"
            id="unique_id"
            autoComplete="unique_id"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
          {errors?.unique_id?.message ? (
            <p className="text-red-500 text-xs italic mt-2">
              {errors?.unique_id?.message}
            </p>
          ) : (
            ""
          )}
        </div>
      </div>

      <button
        type="submit"
        disabled={isLoading}
        className="w-full justify-center inline-flex items-center rounded-md border border-transparent bg-blue-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-blue-200"
      >
        {isLoading ? <Loading title="Sedang diproses" /> : "Cek Tiket"}
      </button>
    </form>
  );
};
