import axios from "axios";
import { AppConfig } from "src/configs";
import { REQUEST_HEADER_AUTH_KEY, TOKEN_TYPE } from "src/constants";

const BaseService = axios.create({
	timeout: 60000,
	baseURL: AppConfig.apiPrefix,
});

BaseService.interceptors.request.use(
	(config) => {
		const accessToken = localStorage.getItem("token");

		if (accessToken) {
			config.headers[REQUEST_HEADER_AUTH_KEY] = `${TOKEN_TYPE} ${accessToken}`;
		}

		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

BaseService.interceptors.response.use(
	(response) => response,
	async (error) => {
		return Promise.reject(error);
	}
);

export default BaseService;
