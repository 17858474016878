import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faExclamationCircle } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode } from "react";
import { Loading } from "../web";

interface BaseTableColumn {
	header: string;
	cell: (value: any) => ReactNode | string;
	className?: string;
}

interface BaseTableProps {
	column: BaseTableColumn[];
	datas: any[];
	loading?: boolean;
}

export const BaseTable = ({ column, datas, loading }: BaseTableProps) => {
	return (
		<table className="min-w-full table-fixed divide-y divide-gray-300 rounded-md overflow-hidden">
			<thead>
				<tr className="border-b-2 bg-slate-200">
					{column.map((value) => (
						<th
							scope="col"
							key={`header-${value?.header}`}
							className="py-3.5 first:pl-4 px-3 text-left text-sm font-medium text-gray-900 sm:pl-3 relative"
						>
							{value.header}
						</th>
					))}
				</tr>
			</thead>
			<tbody className="divide-y divide-slate-200 bg-slate-50">
				{!loading ? (
					datas?.length ? (
						datas?.map((data) => (
							<tr key={data?.id}>
								{column.map((value) => (
									<td className="whitespace-nowrap px-3 py-4 text-sm">{value.cell(data)}</td>
								))}
							</tr>
						))
					) : (
						<tr>
							<td colSpan={column.length}>
								<p className="text-sm text-center pb-3 w-full flex items-center justify-center mt-3">
									<FontAwesomeIcon
										icon={faExclamationCircle as IconProp}
										className="mr-2 text-lg text-red-600"
										aria-hidden="true"
									/>
									Belum ada data terdaftar
								</p>
							</td>
						</tr>
					)
				) : (
					<tr>
						<td colSpan={column.length}>
							<div className="text-center w-full py-2">
								<Loading title="Sedang mengambil data" />
							</div>
						</td>
					</tr>
				)}
			</tbody>
		</table>
	);
};
