import { useMutation } from "@tanstack/react-query";
import { QrScanner } from "@yudiel/react-qr-scanner";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Loading } from "src/components/global";
import { PopupCheckin } from "src/partials/admin/checkin/PopupCheckin";
import { apiPostCheckIn, apiPutCheckIn } from "src/services";

const ScanPage = () => {
	const [ticketNumber, setTicketNumber] = useState("");

	const [checkinDetail, setCheckinDetail] = useState(null);

	const [initQrScanner, setIniQrScanner] = useState<boolean>(true);

	const { mutate: putCheckIn, isLoading: loadingChangeStatus } = useMutation(async (data: { id: string; status: "CheckedOut" | "CheckedIn" }) => await apiPutCheckIn(data));

	const { mutate: postCheckIn, isLoading: loadingCheckin } = useMutation(async (data: any) => await apiPostCheckIn(data));

	const _onCheckIn = (data: { unique_id: string }) => {
		postCheckIn(data, {
			onSuccess: (res: any) => {
				setCheckinDetail(res);
				setTicketNumber("");
			},
			onError: (res: any) => {
				toast.error(res?.response?.data?.error);
				setTicketNumber("");
			},
		});
	};

	useEffect(() => {
		setTimeout(() => {
			setIniQrScanner(false);
		}, 3000);
	}, []);

	return (
		<>
			{(initQrScanner || loadingChangeStatus || loadingCheckin) && <Loading />}
			<div className="mt-8">
				<div className="max-w-[300px]">
					<QrScanner
						viewFinderBorder={20}
						scanDelay={5000}
						onDecode={(result: string) => {
							_onCheckIn({ unique_id: result });
						}}
						onError={(error) => {
							if (!error?.message.includes("CanvasRenderingContext2D")) toast.error(error?.message);
						}}
					/>
				</div>
				<div className="flex items-center gap-2 mt-2 max-w-[300px] flex-wrap">
					<input
						type="text"
						placeholder="Nomor Tiket"
						value={ticketNumber}
						className="rounded-md flex-1 border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
						onInput={(e: any) => setTicketNumber(e.target.value)}
					/>
					<button
						type="button"
						onClick={() => {
							_onCheckIn({ unique_id: ticketNumber });
						}}
						disabled={!!!ticketNumber}
						className="justify-center inline-flex items-center rounded-md border border-transparent bg-blue-600 px-6 py-1.5 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:bg-blue-200"
					>
						Checkin
					</button>
				</div>
			</div>

			{checkinDetail ? <PopupCheckin setTicketNumber={setTicketNumber} data={checkinDetail} putCheckIn={putCheckIn} loading={loadingChangeStatus} setData={setCheckinDetail} /> : ""}
		</>
	);
};

export default ScanPage;
