import { EventTicketForm, Seating } from "src/components/web";
import { Event, Order } from "src/interface";

interface TicketingPurchaseFormProps {
	data: Event;
	setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
	setFormState: React.Dispatch<React.SetStateAction<Order>>;
	formState: Order;
}

export const TicketingPurchaseForm = ({
	data,
	setCurrentStep,
	setFormState,
	formState,
}: TicketingPurchaseFormProps) => {
	return (
		<>
			<h2 className="mb-8 font-medium text-3xl">Detail Tiket</h2>
			{/* <Seating map={data.seat_images_url} /> */}
			<div className="mb-12">
				<EventTicketForm
					title="Jenis dan jumlah tiket"
					setCurrentStep={setCurrentStep}
					setFormState={setFormState}
					data={data}
					formState={formState}
					showPrice
					showTicket
				/>
			</div>
		</>
	);
};
