import BaseService from "./BaseService";

type ResolveType<T> = T extends Promise<infer R> ? R : never;

const ApiService = {
  fetchData<T>(param): Promise<ResolveType<T>> {
    return new Promise((resolve, reject) => {
      BaseService(param)
        .then((response) => {
          resolve(response as ResolveType<T>);
        })
        .catch((errors) => {
          reject(errors);
        });
    });
  },
};

export default ApiService;
