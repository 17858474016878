import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { Event, TicketRequest, Zone } from "src/interface";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { apiPostTicket, apiPutTicket } from "src/services";
import { toast } from "react-toastify";
import { Loading } from "src/components/web";
import { currencyFormatter } from "src/functions/global-functions";

interface EventTicketPopupProps {
	isOpenFunc?: Function;
	isOpen?: boolean;
	eventId?: string;
	updateTicket?: Function;
	zone?: Zone[];
	ticketType?: any;
	refetch?: any;
	defaultData?: any;
	data: Event;
}

const EventTicketSchema = z.object({
	ticket_category_id: z
		.string()
		.nonempty("Kategory tiket wajib diisi")
		.refine((value) => value !== "Pilih kategori", {
			message: "Kategory tiket wajib diisi",
		}),
	ticket_type_id: z
		.string()
		.nonempty("Tipe tiket wajib diisi")
		.refine((value) => value !== "Pilih tipe", {
			message: "Tipe tiket wajib diisi",
		}),
	qty: z.number({ invalid_type_error: "Qty wajib diisi" }).min(1, "Qty wajib diisi"),
	price: z.number({ invalid_type_error: "Harga wajib diisi" }),
	maximum_buying: z.number({ invalid_type_error: "Maximum pembelian wajib diisi" }),
	status_id: z.number(),
	isFree: z.boolean(),
});

type EventTicket = z.infer<typeof EventTicketSchema>;

export const EventTicketPopup = ({
	zone,
	isOpen,
	isOpenFunc,
	ticketType,
	eventId,
	refetch,
	defaultData,
	data,
}: EventTicketPopupProps) => {
	const defaultValue: EventTicket = {
		ticket_category_id: defaultData?.ticket_category_id?.toString() || "Pilih kategori",
		ticket_type_id: defaultData?.ticket_type_id?.toString() || "Pilih tipe",
		qty: defaultData?.qty || 0,
		price: defaultData?.price || 0,
		status_id: 0,
		maximum_buying: 0,
		isFree: defaultData ? !!!defaultData?.price || false : false,
	};

	const {
		register,
		handleSubmit,
		control,
		setValue,
		formState: { errors },
	} = useForm<EventTicket>({
		resolver: zodResolver(EventTicketSchema),
		defaultValues: defaultValue,
	});

	const price = useWatch({
		control,
		name: "price",
	});

	const isFree = useWatch({
		control,
		name: "isFree",
	});

	const ticket_category_id = useWatch({
		control,
		name: "ticket_category_id",
	});

	const { mutate: postTicket, isLoading: loadingPostTicket } = useMutation(
		async ({ id, data }: { id: string; data: TicketRequest }) => await apiPostTicket({ id, data })
	);

	const { mutate: putTicket, isLoading: loadingPutTicket } = useMutation(
		async ({ id, data }: { id: string; data: TicketRequest }) => await apiPutTicket({ id, data })
	);

	useEffect(() => {
		if (isFree) {
			setValue("price", 0);
		}
	}, [isFree]);

	return (
		<Transition appear show={isOpen} as={Fragment}>
			<Dialog onClose={() => isOpenFunc(false)} className="relative z-50">
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-black bg-opacity-25" />
				</Transition.Child>
				<div className="fixed inset-0 overflow-y-auto">
					<div className="flex min-h-full items-center justify-center p-4 text-center">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
								<Dialog.Title className="mb-4 text-2xl font-medium">
									{defaultData?.id ? "Ubah" : "Buat"} tiket
								</Dialog.Title>
								<form
									onSubmit={handleSubmit((value) => {
										if (defaultData?.id) {
											putTicket(
												{ id: defaultData?.id, data: value },
												{
													onSuccess: () => {
														toast.success("Tiket berhasil diubah");
														refetch?.();
														isOpenFunc(false);
													},
													onError: (err: any) => {
														toast.error(err?.response?.data?.message ?? err?.message);
													},
												}
											);
										} else {
											postTicket(
												{ id: eventId, data: value },
												{
													onSuccess: () => {
														toast.success("Tiket berhasil dibuat");
														refetch?.();
														isOpenFunc(false);
													},
													onError: (err: any) => {
														toast.error(err?.response?.data?.message ?? err?.message);
													},
												}
											);
										}
									})}
								>
									<label
										htmlFor="ticket_category_id"
										className="block text-sm font-medium leading-6 text-gray-700"
									>
										Pilih kategori
									</label>
									<div className="mb-5 mt-2">
										<select
											{...register("ticket_category_id")}
											id="ticket_category_id"
											name="ticket_category_id"
											disabled={defaultData?.id}
											className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-700 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
										>
											<option disabled value={"Pilih kategori"}>
												Pilih kategori tiket
											</option>

											{zone?.map((zoneData) => {
												return <option value={zoneData.id}>{zoneData.title}</option>;
											})}
										</select>
										{errors.ticket_category_id?.message ? (
											<div className="text-xs text-red-500 ">
												{errors.ticket_category_id?.message}
											</div>
										) : (
											""
										)}
									</div>

									<label
										htmlFor="ticket_type_id"
										className="block text-sm font-medium leading-6 text-gray-700"
									>
										Pilih tipe
									</label>
									<div className="mb-5 mt-2">
										<select
											{...register("ticket_type_id")}
											id="ticket_type_id"
											name="ticket_type_id"
											disabled={defaultData?.id}
											className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-700 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
											defaultValue={"Pilih tipe"}
										>
											<option disabled value={"Pilih tipe"}>
												Pilih tipe
											</option>
											{ticketType?.map((type) => {
												const ticketValid =
													(ticket_category_id !== "Pilih kategori" ? false : true) ||
													!!data?.tickets
														?.find((value) => value.zone.id === +ticket_category_id)
														?.event_tickets?.find(
															(value) => value.ticket_type_id === type.id
														);

												return (
													<option value={type.id} key={type.id} disabled={ticketValid}>
														{type.title}{" "}
														{ticketValid && !defaultData?.id ? " (Tidak Tersedia)" : ""}
													</option>
												);
											})}
										</select>
										{errors.ticket_type_id?.message ? (
											<div className="text-xs text-red-500 ">
												{errors.ticket_type_id?.message}
											</div>
										) : !defaultData?.id ? (
											<div className="text-xs text-red-500">*Pilih kategori terlebih dahulu</div>
										) : (
											""
										)}
									</div>

									<label htmlFor="qty" className="block text-sm font-medium leading-6 text-gray-700">
										Jumlah tiket tersedia
									</label>
									<div className="mb-5 mt-2">
										<input
											{...register("qty", { valueAsNumber: true })}
											type="number"
											name="qty"
											id="qty"
											className="block w-32 rounded-md border-0 py-1.5 text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
											placeholder="Qty"
										/>

										{errors.qty?.message ? (
											<div className="text-xs text-red-500 ">{errors.qty?.message}</div>
										) : (
											""
										)}
									</div>

									<label htmlFor="qty" className="block text-sm font-medium leading-6 text-gray-700">
										Pembelian Maximum
									</label>
									<div className="mb-5 mt-2">
										<input
											{...register("maximum_buying", { valueAsNumber: true })}
											type="number"
											name="maximum_buying"
											id="maximum_buying"
											className="block w-32 rounded-md border-0 py-1.5 text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
											placeholder="maximum_buying"
										/>

										{errors.maximum_buying?.message ? (
											<div className="text-xs text-red-500 ">
												{errors.maximum_buying?.message}
											</div>
										) : (
											""
										)}
									</div>

									<label
										htmlFor="price"
										className="block text-sm font-medium leading-6 text-gray-700"
									>
										Harga tiket
									</label>
									<div className="mb-5 mt-2">
										<div className="flex items-center gap-2">
											<div className="flex-1">
												<input
													{...register("price", { valueAsNumber: true })}
													type="number"
													name="price"
													id="price"
													disabled={isFree}
													className="block w-full disabled:bg-gray-100 rounded-md border-0 py-1.5 text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
													placeholder="Contoh: 200000"
												/>

												{errors.price?.message ? (
													<div className="text-xs text-red-500 ">{errors.price?.message}</div>
												) : (
													<p className="text-xs italic text-right">
														{currencyFormatter().format(price || 0)}
													</p>
												)}
											</div>
											<div className="flex items-center gap-1 mb-4">
												<input
													id="isFree"
													name="isFree"
													type="checkbox"
													defaultChecked={isFree}
													onChange={(e) => {
														setValue("isFree", e.target.checked);
													}}
												/>
												<label htmlFor="isFree">Gratis</label>
											</div>
										</div>
									</div>

									<button
										type="submit"
										disabled={loadingPostTicket || loadingPutTicket}
										className="rounded-md bg-green-600 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 disabled:cursor-not-allowed disabled:bg-green-200"
									>
										{loadingPostTicket || loadingPutTicket ? (
											<Loading title="Sedang diproses" />
										) : (
											`${defaultData?.id ? "Ubah " : "Buat"} tiket`
										)}
									</button>
								</form>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	);
};
