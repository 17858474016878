import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
	faCalendar,
	faClock,
	faDollarCircle,
	faLeafMaple,
	faLocationPin,
	faSignalStreamSlash,
} from "@fortawesome/pro-duotone-svg-icons";
import { Banner, EventDetails, EventOverview, EventTicketForm, Loading, Wysiwyg } from "src/components/web";
import { useQuery } from "@tanstack/react-query";
import { DateTime, Event } from "src/interface";
import { apiGetEventById } from "src/services";
import { _getDate, useToken } from "src/utils";
import { useState } from "react";
import { ImageCarousel } from "src/components/web/ImageCarousel";

function EventPage() {
	const { id } = useParams();
	const { token } = useToken();

	const { isLoading, data } = useQuery<Event>({
		queryKey: ["event", id],
		queryFn: async () => await apiGetEventById({ id, token }),
	});

	const [selectedDate, setSelectedDate] = useState<DateTime>(null);

	return (
		<>
			{!isLoading ? (
				<>
					<Banner image={data?.banner_image_url} />
					<div className="max-w-screen-xl mx-auto px-5 lg:px-0 py-16">
						<div className="grid grid-cols-1 lg:grid-cols-12 gap-12">
							<div className="col-span-6">
								<div className="mb-12">
									{data?.event_description?.length ? (
										<EventDetails details={data?.event_description} />
									) : (
										""
									)}
									<ImageCarousel images={data?.images} />

									<div className="bg-slate-100">
										<div className="p-7 bg-[#E3E3E3]">
											{data?.date_time?.map((item, index) => (
												<div className="pb-3">
													<div className="text-xl">Session {index + 1}</div>
													<div className="grid md:grid-cols-12 gap-8">
														{data?.price_range && (
															<div className="col-span-7">
																<FontAwesomeIcon
																	icon={faCalendar as IconProp}
																	className="mr-3 text-lime-600"
																/>
																{_getDate(item?.date?.toString())}
															</div>
														)}
														{data?.event_type && (
															<div className="col-span-5">
																<FontAwesomeIcon
																	icon={faClock as IconProp}
																	className="mr-3 text-cyan-600"
																/>
																{item?.time?.toString().slice(0, -3)} -{" "}
																{item?.time_to?.toString().slice(0, -3)} WIB
															</div>
														)}
													</div>
												</div>
											))}
										</div>
										<div className="bg-[#F5F5F5]">
											<div className="grid md:grid-cols-12 gap-8 pt-7 px-7">
												{data?.price_range && (
													<div className="col-span-7">
														<FontAwesomeIcon
															icon={faDollarCircle as IconProp}
															className="mr-3 text-rose-600"
														/>
														{data?.price_range}
													</div>
												)}
												{data?.address && (
													<div className="col-span-5">
														<FontAwesomeIcon
															icon={faLocationPin as IconProp}
															className="mr-3 text-orange-600"
														/>
														{data?.address}
													</div>
												)}
											</div>
											<div className="grid md:grid-cols-12 gap-8 px-7 pt-3 pb-7">
												{data?.venue && (
													<div className="col-span-7">
														<FontAwesomeIcon
															icon={faLeafMaple as IconProp}
															className="mr-3 text-fuchsia-600"
														/>
														{data?.venue}
													</div>
												)}
												{data?.event_type && (
													<div className="col-span-5">
														<FontAwesomeIcon
															icon={faSignalStreamSlash as IconProp}
															className="mr-3 text-teal-600"
														/>
														{data?.event_type}
													</div>
												)}
											</div>
										</div>
									</div>
								</div>
								{data?.event_tnc?.length ? <Wysiwyg text={data?.event_tnc} /> : ""}
							</div>
							<div className="col-span-6">
								<div className="mb-4">
									<EventOverview
										setSelectedDate={setSelectedDate}
										selectedDate={selectedDate}
										dateAndTime={data.date_time}
									/>
								</div>
								{/* <div className="mt-4">
                  <Seating map={data?.seat_images_url} />
                </div> */}
								<EventTicketForm
									data={data}
									title="Tipe Tiket"
									showTicket
									viewOnly
									selectedDate={selectedDate}
								/>
							</div>
						</div>
					</div>
					{/* {MasterData.sponsors.length > 0 &&
          <div className="bg-slate-100">
            <div className="max-w-screen-xl mx-auto py-16">
              <Sponsors data={MasterData.sponsors} />
            </div>
          </div>
          } */}
				</>
			) : (
				<div className="max-w-screen-xl mx-auto py-16">
					<Loading title="Loading event.." />
				</div>
			)}
		</>
	);
}

export default EventPage;
