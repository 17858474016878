import { useEffect, useState } from "react";
import { OrderResponse } from "src/interface";

interface TicketingPaymentFormProps {
  orderResponse: OrderResponse;
}

export const TicketingPaymentForm = ({
  orderResponse,
}: TicketingPaymentFormProps) => {
  const [count, setCount] = useState(3);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (count === 1) {
        window.location.replace(orderResponse?.payment_url || "");
      } else {
        setCount(count - 1);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [count]);

  return (
    <>
      <div className="text-center">
        <p>Menuju ke pembayaran dalam {count} detik...</p>
      </div>
    </>
  );
};
