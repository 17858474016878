import { FilterStatistic } from "./statistik/FilterStatistic";
import { useQuery } from "@tanstack/react-query";
import {
	apiGetStatisticSummerByEventId,
	apiGetStatisticTicketByEventId,
	apiGetStatisticTransactionByEventId,
} from "src/services/StatisticService";
import { useParams } from "react-router-dom";

import { TableAllBuyerTransaction } from "./statistik/TableAllBuyerTransaction";
import { currencyFormatter } from "src/functions/global-functions";
import { Loading } from "src/components/web";
import { Fragment, useState } from "react";
import { apiGetOrderStatus, apiGetPaymentMethod, apiGetType, apiGetZone } from "src/services";
import { Tab } from "@headlessui/react";
import { TableTicketPerEvent } from "./statistik/TableTicketPerEvent";

export const EventStatisticForm = () => {
	const { id } = useParams();
	const [page, setPage] = useState(1);
	const [params, setParams] = useState({});
	const [pageTiket, setPageTiket] = useState(1);

	const { isLoading, data } = useQuery(
		[`event`, `statistic`, `transaction`, params, id, page],
		async () =>
			await apiGetStatisticTransactionByEventId({
				eventId: id,
				params: { page: page || 1, ...params },
			})
	);

	const { isLoading: loadingTiket, data: dataTiket } = useQuery(
		[`event`, `statistic`, `tiket`, pageTiket, id],
		async () =>
			await apiGetStatisticTicketByEventId({
				eventId: id,
				params: { page: pageTiket || 1 },
			})
	);

	const { isLoading: loadingTotal, data: statisticSummary } = useQuery(
		[`event`, `statistic`, `transaction`, id, `total`],
		async () => await apiGetStatisticSummerByEventId({ eventId: id })
	);

	const { data: type } = useQuery(["type"], apiGetType);
	const { data: zone } = useQuery(["ticket", `zone`], apiGetZone);
	const { data: status_order } = useQuery(["ticket", `order`, `status`], apiGetOrderStatus);

	const { data: payment_method } = useQuery(["payment", "method"], apiGetPaymentMethod);

	return (
		<>
			<div className="flex flex-1 mt-4 items-center bg-slate-100 px-4 py-2 rounded-lg text-md">
				<p className="flex-1 font-medium flex items-center">
					Total Penjualan tiket:
					<span className="font-normal ml-1">
						{" "}
						{loadingTotal ? (
							<div className="ml-1">
								<Loading title="Dihitung..." />
							</div>
						) : (
							currencyFormatter().format(statisticSummary?.total_rupiah ?? 0)
						)}
					</span>
				</p>
				<p className="flex-1 font-medium flex items-center">
					Jumlah total QTY penjualan tiket:
					<span className="font-normal ml-1">
						{" "}
						{loadingTotal ? (
							<div>
								<Loading title="Dihitung..." />
							</div>
						) : (
							statisticSummary?.total_ticket ?? 0
						)}
					</span>
				</p>
			</div>

			{/* <div className="mt-6">
        <div className="flex ">
          <div className="flex-1">
            <ChartProvince />
          </div>
          <div className="flex-1">
            <div>
              <GeogratificStatistic />
            </div>
            <div>
              <HeatMapStatistic />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6 px-4 py-3 border">
        <FilterStatistic />
      </div> */}

			<Tab.Group>
				<Tab.List className="border-b border-slate-300 flex mt-2">
					{["Transaction", "Ticket"].map((menu, index) => (
						<Tab as={Fragment} key={`menu-${menu}-${index}`}>
							{({ selected }) => (
								<button
									className={`${
										selected
											? "border-b-2 border-blue-600 font-medium text-slate-900"
											: "border-b-2 border-white"
									} mr-12 py-2 text-slate-500 focus-visible:outline-0`}
								>
									{menu}
								</button>
							)}
						</Tab>
					))}
				</Tab.List>
				<Tab.Panels>
					<Tab.Panel>
						<div className="mt-6 px-4 py-3 border">
							<FilterStatistic
								setParams={setParams}
								type={type}
								zone={zone}
								params={params}
								status_order={status_order}
								payment_method={payment_method}
								// status_ticket={status_ticket}
							/>
						</div>

						<div className="mt-6">
							<TableAllBuyerTransaction data={data} isLoading={isLoading} setPage={setPage} />
						</div>
					</Tab.Panel>
					<Tab.Panel>
						<TableTicketPerEvent data={dataTiket} isLoading={loadingTiket} setPage={setPageTiket} />
					</Tab.Panel>
				</Tab.Panels>
			</Tab.Group>
		</>
	);
};
