import { useQuery } from "@tanstack/react-query";
import { Loading as GlobalLoading } from "src/components/global";
import { currencyFormatter } from "src/functions/global-functions";
import { GetInsight, GetInsightPerEvent } from "src/services/InsightService";
import { _getDate, shortCurrency } from "src/utils";
import { MultiSelect } from "react-multi-select-component";
import { useState } from "react";
import { Loading } from "src/components/web";
import { Disclosure } from "@headlessui/react";
import { ChevronDown, ChevronUp } from "lucide-react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { DATE_FORMAT_DD_MM } from "src/constants";

const SummaryBox = ({ title, value, desc = "" }) => {
	return (
		<div className="py-3 px-5 bg-green-50 rounded-lg">
			<p className="text-green-700 text-md font-medium">{title}</p>
			<p className="mt-12 mb-4 text-6xl text-green-900 font-medium">{value}</p>
			<p className="text-green-700 text-md">{desc}</p>
		</div>
	);
};

const SummaryDropBox = ({ title, value, desc = "" }) => {
	return (
		<div className="py-3 px-5 rounded-lg border">
			<p className="text-slate-700 text-md font-medium">{title}</p>
			<p className="mt-12 mb-4 text-6xl text-slate-900 font-medium">{value}</p>
			<p className="text-slate-700 text-md">{desc}</p>
		</div>
	);
};

const CalendarBox = ({ date, count }) => {
	return (
		<div className="bg-slate-200 rounded-lg overflow-hidden w-20 h-20 flex flex-col items-center justify-center gap-3">
			<div className="text-md text-slate-900">{date}</div>
			<div className="text-lg text-slate-900 font-medium">{count}</div>
		</div>
	);
};

export const InsightTransaction = ({ listEvent }) => {
	const [selected, setSelected] = useState([]);
	const { data: insight, isLoading: loadingInsight } = useQuery<any>([`insight`, `dashboard`], GetInsight);
	const { data: insightPerEvent, isLoading: loadingInsightPerEvent } = useQuery<any>(
		[`insight`, `event`, selected],
		async () => await GetInsightPerEvent({ event_id: selected?.map((item) => item?.value).toString() }),
		{ enabled: !!selected?.length }
	);

	const groupDataByDate = (data) => {
		const groupedData = {};
		console.log(data);
		data.forEach((item) => {
			const date = item.created_at.split("T")[0];

			if (groupedData[date]) {
				groupedData[date]++;
			} else {
				groupedData[date] = 1;
			}
		});
		return groupedData;
	};

	return loadingInsight ? (
		<GlobalLoading />
	) : (
		<div className="mt-10">
			<h1 className="text-slate-500 text-xl font-medium">Summary dari semua event</h1>

			<div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-5">
				<SummaryBox title={"Jumlah total QTY tiket"} value={insight?.total_qty} />
				<SummaryBox
					title={"Jumlah total tiket terjual"}
					value={insight?.total_sales_qty}
					desc={`${(insight?.total_sales_qty
						? insight?.total_sales_qty / insight?.total_qty ?? 0
						: 0
					).toFixed(3)}% dari total tiket terbuat habis terjual`}
				/>
				<SummaryBox
					title={"Total sales penjualan"}
					value={shortCurrency(insight?.total_sales_rp)}
					desc={currencyFormatter().format(insight?.total_sales_rp || 0)}
				/>
			</div>

			<div className="flex items-center justify-between my-10">
				<h1 className="text-slate-500 text-xl font-medium">Event di terpilih untuk di tampilkan</h1>
				<MultiSelect
					options={listEvent?.map((item) => ({
						label: item?.event_title,
						value: item?.id,
					}))}
					value={selected}
					onChange={setSelected}
					labelledBy="Select"
					className="w-[200px] max-w-[200px]"
				/>
			</div>

			{selected?.length ? (
				<div>
					{loadingInsightPerEvent ? (
						<Loading />
					) : insightPerEvent?.length ? (
						insightPerEvent?.map((insightEvent) => (
							<div className="border rounded-lg overflow-hidden">
								<Disclosure defaultOpen={false}>
									{({ open }) => (
										<>
											<Disclosure.Button className={`w-full bg-slate-100`}>
												<div className="flex w-full items-center justify-between rounded-lg p-4 text-left text-md font-medium text-purple-900 focus:outline-none">
													<div className="flex items-start justify-between w-full">
														<div className="flex items-start flex-col justify-start">
															<span className="text-black text-xl">
																{insightEvent?.event_title}
															</span>
															<span className="text-md text-slate-500 font-normal">
																Overview event sale dari tanggal pertama sale hingga
																event day
															</span>
														</div>
														<div>{open ? <ChevronUp /> : <ChevronDown />}</div>
													</div>
												</div>
											</Disclosure.Button>
											<Disclosure.Panel className="pt-4 pb-2 text-sm text-gray-500">
												<div className="px-4 py-2">
													<div className="grid grid-cols-1 md:grid-cols-3 gap-8">
														<SummaryDropBox
															title={"Jumlah total QTY tiket"}
															value={insightEvent?.total_qty}
														/>
														<SummaryDropBox
															title={"Jumlah total tiket terjual"}
															value={insightEvent?.total_sales_qty}
															desc={`${(insightEvent?.total_sales_qty
																? insightEvent?.total_sales_qty /
																		insightEvent?.total_qty ?? 0
																: 0
															)?.toFixed(3)}% dari total tiket terbuat habis terjual`}
														/>
														<SummaryDropBox
															title={"Total sales penjualan"}
															value={shortCurrency(insightEvent?.total_sales_rp)}
															desc={currencyFormatter().format(
																insightEvent?.total_sales_rp || 0
															)}
														/>
													</div>
													<div className="mt-10 relative ">
														<Swiper slidesPerView={10} spaceBetween={30}>
															{insightEvent?.orders?.length ? (
																Object.keys(
																	groupDataByDate(insightEvent?.orders || [])
																)?.map((order) => (
																	<SwiperSlide>
																		<CalendarBox
																			date={_getDate(
																				order,
																				"",
																				DATE_FORMAT_DD_MM
																			)}
																			count={
																				groupDataByDate(
																					insightEvent?.orders || []
																				)?.[order]
																			}
																		/>
																	</SwiperSlide>
																))
															) : (
																<div className="text-center">Belum ada transaksi</div>
															)}
														</Swiper>
													</div>
												</div>
											</Disclosure.Panel>
										</>
									)}
								</Disclosure>
							</div>
						))
					) : (
						<div className="text-center">Event Tidak Ditemukan</div>
					)}
				</div>
			) : (
				<div className="text-center">Belum ada event yang dipilih</div>
			)}
		</div>
	);
};
