import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { serialize } from "object-to-formdata";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Loading } from "src/components/web";
import { TextComponent } from "src/partials/ticketing/detail/DetailInputComponent";
import { apiPutPartnerAccount } from "src/services";
import { z } from "zod";

const ChangePassSchema = z
	.object({
		password: z
			.string()
			.nonempty("Password wajib diisi")
			.min(8, "Password harus mengandung minimal 8 karakter")
			.refine((value) => /[A-Z]/.test(value), {
				message: "Password harus mengandung minimal 1 karakter huruf besar",
			})
			.refine((value) => /[a-z]/.test(value), {
				message: "Password harus mengandung minimal 1 karakter huruf kecil",
			})
			.refine((value) => /[0-9]/.test(value), {
				message: "Password harus mengandung minimal 1 angka",
			})
			.refine((value) => /[^A-Za-z0-9]/.test(value), {
				message: "Password harus mengandung minimal 1 simbol",
			}),
		password_confirmation: z
			.string()
			.nonempty("Konfirmasi password wajib diisi")
			.min(8, "Password harus mengandung minimal 8 karakter")
			.refine((value) => /[A-Z]/.test(value), {
				message: "Konfirmasi password harus mengandung minimal 1 karakter huruf besar",
			})
			.refine((value) => /[a-z]/.test(value), {
				message: "Konfirmasi password harus mengandung minimal 1 karakter huruf kecil",
			})
			.refine((value) => /[0-9]/.test(value), {
				message: "Konfirmasi password harus mengandung minimal 1 angka",
			})
			.refine((value) => /[^A-Za-z0-9]/.test(value), {
				message: "Konfirmasi password harus mengandung minimal 1 simbol",
			}),
	})
	.refine((data) => data.password === data.password_confirmation, {
		message: "Password tidak sama dengan konfirmasi password",
		path: ["confirm"],
	});

type ChangePassForm = z.infer<typeof ChangePassSchema>;

export const ChangePassForm = ({ data }) => {
	const defaultValue: ChangePassForm = {
		password: "",
		password_confirmation: "",
	};

	const navigate = useNavigate();

	const { mutate: putUser, isLoading: loadingPutUser } = useMutation(async ({ id, data }: { id: string; data: any }) => await apiPutPartnerAccount({ id, data }));

	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
	} = useForm<ChangePassForm>({
		resolver: zodResolver(ChangePassSchema),
		defaultValues: defaultValue,
	});

	return (
		<>
			<form
				onSubmit={handleSubmit((value: ChangePassForm) => {
					const formData = serialize({
						firstname: data?.firstname,
						lastname: data?.lastname,
						title: data?.title,
						mobile_number: data?.mobile_number,
						email: data?.email,
						picture_path: data?.picture_path,
						...value,
					});
					putUser(
						{ id: data?.id, data: formData },
						{
							onSuccess: (res) => {
								toast.success("Password berhasil diubah");
								navigate(`/admin/login`);
							},
							onError: (res) => {
								toast.error("Terjadi kesalahan");
							},
						}
					);
				})}
			>
				<div className="mt-4">
					<div className="sm:grid sm:grid-cols-3 sm:items-start">
						<label htmlFor="lastname" className="block text-sm font-medium leading-8 text-gray-900 sm:pt-1.5">
							Password
						</label>
						<div className="mt-2 sm:col-span-2 sm:mt-0">
							<TextComponent title={""} id={`password`} register={register(`password`)} name={`password`} type={"password"} errors={(errors?.password?.message as string) || undefined} />
						</div>
					</div>
					<div className="sm:grid sm:grid-cols-3 sm:items-start">
						<label htmlFor="lastname" className="block text-sm font-medium leading-8 text-gray-900 sm:pt-1.5">
							Konfirmasi password
						</label>
						<div className="mt-2 sm:col-span-2 sm:mt-0">
							<TextComponent
								title={""}
								id={`password_confirmation`}
								register={register(`password_confirmation`)}
								name={`password_confirmation`}
								type={"password"}
								errors={(errors?.password_confirmation?.message as string) || (errors?.["confirm"]?.message as string) || undefined}
							/>
						</div>
					</div>
				</div>
				<div className="flex pt-6 items-center justify-center w-full">
					<button
						type="submit"
						disabled={loadingPutUser}
						className="rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 disabled:cursor-not-allowed disabled:bg-blue-200"
					>
						{loadingPutUser ? <Loading /> : "Ganti Kata Sandi"}
					</button>
				</div>
			</form>
		</>
	);
};
