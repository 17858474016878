import {
  faCalendarLinesPen,
  faChartMixed,
  faImages,
  faPersonToPortal,
  faTicket,
  faWallet,
} from "@fortawesome/pro-duotone-svg-icons";

export const EVENT_FORM_NAVIGATION = (data: any = null) => [
  {
    name: "Tentang Event",
    icon: faCalendarLinesPen,
    iconColor: "text-purple-500",
    disabled: false,
  },
  {
    name: "Galeri Gambar",
    icon: faImages,
    iconColor: "text-red-500",
    disabled: !!!data,
  },
  {
    name: "Tiket",
    icon: faTicket,
    iconColor: "text-blue-500",
    disabled: !!!data,
  },
  // {
  //   name: "Dompet",
  //   icon: faWallet,
  //   iconColor: "text-green-500",
  //   disabled: true,
  // },
  {
    name: "Portal",
    icon: faPersonToPortal,
    iconColor: "text-yellow-500",
    disabled: !!!data,
  },
  {
    name: "Statistik",
    icon: faChartMixed,
    iconColor: "text-fuchsia-500",
    disabled: !!!data,
  },
];
