import { faInboxIn, faInboxOut } from "@fortawesome/pro-duotone-svg-icons";

export const CHECKIN_FORM_NAVIGATION = [
  {
    name: "Checkin",
    icon: faInboxOut,
    iconColor: "text-purple-500",
    disabled: false,
  },
  {
    name: "Checkout",
    icon: faInboxIn,
    iconColor: "text-red-500",
    disabled: false,
  },
];
