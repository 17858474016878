import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { Loading, Steps } from "src/components/web";
import { useToken } from "src/utils";
import { useQuery } from "@tanstack/react-query";
import { apiGetEventById, apiGetMasterData } from "src/services";
import { Event, MasterData, Order } from "src/interface";
import { TicketingForm } from "src/partials/ticketing";

function TicketingPage() {
	const { id, dateId } = useParams();
	const { token } = useToken();

	const { isLoading, data } = useQuery<Event>({
		queryKey: ["event", id],
		queryFn: async () => await apiGetEventById({ id, token }),
	});

	const { data: MasterData } = useQuery<MasterData>({
		queryKey: ["master-data"],
		queryFn: apiGetMasterData,
	});

	const [formState, setFormState] = useState<Order>({});
	const [currentStep, setCurrentStep] = useState<number>(1);

	useEffect(() => {
		if (data) {
			const selectedDate = data.date_time.find((date_time) => date_time.id === +dateId);

			setFormState((prev) => ({
				...prev,
				event_id: data.id,
				fee_tixie: 5000,
				event_date_time: selectedDate,
			}));
		}
	}, [data, dateId]);

	return isLoading ? (
		<div className="max-w-screen-xl mx-auto py-12 px-5 lg:px-0">
			<Loading title="Loading" />
		</div>
	) : (
		<>
			<div className="max-w-screen-xl mx-auto pt-12 px-5 lg:px-0">
				<Steps currentStep={currentStep} />
			</div>
			<div className="max-w-screen-xl mx-auto py-12 px-5 lg:px-0">
				<TicketingForm
					currentStep={currentStep}
					data={data}
					setCurrentStep={setCurrentStep}
					setFormState={setFormState}
					formState={formState}
				/>
			</div>
		</>
	);
}

export default TicketingPage;
