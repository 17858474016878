import { useCallback, useEffect, useState } from "react";
import { Disclosure, Transition } from "@headlessui/react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faChevronDown } from "@fortawesome/pro-light-svg-icons";
import { useNavigate } from "react-router-dom";
import { currencyFormatter } from "src/functions/global-functions";
import { DateTime, Event, EventTicket, Order, OrderTicket, Ticket, TypeOrderTicket } from "src/interface";
import { useToken } from "src/utils";
import { useMutation } from "@tanstack/react-query";
import { apiCheckKupon, apiPostOrder } from "src/services/OrderService";
import { Loading } from "./Loading";
import { TextComponent } from "src/partials/ticketing/detail/DetailInputComponent";
import { toast } from "react-toastify";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi2";

interface EventTicketProps {
	title?: string;
	showTicket?: boolean;
	showPrice?: boolean;
	data?: Event;
	viewOnly?: boolean;
	proceed?: boolean;
	setFormState?: React.Dispatch<React.SetStateAction<Order>>;
	setCurrentStep?: React.Dispatch<React.SetStateAction<number>>;
	selectedDate?: DateTime;
	formState?: Order;
	setOrderResponse?: React.Dispatch<React.SetStateAction<Order>>;
}

export const EventTicketForm = ({
	data,
	showTicket,
	showPrice,
	viewOnly,
	setFormState,
	setCurrentStep,
	selectedDate,
	formState,
	proceed,
	setOrderResponse,
}: EventTicketProps) => {
	const navigate = useNavigate();
	const [orderTicket, setOrderTicket] = useState<OrderTicket[]>([]);
	const [kupon, setKupon] = useState<string>("");
	const [kuponData, setKuponData] = useState<any>(formState?.kuponData);
	const [orderValue, setOrderValue] = useState<{ total: number; ppn: number; fee: number; discount: number }>({
		total: 0,
		ppn: 0,
		fee: 0,
		discount: formState?.discount || 0,
	});
	const [errorOrderTicket, setErrorOrderTicket] = useState<
		{
			zone: number;
			typesId: number;
		}[]
	>([]);
	const [acceptTerm, setAcceptTerm] = useState<number[]>([]);

	const BuildTypeOrderTicket = (event: EventTicket, qty): TypeOrderTicket => ({
		id: event.ticket_type.id,
		qty,
		price: event.price,
		users: [],
	});

	const setError = ({ ticket, event_ticket }: { event_ticket: EventTicket; ticket: Ticket }) => {
		const currentError = errorOrderTicket.find(
			(value) => value.zone === ticket.zone.id && value.typesId === event_ticket.ticket_type.id
		);

		if (!currentError) {
			setErrorOrderTicket([
				...errorOrderTicket,
				{
					zone: ticket.zone.id,
					typesId: event_ticket.ticket_type.id,
				},
			]);
		}
	};

	const removeError = ({ ticket, event_ticket }: { event_ticket: EventTicket; ticket: Ticket }) => {
		setErrorOrderTicket((prev) =>
			prev.filter((value) => value.typesId !== event_ticket.ticket_type.id || value.zone !== ticket.zone.id)
		);
	};

	const getError = ({ ticket, event_ticket }: { event_ticket: EventTicket; ticket: Ticket }) => {
		return errorOrderTicket.find(
			(value) => value.zone === ticket.zone.id && value.typesId === event_ticket.ticket_type.id
		);
	};

	const getItem = ({ event_ticket, ticket }) => {
		const currentZoneOrderTicket = orderTicket.find((value) => value.zone === ticket.zone.id);

		if (currentZoneOrderTicket) {
			const currentTypeOrderTicket = currentZoneOrderTicket.types.find(
				(value) => value.id === event_ticket.ticket_type.id
			);

			if (currentTypeOrderTicket) return currentTypeOrderTicket;
		}

		return { qty: "" };
	};

	const updateItem = ({ event, qty, ticket }: { event: EventTicket; qty: number; ticket: Ticket }) => {
		const currentZoneOrderTicket = orderTicket.find((value) => value.zone === ticket.zone.id);
		if (!currentZoneOrderTicket) {
			const newOrderTicket: OrderTicket = {
				zone: ticket.zone.id,
				types: [BuildTypeOrderTicket(event, qty)],
			};

			setOrderTicket([...orderTicket, newOrderTicket]);
		} else {
			const currentTypeOrderTicket = currentZoneOrderTicket.types.find(
				(value) => value.id === event.ticket_type.id
			);

			if (!currentTypeOrderTicket) {
				const newTypeOrder: TypeOrderTicket = BuildTypeOrderTicket(event, qty);
				currentZoneOrderTicket.types.push(newTypeOrder);
			} else {
				if (qty) {
					currentTypeOrderTicket.qty = qty;
				} else {
					currentZoneOrderTicket.types = currentZoneOrderTicket.types.filter(
						(value) => value.id !== currentTypeOrderTicket.id
					);
				}
			}

			if (currentZoneOrderTicket.types.length) {
				setOrderTicket((prev) =>
					prev.map((value) => (value.zone === currentZoneOrderTicket.zone ? currentZoneOrderTicket : value))
				);
			} else {
				setOrderTicket((prev) => prev.filter((value) => value.zone !== currentZoneOrderTicket.zone));
			}
		}
	};

	const calculateTotal = useCallback(() => {
		const total = orderTicket.reduce((prev, curr) => {
			return (
				curr.types.reduce((typePrev, typeCurr) => {
					return typeCurr.price * typeCurr.qty + typePrev;
				}, 0) + prev
			);
		}, 0);
		const ppn = total * 0.11;
		const fee = (total * 6.5) / 100;

		let discount = 0;
		if (total) {
			discount =
				kuponData?.voucher?.type === "fix"
					? kuponData?.voucher?.type_value === "amount"
						? kuponData?.voucher?.value
						: (kuponData?.voucher?.value / 100) * total
					: kuponData?.voucher?.type === "free"
					? total
					: 0;
		}
		return { total, ppn, fee, discount };
	}, [orderTicket, kuponData]);

	const { token } = useToken();

	const { mutate, isLoading } = useMutation(async (order: Order) => await apiPostOrder({ order, token }));
	const { mutate: postKupon, isLoading: loadingPostKupon } = useMutation(
		async (serial: string) => await apiCheckKupon({ serial, token })
	);

	useEffect(() => {
		setOrderValue(calculateTotal());
	}, [calculateTotal, orderTicket, kuponData]);

	useEffect(() => {
		setOrderTicket(formState?.ticket || []);
	}, [formState]);

	return (
		<div className={showPrice && showTicket ? "grid grid-cols-1 md:grid-cols-2 gap-4" : ""}>
			{showTicket && (
				<div className={showPrice ? "flex-1" : "col-span-12"}>
					{data?.tickets
						?.filter((ticket: Ticket) => ticket?.status_id)
						.map((ticket: Ticket, index: number) => {
							if (ticket.event_tickets.length > 0) {
								return (
									<div
										className="mb-5 rounded-md overflow-hidden"
										key={`ticket-courosel-${ticket.id}-${index}`}
									>
										<Disclosure defaultOpen>
											{({ open }) => (
												<>
													<Disclosure.Button className="w-full text-left flex justify-between bg-gray-700 text-white p-4">
														<div>
															<h3 className="text-2xl">{ticket.zone.title}</h3>
															<p>{ticket.zone.description}</p>
														</div>
														<FontAwesomeIcon
															icon={faChevronDown as IconProp}
															className={open ? "rotate-180 transform" : ""}
														/>
													</Disclosure.Button>
													<Transition
														enter="transition duration-100 ease-out"
														enterFrom="transform scale-95 opacity-0"
														enterTo="transform scale-100 opacity-100"
														leave="transition duration-75 ease-out"
														leaveFrom="transform scale-100 opacity-100"
														leaveTo="transform scale-95 opacity-0"
													>
														<Disclosure.Panel className="bg-gray-100 p-5">
															{ticket.event_tickets.map(
																(event_ticket: EventTicket, i: number) => {
																	return (
																		<div
																			className={`grid ${
																				viewOnly
																					? "md:grid-cols-2"
																					: "md:grid-cols-3"
																			} grid-cols-2 bg-white gap-4 p-5 mb-4`}
																			key={`event-ticket-${event_ticket.ticket_type.id}-${i}`}
																		>
																			<div className="mb-2 md:mb-0">
																				<h4 className="text-xl font-medium">
																					{event_ticket.ticket_type.title}
																				</h4>
																				<p className="text-gray-500">
																					{
																						event_ticket.ticket_type
																							.description
																					}
																				</p>
																			</div>
																			<div className="mb-2 md:mb-0">
																				<p className="text-xl ">
																					{event_ticket.price ? (
																						currencyFormatter().format(
																							event_ticket.price
																						)
																					) : (
																						<span className="font-medium text-green-500">
																							GRATIS
																						</span>
																					)}
																				</p>
																				{event_ticket?.stock > 0 ? (
																					<p className="text-gray-500">
																						QTY
																						<span className="font-medium ml-2">
																							{event_ticket.stock}
																						</span>
																					</p>
																				) : (
																					<p className="text-red-500">
																						SOLD OUT
																					</p>
																				)}
																			</div>
																			{!viewOnly && (
																				<div className="sm:w-full max-w-[100px] mb-2 md:mb-0 flex gap-1 items-center">
																					<div>
																						<button
																							type="button"
																							onClick={() => {
																								const qty: number =
																									+getItem({
																										ticket,
																										event_ticket,
																									})?.qty;

																								if (qty - 1 >= 0) {
																									updateItem({
																										event: event_ticket,
																										qty: qty - 1,
																										ticket,
																									});
																								}
																							}}
																							className="bg-slate-500 mt-1 rounded-full p-1"
																						>
																							<HiChevronLeft className="text-white text-md" />
																						</button>
																					</div>
																					<div
																						className={`flex flex-col ${
																							event_ticket?.maximum_buying
																								? "mt-3"
																								: ""
																						}`}
																					>
																						<input
																							type="number"
																							readOnly
																							disabled
																							min={0}
																							inputMode="numeric"
																							defaultValue={
																								getItem({
																									ticket,
																									event_ticket,
																								})?.qty
																							}
																							className={`block sm:flex-1 md:w-24 rounded-md py-2 pl-3 text-base text-center ${
																								getError({
																									ticket,
																									event_ticket,
																								})
																									? `border-red-300`
																									: `focus:border-blue-500 focus:outline-none focus:ring-blue-500 border-gray-300`
																							}  sm:text-sm`}
																						/>
																						{event_ticket?.maximum_buying ? (
																							<p className="text-xs text-right mt-1">
																								Max{" "}
																								{
																									event_ticket.maximum_buying
																								}
																							</p>
																						) : (
																							""
																						)}
																					</div>
																					<div>
																						<button
																							type="button"
																							onClick={() => {
																								const qty: number =
																									+getItem({
																										ticket,
																										event_ticket,
																									})?.qty;

																								if (
																									qty <
																										event_ticket.stock &&
																									(event_ticket?.maximum_buying !==
																									0
																										? qty <
																										  event_ticket?.maximum_buying
																										: true)
																								) {
																									updateItem({
																										event: event_ticket,
																										qty: qty + 1,
																										ticket,
																									});
																								}
																							}}
																							className="bg-slate-500 mt-1 rounded-full p-1"
																						>
																							<HiChevronRight className="text-white text-md" />
																						</button>
																					</div>
																				</div>
																			)}
																		</div>
																	);
																}
															)}
														</Disclosure.Panel>
													</Transition>
												</>
											)}
										</Disclosure>
									</div>
								);
							}
						})}
				</div>
			)}

			{showPrice && (
				<div className={showTicket ? "flex-1" : "col-span-12"}>
					<div>
						<div className="mb-5 rounded-md border-slate-200 border-2 content-between h-full grid">
							<div className="p-8">
								{orderTicket?.length ? (
									<>
										{orderTicket.map((order, index) => {
											const ticketZone = data.tickets.find(
												(ticket) => ticket.zone.id === order.zone
											);
											return (
												<div
													className="border-b pb-4 mb-4 border-b-gray-300"
													key={`ticket-${order.zone}-${index}`}
												>
													<div className="flex justify-between mb-3">
														<div className="text-xl font-medium flex justify-between w-full">
															<div>
																{order.types.reduce((prev, curr) => {
																	return curr.qty + prev;
																}, 0)}
																x {ticketZone.zone.title}
															</div>
															<div>
																{order.types.reduce((prev, curr) => {
																	return curr.qty * curr.price + prev;
																}, 0) ? (
																	currencyFormatter().format(
																		order.types.reduce((prev, curr) => {
																			return curr.qty * curr.price + prev;
																		}, 0)
																	)
																) : (
																	<div className="font-medium text-green-500">
																		Gratis
																	</div>
																)}
															</div>
														</div>
													</div>

													{order.types.map((type, i) => {
														const ticketType = ticketZone.event_tickets.find(
															(value) => value.ticket_type.id === type.id
														);
														return (
															<div
																key={`event-ticket-${type.id}-${i}`}
																className="text-gray-500 mb-3 text-lg"
															>
																<div key={i} className="flex justify-between ">
																	<p className="flex items-center">
																		<span
																			className={`w-6 h-6 justify-center items-center text-xs flex ${
																				getError({
																					ticket: ticketZone,
																					event_ticket: ticketType,
																				})
																					? "bg-red-500 text-white"
																					: "bg-gray-200"
																			} mr-2 overflow-hidden`}
																		>
																			{type.qty >= 1000 ? 999 : type.qty}
																		</span>
																		{ticketType.ticket_type.title}
																	</p>
																</div>
															</div>
														);
													})}
												</div>
											);
										})}
										{!proceed ? (
											<div className="flex items-center gap-2">
												<div className="flex-1">
													<TextComponent
														id={"kupon"}
														name={"kupon"}
														type={"text"}
														onChange={(e: any) => setKupon(e.target.value)}
														placeholder={"Masukkan Kupon"}
													/>
												</div>
												<button
													type="button"
													disabled={loadingPostKupon}
													onClick={() => {
														postKupon(kupon, {
															onSuccess: (data) => {
																setKuponData(data);
															},
															onError: (error: any) => {
																toast.error("Kupon tidak ditemukan");
																setKuponData(null);
															},
														});
													}}
													className={`justify-center mb-4 inline-flex h-9 mt-1 items-center rounded-md border border-transparent bg-green-500 disabled:bg-green-200 px-6 text-base font-medium text-white shadow-sm focus:outline-none disabled:cursor-not-allowed`}
												>
													{loadingPostKupon ? (
														<Loading title={"Sedang diproses"} />
													) : (
														"Cek Kupon"
													)}
												</button>
											</div>
										) : (
											""
										)}
									</>
								) : (
									<p className="text-center">Keranjang masih kosong</p>
								)}
							</div>

							{orderTicket?.length ? (
								<div className={`${!proceed ? "bg-slate-100 " : "bg-white pt-0"} md:p-8 p-4 `}>
									{orderValue?.discount ? (
										<div className="mb-5 flex justify-between">
											<p className="text-gray-500 md:text-xl text-lg">Diskon</p>
											<p className="text-gray-500 md:text-xl text-lg">
												{currencyFormatter().format(orderValue.discount)}
											</p>
										</div>
									) : (
										""
									)}
									<div className="mb-5 flex justify-between">
										<p className="text-gray-500 md:text-xl text-lg">PPN</p>
										<p className="text-gray-500 md:text-xl text-lg">
											{orderValue?.ppn ? (
												currencyFormatter().format(orderValue.ppn)
											) : (
												<div className="font-medium text-green-500">Gratis</div>
											)}
										</p>
									</div>
									<div className="md:mb-10 mb-5 flex justify-between">
										<p className="text-gray-500 md:text-xl text-lg">Service Fee</p>
										<p className="text-gray-500 md:text-xl text-lg">
											{orderValue.fee ? (
												currencyFormatter().format(orderValue.fee)
											) : (
												<div className="font-medium text-green-500">Gratis</div>
											)}
										</p>
									</div>
									<div className="flex justify-between">
										<p className="md:text-2xl text-xl font-medium">TOTAL</p>
										<p className="md:text-2xl text-xl font-medium">
											{orderValue.total +
											orderValue.ppn +
											orderValue.fee -
											orderValue.discount ? (
												currencyFormatter().format(
													orderValue.total +
														orderValue.ppn +
														orderValue.fee -
														orderValue.discount
												)
											) : (
												<div className="font-medium text-green-500">Gratis</div>
											)}
										</p>
									</div>

									{!proceed ? (
										<button
											type="button"
											disabled={!!errorOrderTicket?.length || !!!orderTicket?.length}
											onClick={() => {
												setFormState((prev) => ({
													...prev,
													ticket: orderTicket,
													fee_tixie: orderValue.fee,
													vat: orderValue.ppn,
													total_price: orderValue.total,
													discount: orderValue.discount,
													voucher_used_id: kuponData?.id || 0,
													kuponData: kuponData,
													grand_total:
														orderValue.total +
														orderValue.ppn +
														orderValue.fee -
														orderValue?.discount,
												}));
												setCurrentStep(2);
											}}
											className={`w-full mt-12 justify-center inline-flex items-center rounded-md border border-transparent bg-blue-600 disabled:bg-blue-200 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:cursor-not-allowed`}
										>
											Pesan Tiket
										</button>
									) : (
										""
									)}
								</div>
							) : (
								""
							)}
						</div>
						{proceed
							? data?.agreement_list?.length >= 1 && (
									<div className="bg-orange-100 border border-orange-200 rounded-lg p-8">
										{data?.agreement_list?.map((value) => {
											return (
												<div
													className="flex items-baseline gap-2 mb-2 last:mb-0"
													key={`aggrement-list-${value.id}`}
												>
													<div>
														<input
															onChange={(e: any) => {
																if (e.target.checked) {
																	setAcceptTerm((prev) => [...prev, value.id]);
																} else {
																	setAcceptTerm((prev) =>
																		prev.filter((at) => at !== value.id)
																	);
																}
															}}
															className="w-4 h-4 text-blue-600 cursor-pointer bg-white border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 focus:ring-2"
															type="checkbox"
														/>
													</div>
													<div>
														<p>{value.title}</p>
														<p className="text-gray-500 text-sm font-normal">
															{value.description}
														</p>
													</div>
												</div>
											);
										})}
									</div>
							  )
							: ""}
						<div>
							{proceed ? (
								<button
									type="button"
									disabled={acceptTerm.length !== data?.agreement_list.length || isLoading}
									onClick={() => {
										mutate(formState, {
											onSuccess: (data) => {
												setOrderResponse(data);
												if (!data?.grand_total) {
													navigate(`/order/success`);
												} else {
													setCurrentStep(4);
												}
											},
										});
									}}
									className={`w-full mt-4 justify-center inline-flex items-center rounded-md border border-transparent bg-green-500 disabled:bg-green-200 px-6 py-3 text-base font-medium text-white shadow-sm focus:outline-none disabled:cursor-not-allowed`}
								>
									{isLoading ? <Loading title={"Sedang diproses"} /> : "Proses Tiket"}
								</button>
							) : (
								""
							)}
						</div>
					</div>
				</div>
			)}

			{viewOnly && (
				<button
					type="button"
					disabled={!!!data?.tickets?.filter((ticket) => ticket?.status_id)?.length}
					onClick={() => navigate(`/ticketing/${data.id}/${selectedDate?.id}`)}
					className="col-span-12 justify-center inline-flex items-center rounded-md disabled:bg-blue-200 disabled:cursor-not-allowed border border-transparent bg-blue-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 w-full"
				>
					Pesan Tiket
				</button>
			)}
		</div>
	);
};
