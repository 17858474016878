import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { DATE_FORMAT_DD_MMM_YYYY } from "src/constants";
import { News } from "src/interface";
import { _getDate, _getTime } from "src/utils";

interface NewsDetailProps {
	show: boolean;
	_onClose: () => void;
	data: News;
}

export const NewsDetail = ({ show, _onClose, data }: NewsDetailProps) => {
	return (
		<Transition appear show={show} as={Fragment}>
			<Dialog as="div" className="relative z-10" onClose={_onClose}>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-black bg-opacity-25" />
				</Transition.Child>

				<div className="fixed inset-0 overflow-y-auto">
					<div className="flex min-h-full items-center justify-center p-4 text-center">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
								<img className="w-full h-[300px] object-cover" src={data.image_url} alt={data.title} />

								<p className="text-sm mt-2 uppercase text-gray-400 font-medium mb-2">
									{_getDate(data?.date, "", DATE_FORMAT_DD_MMM_YYYY)}
									{data?.date ? _getTime(data?.date, "") : ""}
								</p>
								<h3 className="font-medium">{data.title}</h3>
								<p className="text-gray-600 mb-5">{data.description}</p>
								<div className="flex items-center justify-center">
									<button
										type="button"
										onClick={_onClose}
										className="bg-red-500 text-white px-4 py-1 rounded-lg"
									>
										Tutup
									</button>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	);
};
