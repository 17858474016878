import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCalendar, faClock, faEye } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover } from "@headlessui/react";
// import dayjs from "dayjs";
// import { DATE_FORMAT_DD_MM_YYYY, TIME_FORMAT_HH_MM } from "src/constants";
import { currencyFormatter } from "src/functions/global-functions";
import { _getDate, _getTime } from "src/utils";

export const BuildStatisticTransactionTableSchema = ({ setTransactionData }: any) => {
	return [
		{
			header: "Tanggal",
			cell: (value) => {
				return (
					<>
						<div>
							<FontAwesomeIcon className="mr-2" aria-hidden="true" icon={faCalendar as IconProp} />
							{_getDate(value?.created_at)}
						</div>
						<div>
							<FontAwesomeIcon className="mr-2" aria-hidden="true" icon={faClock as IconProp} />
							{_getTime(value?.created_at)}
						</div>
					</>
				);
			},
		},
		{
			header: "Transaksi",
			cell: (value) => {
				return (
					<button
						type="button"
						onClick={() => setTransactionData(value)}
						className="rounded bg-white px-2 py-1 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
					>
						Lihat transaksi
						<FontAwesomeIcon icon={faEye as IconProp} className="ml-2" />
					</button>
				);
			},
		},
		{
			header: "Total (Rp)",
			cell: (value) => currencyFormatter().format(value?.grand_total || 0),
		},
		{
			header: "Metode Pembayaran",
			cell: (value) => (
				<span>
					{value?.payment?.payment_method} {value?.payment?.ewallet_type}
				</span>
			),
		},
		{
			header: "Tiket ID",
			cell: (value) => (
				<Popover className="relative">
					<Popover.Button>
						<button
							type="button"
							className="rounded bg-white px-2 py-1 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
						>
							Tiket ID
							<FontAwesomeIcon icon={faEye as IconProp} className="ml-2" />
						</button>
					</Popover.Button>

					<Popover.Panel className="absolute z-100 bg-slate-300 p-3 rounded-md mt-1">
						<ul>
							{value?.tickets?.map((tiket) => (
								<li key={tiket?.id}>{tiket?.unique_id}</li>
							))}
						</ul>
					</Popover.Panel>
				</Popover>
			),
		},
		{
			header: "KTP",
			cell: (value) => value?.buyer?.number_identity_card,
		},
		{
			header: "Status Pembelian",
			cell: (value) => value?.status?.status,
		},
	];
};
