import { useState, useEffect } from "react";
import { Input } from "../ui/input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faXmarkCircle, faXmark } from "@fortawesome/pro-solid-svg-icons";
import { Loading } from "../web";

export interface IProps {
  setCollectionItem?: Function;
  collectionItem?: any;
  multipleImage?: boolean;
  currentImage?: any;
  projectId?: string;
  type?: string;
  imageFunction?: Function;
  loading?: boolean;
}

export const ImageUpload = (props: IProps) => {
  const [files, setFile] = useState([]);
  const [singleFile, setSingleFile] = useState();
  const [currentImage, setCurrentImage] = useState();

  const handlerFile = (e) => {
    let allFiles = [];

    for (let i = 0; i < e.target.files.length; i++) {
      if (props.type === "image_url")
        allFiles.push({ image_url: e.target.files[i] });
    }
    if (
      props.type === "single_file" ||
      props.type === "logo" ||
      props.type === "banner_image_url" ||
      props.type === "banner_url"
    )
      setSingleFile(e.target.files[0]);

    if (allFiles.length > 0) {
      setFile(allFiles);
    }
  };
  const removeFile = (id) => {
    const newArray = files.filter((item, i) => {
      if (i !== id) return item;
    });
    setFile(newArray);
  };

  useEffect(() => {
    if (props.type === "image_url") {
      props?.setCollectionItem({ ...files, event_id: props.projectId });
    }
  }, [files]);

  useEffect(() => {
    if (props.type === "single_file") {
      props?.setCollectionItem({
        ...props.collectionItem,
        image_url: singleFile,
      });
    }
    if (props.type === "logo") {
      props?.setCollectionItem({
        ...props.collectionItem,
        logo_url: singleFile,
      });
    }
    if (props.type === "banner_url") {
      props?.setCollectionItem({
        ...props.collectionItem,
        banner_url: singleFile,
      });
    }
    if (props.type === "banner_image_url") {
      props?.setCollectionItem({
        ...props.collectionItem,
        banner_image_url: singleFile,
        event_id: props.projectId,
      });
    }
  }, [singleFile]);

  return (
    <div>
      <div className="grid grid-cols-3 gap-4 mb-4">
        {files?.map((file, key) => {
          return (
            <div key={key} className="border p-3 rounded-sm relative">
              <button
                className="absolute -top-2 -right-2 text-md"
                onClick={(e) => removeFile(key)}
              >
                <FontAwesomeIcon
                  icon={faXmarkCircle as IconProp}
                  className="text-red-500 text-xl"
                />
              </button>
              <p className="truncate text-gray-600">{file?.name}</p>
              {(props.type === "banner_image_url" && (
                <img
                  src={URL.createObjectURL(file?.banner_image_url)}
                  alt={file?.banner_image_url.name}
                />
              )) ||
                (props.type === "image_url" && (
                  <img
                    src={URL.createObjectURL(file?.image_url)}
                    alt={file?.image_url.name}
                  />
                )) ||
                (props.type === "single_file" && (
                  <img
                    src={URL.createObjectURL(file?.image_url)}
                    alt={file?.image_url.name}
                  />
                ))}
            </div>
          );
        })}
        {singleFile && <img src={URL.createObjectURL(singleFile)} alt="" />}
        {(!singleFile || !files) && props.currentImage && (
          <img src={props.currentImage} alt="" />
        )}
      </div>
      <div className="flex">
        <Input
          id="picture"
          type="file"
          onChange={handlerFile}
          multiple={props.multipleImage ? props.multipleImage : false}
          className="bg-slate-200 mb-0 mr-4 w-64"
        />
        {files.length > 0 && (
          <button
            className="h-10 rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            onClick={(e) => setFile([])}
          >
            <FontAwesomeIcon icon={faXmark as IconProp} />
          </button>
        )}
      </div>
      {props.imageFunction && (
        <button
          type="button"
          className="mt-2 rounded bg-indigo-600 px-2 py-1 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-indigo-300"
          disabled={
            props?.loading || files.length > 0 || singleFile ? false : true
          }
          onClick={(e) => {
            props.imageFunction();
          }}
        >
          {props?.loading ? (
            <Loading title="Sedang diproses" />
          ) : (
            "Upload gambar"
          )}
        </button>
      )}
    </div>
  );
};
