import { zodResolver } from "@hookform/resolvers/zod";
import Alert from "@mui/material/Alert";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { TextComponent } from "src/partials/ticketing/detail/DetailInputComponent";
import { apiPostLogin, apiPostLogout } from "src/services";
import { useLocalStorage, useToken } from "src/utils";
import { z } from "zod";

const LoginSchema = z.object({
	email: z.string().email("Format email salah").nonempty("Email wajib diisi"),
	password: z.string().nonempty("Password wajib diisi"),
});

type LoginForm = z.infer<typeof LoginSchema>;

export const Login = () => {
	const { setToken, removeToken, token } = useToken("token");
	const { removeItem } = useLocalStorage("partner");
	const navigate = useNavigate();

	const defaultValue: LoginForm = {
		email: "",
		password: "",
	};

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<LoginForm>({
		resolver: zodResolver(LoginSchema),
		defaultValues: defaultValue,
	});

	const { mutate: loginMutate } = useMutation(async (data: any) => await apiPostLogin(data));

	const { mutate: logoutMutate } = useMutation(apiPostLogout);

	const [errorMsg, setErrorMsg] = useState<any>([]);

	const queryClient = useQueryClient();

	useEffect(() => {
		if (token) {
			logoutMutate();
		}

		removeToken();
		removeItem();
		queryClient.clear();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<>
			<div className="flex min-h-full w-full absolute flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8 bg-slate-100">
				<div className="sm:mx-auto sm:w-full sm:max-w-md">
					<img className="h-12 w-auto mx-auto" src="/tixie-logo.png" alt="Tixie" />
					<h2 className="mt-6 text-center text-2xl font-medium leading-9 tracking-tight text-gray-900">
						Sign in to your account
					</h2>
				</div>

				<div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
					<div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
						{errorMsg?.length ? (
							<Alert severity="error" variant="filled" className="mt-2">
								<ul className="space-y-1">
									{errorMsg?.map((item, i) => {
										return <li key={i}>{item}</li>;
									})}
								</ul>
							</Alert>
						) : (
							""
						)}
						<form
							className="space-y-6"
							onSubmit={handleSubmit((value: LoginForm) => {
								loginMutate(value, {
									onSuccess: (res: any) => {
										setToken(res?.data?.token);
										navigate("/admin");
									},
									onError: (res: any) => {
										const responseError = res?.response?.data?.error;
										setErrorMsg(
											typeof responseError != "string"
												? Object?.keys(responseError)?.map((k) => responseError?.[k]?.[0])
												: [responseError]
										);
									},
								});
							})}
						>
							<div>
								<div className="mt-2">
									<TextComponent
										title={"Email address"}
										id={`email`}
										register={register(`email`)}
										name={`email`}
										type={"email"}
										errors={(errors?.email?.message as string) || undefined}
									/>
								</div>
							</div>

							<div>
								<div className="mt-2">
									<TextComponent
										title={"Password"}
										id={`password`}
										register={register(`password`)}
										name={`password`}
										type={"password"}
										errors={(errors?.password?.message as string) || undefined}
									/>
								</div>
							</div>

							{/* <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-3 block text-sm leading-6 text-gray-900"
                  >
                    Remember me
                  </label>
                </div>

                <div className="text-sm leading-6">
                  <a
                    href="/"
                    className="font-medium text-blue-600 hover:text-blue-500"
                  >
                    Forgot password?
                  </a>
                </div>
              </div> */}

							<div>
								<button
									type="submit"
									className="flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-medium leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
								>
									Sign in
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};
