export const classNames = (...classes) => {
	return classes.filter(Boolean).join(" ");
};

export const handleApiError = (error) => {
	console.log(error);
};

export const formatDate = (date) => {
	return date.toISOString().split("T")[0];
};

export const flattenObject = (arr) =>
	arr.flatMap(({ attributes, ...rest }) =>
		attributes.map((o) => ({
			...rest,
			...o,
		}))
	);

// export const currencyFormatter = new Intl.NumberFormat('en-US', {
//   style: 'currency',
//   currency: 'IDR',
//   currencyDisplay: 'narrowSymbol'
// });

export const currencyFormatter = () => {
	const format = (number) => {
		const numberString = number.toString();

		const parts = numberString.split(".");

		const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

		const formattedNumber = parts.length === 2 ? `${integerPart}.${parts[1]}` : integerPart;

		return `Rp ${formattedNumber}`;
	};

	return { format };
};
