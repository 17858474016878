import { AlertDialogTrigger } from "src/components/ui/alert-dialog";
import { currencyFormatter } from "src/functions/global-functions";
import { _getDate } from "src/utils";

export const BuildDiscountVoucherTableSchema = ({ setShowModal, setDataUpdate }) => {
	return [
		{
			header: "Name",
			cell: (value) => value?.title,
		},
		{
			header: "Serial",
			cell: (value) => value?.serial,
		},
		{
			header: "Tipe",
			cell: (value) => value?.type,
		},
		{
			header: "Kuota",
			cell: (value) => value?.quota || "Unlimited",
		},
		{
			header: "Diskon",
			cell: (value) =>
				value?.type_value === "percentage"
					? `${value?.value}%`
					: value?.type_value === "none"
					? "-"
					: currencyFormatter().format(value?.value || 0),
		},
		{
			header: "Unik",
			cell: (value) => (
				<div>
					<input type="checkbox" checked={!!value?.is_unix} disabled />
				</div>
			),
		},
		{
			header: "Status",
			cell: (value) => <div>{value?.status_id ? "Aktif" : "Tidak Aktif"}</div>,
		},
		{
			header: "Tanggal",
			cell: (value) => (
				<div>
					{_getDate(value?.start_expired_at)} s/d {_getDate(value?.end_expired_at)}
				</div>
			),
		},
		{
			header: "Edit",
			cell: (value) => (
				<div>
					<button
						onClick={() => {
							setDataUpdate(value);
							setShowModal(true);
						}}
						className="mr-4 rounded bg-white px-2 py-1 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
					>
						Ubah
					</button>
					<AlertDialogTrigger
						onClick={() =>
							setDataUpdate({
								id: value.id,
								title: value.event_title,
							})
						}
						className="rounded bg-white px-2 py-1 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
					>
						Hapus
					</AlertDialogTrigger>
				</div>
			),
		},
	];
};
