import { Village } from "src/interface";
import ApiService from "./ApiService";
import { AppConfig } from "src/configs";

export async function apiGetVillage({
  sub_district_id,
  token,
}: {
  sub_district_id: number;
  token: string;
}): Promise<Village[]> {
  return ApiService.fetchData<Village[]>({
    url: `kelurahan/${sub_district_id}`,
    method: "get",
    headers: {
      mastertoken: AppConfig.masterToken,
      subdomain: AppConfig.subDomain,
      usertoken: token,
    },
  }).then((res: any) => res?.data?.data as Village[]);
}
