export const QuillConfig = {
	modules: {
		toolbar: [
			[
				"bold",
				"italic",
				"underline",
				"strike",
				{ color: [] },
				{ list: "ordered" },
				{ list: "bullet" },
				{ script: "sub" },
				{ script: "super" },
				{ indent: "-1" },
				{ indent: "+1" },
				{ header: [1, 2, 3, 4, 5, 6, false] },
				{ align: [] },
				"link",
				"image",
				"video",
				"clean",
			],
		],
	},
	theme: "snow",
	formats: [
		"header",
		"bold",
		"italic",
		"underline",
		"strike",
		"blockquote",
		"code-block",
		"list",
		"bullet",
		"script",
		"indent",
		"direction",
		"size",
		"color",
		"background",
		"font",
		"align",
		"clean",
		"image",
		"video",
	],
};

export const _quillRemoveFormats = (text: string) => {
	const pattern = /<([^>]+)><br><\/\1>/g;
	const result = text.replaceAll(pattern, "<br>");
	if (result === "<br>") return "";
	return result;
};
