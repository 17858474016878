import { Tab } from "@headlessui/react";
import { Fragment, useContext, useEffect, useState } from "react";
import { connectAPI } from "../../api/api";
import { ImageUpload } from "../../components/global/imageUpload";
import { IOrg } from "../../interface/generic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faLock } from "@fortawesome/pro-solid-svg-icons";
import { faCircleXmark, faPenToSquare } from "@fortawesome/pro-duotone-svg-icons";
import { TicketZone } from "../../components/admin/ticket-zone";
import { TicketType } from "../../components/admin/ticket-type";
import { serialize } from "object-to-formdata";
import { ErrorContext } from "../../functions/context";
import { Loading } from "src/components/web";
import { Loading as LoadingGlobal } from "src/components/global";
import { Link } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { apiDeletePartnerAccount, apiGetPartner, apiGetUserProfile } from "src/services";
import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
	AlertDialogTrigger,
} from "src/components/ui/alert-dialog";
import { toast } from "react-toastify";
import { Permission, PermissionNavigation } from "src/utils/permissions";

const subNavigation = [
	{
		name: "Organisasi",
		permissions: [2],
	},
	{
		name: "Akun",
		permissions: [2],
	},
	{
		name: "Kategori tiket dan tipe",
		permissions: [2, 4],
	},
];

function Setting() {
	let [orgData, setOrgData] = useState<IOrg>();
	let [isLoading, setIsLoading] = useState(false);
	let [currentOrgData, setCurrentOrgData] = useState({
		partner_name: "",
		about: "",
		setting_domain_name: "",
		logo_url: "",
		banner_url: "",
	});

	const [currTab, setCurrTab] = useState(null);
	const [selectedAccount, setSelectedAccount] = useState(null);

	const errorContext = useContext(ErrorContext);

	const { data: userDetails, isLoading: loadingUser } = useQuery<any>([`partner`, `profile`], apiGetUserProfile);

	const {
		data: partnerDetails,
		isLoading: loadingPartner,
		refetch: refetchPartner,
	} = useQuery<any>([`partner`, `website`], apiGetPartner);

	const updatePartnerDetails = () => {
		const formData = serialize(currentOrgData);
		setIsLoading(true);
		const loginToken = localStorage.getItem("token");
		connectAPI(`partner/website`, formData, "post", {
			authorization: `Bearer ${loginToken}`,
		}).then((res) => {
			if (res.status === 200) {
				const data = res.data.data;
				setIsLoading(false);
				setCurrentOrgData({
					...currentOrgData,
					partner_name: data?.partner_name,
					about: data?.about,
					setting_domain_name: data?.setting_domain_name,
					logo_url: data?.logo_url,
				});
			} else {
				setIsLoading(false);
				refetchPartner();
				errorContext.setGlobalError("Update tidak berhasil, ada masalah");
			}
		});
	};

	const { mutate: deleteAccount, isLoading: loadingDeleteAccount } = useMutation(
		async (id: string) => await apiDeletePartnerAccount({ id })
	);

	useEffect(() => {
		const data = partnerDetails.data.data;
		setOrgData(data);
		setCurrentOrgData({
			...currentOrgData,
			partner_name: data?.partner_name,
			about: data?.about,
			setting_domain_name: data?.setting_domain_name,
			logo_url: data?.logo_url,
			banner_url: data?.banner_url,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [partnerDetails]);

	return loadingUser ? (
		<LoadingGlobal />
	) : (
		<>
			<AlertDialog>
				<AlertDialogContent>
					<AlertDialogHeader>
						<AlertDialogTitle className="font-medium">Konfirmasi</AlertDialogTitle>
						<AlertDialogDescription>
							Mohon konfirmasi hapus akun{" "}
							<span className="font-medium">
								{selectedAccount?.firstname} {selectedAccount?.lastname}
							</span>
						</AlertDialogDescription>
					</AlertDialogHeader>
					<AlertDialogFooter>
						<AlertDialogCancel className="rounded-md bg-gray-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600">
							Batal
						</AlertDialogCancel>
						<AlertDialogAction
							disabled={loadingDeleteAccount}
							className="rounded-md bg-red-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
							onClick={() =>
								deleteAccount(selectedAccount?.id, {
									onSuccess: (_) => {
										toast.success("Akun berhasil dihapus");
										refetchPartner();
									},
								})
							}
						>
							Hapus
						</AlertDialogAction>
					</AlertDialogFooter>
				</AlertDialogContent>
				<div>
					<div className="sm:flex sm:items-center">
						<div className="sm:flex-auto">
							<h1 className="text-3xl text-gray-900 font-medium">Pengaturan</h1>
							<p className="mt-2 text-sm text-gray-700">Pengaturan akun dan organisasi</p>
						</div>
						{currTab === "Akun" ? (
							<div>
								<Link
									to={"/admin/account"}
									className="block rounded-md bg-indigo-600 py-1.5 px-3 text-center text-sm font-medium leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
								>
									Tambah akun
								</Link>
							</div>
						) : (
							""
						)}
					</div>
					<div className="mt-8 flow-root">
						<Tab.Group>
							<Tab.List className="border-b border-slate-300 flex">
								{PermissionNavigation(subNavigation, userDetails?.data?.data?.roles).map((item) => (
									<Tab as={Fragment} key={item.name}>
										{({ selected }) => (
											<button
												className={`${
													selected
														? "border-b-2 border-blue-600 font-medium text-slate-900"
														: "border-b-2 border-white "
												} mr-10 py-2 text-slate-500`}
												onClick={() => setCurrTab(item.name)}
											>
												{item.name}
											</button>
										)}
									</Tab>
								))}
							</Tab.List>
							<Tab.Panels>
								{Permission(userDetails?.data?.data?.roles, [2]) ? (
									<>
										<Tab.Panel>
											{loadingPartner ? (
												<Loading className="mt-5" />
											) : (
												<div className="mt-10 space-y-8 border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:pb-0">
													<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
														<label
															htmlFor="org_logo"
															className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
														>
															Logo
														</label>
														<div className="mt-2 sm:col-span-2 sm:mt-0">
															<ImageUpload
																setCollectionItem={setCurrentOrgData}
																type="logo"
																collectionItem={currentOrgData}
																currentImage={currentOrgData.logo_url}
															/>
															<p className="mt-5 text-sm font-medium mb-3 uppercase">
																Galeri gambar
															</p>

															<div className="grid grid-cols-1 gap-4  p-4 bg-slate-100 w-48">
																<img src={orgData?.logo_url} alt="" />
															</div>
														</div>
													</div>

													<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
														<label
															htmlFor="org_logo"
															className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
														>
															Banner
														</label>
														<div className="mt-2 sm:col-span-2 sm:mt-0">
															<ImageUpload
																setCollectionItem={setCurrentOrgData}
																type="banner_url"
																collectionItem={currentOrgData}
																currentImage={currentOrgData.banner_url}
															/>
															<p className="mt-5 text-sm font-medium mb-3 uppercase">
																Galeri gambar
															</p>

															<div className="grid grid-cols-1 gap-4  p-4 bg-slate-100 w-48">
																<img src={orgData?.banner_url} alt="" />
															</div>
														</div>
													</div>

													<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
														<label
															htmlFor="org_name"
															className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
														>
															Nama organisasi
														</label>
														<div className="mt-2 sm:col-span-2 sm:mt-0">
															<input
																id="org_name"
																name="org_name"
																type="text"
																autoComplete="org_name"
																value={currentOrgData?.partner_name}
																onChange={(e) =>
																	setCurrentOrgData({
																		...currentOrgData,
																		partner_name: e.target.value,
																	})
																}
																className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
															/>
														</div>
													</div>
													<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
														<label
															htmlFor="org_domain"
															className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
														>
															Domain
														</label>
														<div className="mt-2 sm:col-span-2 sm:mt-0">
															<div className="relative">
																<input
																	id="org_domain"
																	name="org_domain"
																	type="text"
																	autoComplete="org_domain"
																	value={currentOrgData?.setting_domain_name}
																	disabled
																	className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled: bg-gray-100 disabled:text-gray-500"
																/>
																<FontAwesomeIcon
																	className="absolute right-3 top-3 text-gray-600 text-sm"
																	aria-hidden="true"
																	icon={faLock as IconProp}
																/>
															</div>
															<p className="mt-3 text-sm leading-6 text-gray-400">
																Untuk mengganti nama domain, silakan hubungi customer
																service.
															</p>
														</div>
													</div>
													<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
														<label
															htmlFor="about_org"
															className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
														>
															Tentang organisasi
														</label>
														<div className="mt-2 sm:col-span-2 sm:mt-0">
															<textarea
																id="about_org"
																name="about_org"
																rows={5}
																className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
																defaultValue={currentOrgData?.about}
																onChange={(e) =>
																	setCurrentOrgData({
																		...currentOrgData,
																		about: e.target.value,
																	})
																}
															/>
														</div>
													</div>
													<div className="bg-slate-100 p-5 items-center flex justify-center">
														<button
															type="button"
															className="rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
															onClick={(e) => {
																updatePartnerDetails();
															}}
														>
															Perbaharui data
														</button>
													</div>
												</div>
											)}
										</Tab.Panel>{" "}
										<Tab.Panel>
											{isLoading || loadingDeleteAccount ? (
												<Loading className="mt-5" />
											) : (
												<div className="mt-10">
													<ul className="grid  gap-6 grid-cols-12">
														{orgData?.users.map((person, index) => (
															<li
																key={person.email}
																className="relative col-span-12 md:col-span-6 lg:col-span-3 flex flex-col rounded-lg text-center border shadow-sm bg-white"
															>
																{index > 0 && (
																	<AlertDialogTrigger
																		onClick={(e: any) => {
																			e?.stopPropagation();
																			setSelectedAccount(person);
																		}}
																		className="absolute right-2 top-2 rounded-full w-5 h-5 flex items-center justify-center"
																	>
																		<FontAwesomeIcon
																			className="text-red-500 w-full h-full"
																			icon={faCircleXmark as IconProp}
																		/>
																	</AlertDialogTrigger>
																)}
																<div className="flex flex-1 flex-col p-8">
																	{person.picture_path ? (
																		<img
																			className="mx-auto h-32 w-32 flex-shrink-0 rounded-full"
																			src={person.picture_path}
																			alt=""
																		/>
																	) : (
																		<span className="mx-auto inline-block h-20 w-20 overflow-hidden rounded-full bg-gray-100">
																			<svg
																				className="h-full w-full text-gray-300"
																				fill="currentColor"
																				viewBox="0 0 24 24"
																			>
																				<path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
																			</svg>
																		</span>
																	)}
																	<h3 className="mt-6 text-sm font-medium text-gray-900">
																		{person.firstname}
																	</h3>
																	<dl className="mt-1 flex flex-grow flex-col justify-between">
																		<dt className="sr-only">Title</dt>
																		<dd className="text-sm text-gray-500">
																			{person.title}
																		</dd>
																		<dt className="sr-only">Role</dt>
																		<dd className="mt-3">
																			<span className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
																				{person.roles[0].title}
																			</span>
																		</dd>
																	</dl>
																</div>
																<div>
																	<div className="-mt-px flex divide-x divide-gray-200">
																		<div className="-ml-px flex w-0 flex-1">
																			<Link
																				to={`/admin/account/${person.id}`}
																				className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-medium text-gray-900"
																			>
																				<FontAwesomeIcon
																					className=" text-gray-600 text-sm"
																					aria-hidden="true"
																					icon={faPenToSquare as IconProp}
																				/>
																				Edit
																			</Link>
																		</div>
																	</div>
																</div>
															</li>
														))}
													</ul>
												</div>
											)}
										</Tab.Panel>
									</>
								) : (
									""
								)}
								{Permission(userDetails?.data?.data?.roles, [2, 4]) ? (
									<Tab.Panel>
										<div className="mt-10">
											<div className="grid grid-cols-1 md:grid-cols-2 gap-4">
												<div>
													<TicketZone />
												</div>
												<div>
													<TicketType />
												</div>
											</div>
										</div>
									</Tab.Panel>
								) : (
									""
								)}
							</Tab.Panels>
						</Tab.Group>
					</div>
				</div>
			</AlertDialog>
		</>
	);
}

export default Setting;
