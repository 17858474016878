import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCircleXmark } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Disclosure, Transition } from "@headlessui/react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "react-toastify";
import { TicketCategoryToggle } from "src/components/admin/ticket-category-toggle";
import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
	AlertDialogTrigger,
} from "src/components/ui/alert-dialog";
import { currencyFormatter } from "src/functions/global-functions";
import { Event } from "src/interface";
import { EventTicketPopup } from "src/pages/admin/event-ticket-popup";
import { apiDeleteTicket, apiGetTicketType, apiGetZone, apiUpdateStatusTicket } from "src/services";

interface EventTicketFormProps {
	data: Event;
	isLoading: boolean;
	refetch?: <TPageData>(
		options?: RefetchOptions & RefetchQueryFilters<TPageData>
	) => Promise<QueryObserverResult<Event, unknown>>;
}

export const EventTicketForm = ({ data, refetch }: EventTicketFormProps) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [selectedTicket, setSelectedTicket] = useState<any>({});
	const [selectedDeletedTicket, setSelectedDeletedTicket] = useState<any>({});

	const { data: zone } = useQuery(["ticket", `zone`], apiGetZone);
	const { data: ticketType } = useQuery(["ticket", "category"], apiGetTicketType);

	const { mutate: changeStatusTicket } = useMutation(
		async ({ id, data }: { id: number; data: { status_id: number } }) => await apiUpdateStatusTicket({ id, data })
	);

	const { mutate: deleteTicketById, isLoading: loadingDeleteTicket } = useMutation(
		async (id: string) => await apiDeleteTicket({ id })
	);

	return (
		<AlertDialog>
			<AlertDialogContent>
				<AlertDialogHeader>
					<AlertDialogTitle className="font-medium">Konfirmasi</AlertDialogTitle>
					<AlertDialogDescription>
						Mohon konfirmasi hapus ticket{" "}
						<span className="font-medium">
							{selectedDeletedTicket?.ticket?.zone?.title} -{" "}
							{selectedDeletedTicket?.tix?.ticket_type?.title}
						</span>
					</AlertDialogDescription>
				</AlertDialogHeader>
				<AlertDialogFooter>
					<AlertDialogCancel className="rounded-md bg-gray-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600">
						Batal
					</AlertDialogCancel>
					<AlertDialogAction
						disabled={loadingDeleteTicket}
						className="rounded-md bg-red-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
						onClick={() =>
							deleteTicketById(selectedDeletedTicket?.tix?.id, {
								onSuccess: (_) => {
									toast.success("Tiket berhasil dihapus");
									refetch();
								},
							})
						}
					>
						Hapus
					</AlertDialogAction>
				</AlertDialogFooter>
			</AlertDialogContent>
			<div className="mt-10 flex items-center justify-between border-b pb-3">
				<h2 className="text-2xl font-medium">Tiket</h2>
				<button
					type="button"
					className="block rounded-md bg-indigo-600 py-1.5 px-3 text-center text-sm font-medium leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
					onClick={(e) => {
						setIsOpen(true);
						setSelectedTicket(null);
					}}
				>
					Tambah tiket
				</button>
			</div>
			<div className="grid grid-cols-1 gap-4 mt-6">
				<div className="rounded-md bg-blue-50 p-4 mb-4">
					<div className="flex">
						<div className="flex-shrink-0">
							<InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
						</div>
						<div className="ml-3 flex-1 md:flex md:justify-between">
							<p className="text-sm text-blue-700">
								Tips: Gunakan status aktif/non-aktif jika hanya ingin mengaktifkan 1 kategori tiket
								(contoh: Presale)
							</p>
						</div>
					</div>
				</div>
				{data?.tickets?.map((ticket, index) => {
					return (
						<div className="mb-8 rounded-md" key={`ticket-${ticket.id}-${index}`}>
							<Disclosure>
								<Disclosure.Button className="w-full bg-slate-700  rounded-md">
									<div className="p-4 grid grid-cols-2">
										<div className="text-left">
											<p className="text-lg font-medium text-white">
												{ticket?.zone?.title}
												<span className="text-slate-700 bg-white rounded-full px-2 py-1 text-sm ml-2">
													{ticket?.event_tickets?.reduce((prev, curr) => {
														return curr.qty + prev;
													}, 0)}
												</span>
											</p>
											<p className="text-white text-left">{ticket?.zone?.description}</p>
										</div>
										<div className="flex pt-1 text-white justify-end">
											<TicketCategoryToggle
												active={ticket.status_id}
												onChange={(e) =>
													changeStatusTicket(
														{ id: ticket?.id, data: { status_id: +e } },
														{
															onSuccess: (res) => {
																refetch();
															},
														}
													)
												}
											/>
										</div>
									</div>
								</Disclosure.Button>
								<Transition
									enter="transition duration-100 ease-out"
									enterFrom="transform scale-95 opacity-0"
									enterTo="transform scale-100 opacity-100"
									leave="transition duration-75 ease-out"
									leaveFrom="transform scale-100 opacity-100"
									leaveTo="transform scale-95 opacity-0"
								>
									<Disclosure.Panel className="p-4 bg-slate-100">
										<div className="grid lg:grid-cols-3 gap-5 grid-cols-1">
											{ticket?.event_tickets.length === 0 && "Belum ada tiket"}
											{ticket?.event_tickets?.map((tix, i) => {
												return (
													<div
														key={i}
														className="cursor-pointer"
														onClick={() => {
															setSelectedTicket(tix);
															setIsOpen(true);
														}}
													>
														<div className=" bg-blue-100 rounded-3xl">
															<div className="flex flex-col">
																<div className="bg-white relative rounded-2xl p-4 m-3">
																	<AlertDialogTrigger
																		onClick={(e: any) => {
																			e?.stopPropagation();
																			setSelectedDeletedTicket({ tix, ticket });
																		}}
																		className="absolute right-2 top-2 rounded-full w-5 h-5 flex items-center justify-center"
																	>
																		<FontAwesomeIcon
																			className="text-red-500 w-full h-full"
																			aria-hidden="true"
																			icon={faCircleXmark as IconProp}
																		/>
																	</AlertDialogTrigger>

																	<div className="flex-auto justify-evenly">
																		<div className="flex items-center justify-center">
																			<h2 className="font-medium text-xl text-center">
																				{tix?.ticket_type?.title}
																			</h2>
																		</div>
																		<div className="border-dashed border-b-2 my-5">
																			<div className="absolute rounded-full w-5 h-5 bg-blue-100 -mt-2 -left-2"></div>
																			<div className="absolute rounded-full w-5 h-5 bg-blue-100 -mt-2 -right-2"></div>
																		</div>
																		<div className="flex items-center justify-center flex-col my-1">
																			<h2 className="font-medium text-xl">
																				{tix?.price
																					? currencyFormatter().format(
																							tix?.price
																					  )
																					: "GRATIS"}
																			</h2>
																			<p className="text-slate-500">
																				{tix?.ticket_type?.description}
																			</p>
																		</div>
																		<div className="border-dashed border-b-2 my-5">
																			<div className="absolute rounded-full w-5 h-5 bg-blue-100 -mt-2 -left-2"></div>
																			<div className="absolute rounded-full w-5 h-5 bg-blue-100 -mt-2 -right-2"></div>
																		</div>
																		<div className="flex items-center justify-center flex-col my-1">
																			<h2 className="font-medium text-xl">
																				<span className="text-slate-300">
																					QTY{" "}
																				</span>
																				{tix?.qty}
																			</h2>
																			<p className="text-slate-500 bg-slate-100 rounded-full px-2 mt-4 truncate w-full text-center">
																				{tix?.unique_id}
																			</p>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												);
											})}
										</div>
									</Disclosure.Panel>
								</Transition>
							</Disclosure>
						</div>
					);
				})}

				{isOpen ? (
					<EventTicketPopup
						isOpenFunc={setIsOpen}
						isOpen={isOpen}
						zone={zone}
						ticketType={ticketType}
						eventId={data.id}
						defaultData={selectedTicket}
						refetch={refetch}
						data={data}
					/>
				) : (
					""
				)}
			</div>
		</AlertDialog>
	);
};
