import { AppConfig } from "src/configs";
import ApiService from "./ApiService";
import { Province } from "src/interface";

export async function apiGetProvince({ token }): Promise<Province[]> {
  return ApiService.fetchData<Province[]>({
    url: "provinsi",
    method: "get",
    headers: {
      mastertoken: AppConfig.masterToken,
      subdomain: AppConfig.subDomain,
      usertoken: token,
    },
  }).then((res: any) => res?.data?.data as Province[]);
}
