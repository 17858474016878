export const useToken = (tokenName = "webtoken") => {
	const setToken = (tokenId: string) => {
		localStorage.setItem(tokenName, tokenId);
	};

	const removeToken = () => {
		localStorage.removeItem(tokenName);
	};

	const token = localStorage.getItem(tokenName);

	return { setToken, removeToken, token };
};
