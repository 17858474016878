interface ContentSectionProps {
	data: string;
}

export const ContentSection = ({ data }: ContentSectionProps) => {
	return (
		<div className="max-w-screen-xl mx-auto">
			<div className="ql-editor py-20 px-0" dangerouslySetInnerHTML={{ __html: data }}></div>
		</div>
	);
};
