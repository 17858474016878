import { Link } from "react-router-dom";

export const OrderFailed = () => {
  return (
    <div className="bg-gray-100 h-[90vh] flex items-center justify-center">
      <div className="bg-white p-6 md:mx-auto">
        <svg
          className="fill-red-600 w-16 h-16 mx-auto my-6"
          width="24px"
          height="24px"
          viewBox="0 0 500 500"
        >
          <title>fail</title>
          <path d="M264 456Q210 456 164 429 118 402 91 356 64 310 64 256 64 202 91 156 118 110 164 83 210 56 264 56 318 56 364 83 410 110 437 156 464 202 464 256 464 310 437 356 410 402 364 429 318 456 264 456ZM264 288L328 352 360 320 296 256 360 192 328 160 264 224 200 160 168 192 232 256 168 320 200 352 264 288Z" />
        </svg>
        <div className="text-center">
          <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">
            Pembayaran Gagal!
          </h3>
          <p className="text-gray-600 my-2">
            Mohon melakukan pembayaran ulang melalui link yang ada di whatsapp.
          </p>
          <div className="py-10 text-center">
            <Link
              to="/"
              className="px-12 bg-indigo-600 hover:bg-indigo-500 text-white font-semibold py-3"
            >
              Kembali ke beranda
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
