import { Loading, TicketHolder } from "src/components/web";
import { Event, Order, Province } from "src/interface";

import { useQuery } from "@tanstack/react-query";
import { apiGetProvince } from "src/services/ProvinceService";
import { useToken } from "src/utils";
import { ChevronLeft } from "lucide-react";

interface TicketingDetailFormProps {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setFormState: React.Dispatch<React.SetStateAction<Order>>;
  data: Event;
  formState: Order;
}

export const TicketingDetailForm = ({
  data,
  formState,
  setCurrentStep,
  setFormState,
}: TicketingDetailFormProps) => {
  const { token } = useToken();

  const { data: province, isLoading } = useQuery<Province[]>({
    queryKey: ["province"],
    queryFn: async () => await apiGetProvince({ token }),
  });

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <button
        type="button"
        onClick={() => {
          setCurrentStep(1);
        }}
        className="flex items-center justify-center rounded bg-white px-2 py-1 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 mb-3"
      >
        <ChevronLeft /> Kembali
      </button>
      <TicketHolder
        setCurrentStep={setCurrentStep}
        data={data}
        formState={formState}
        province={province}
        token={token}
        setFormState={setFormState}
      />
    </>
  );
};
