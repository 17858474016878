import { useState } from "react";
import { BaseTable } from "src/components/admin/Base-table";
import { Pagination } from "src/components/admin/Pagination";
import { BuildStatisticTransactionTableSchema } from "src/configs/table";
import { PopupTransactionDetail } from "./PopupTransactionDetail";

export const TableAllBuyerTransaction = ({
	data,
	isLoading,
	setPage,
}: {
	data: any;
	isLoading: boolean;
	setPage: any;
}) => {
	const [transactionData, setTransactionData] = useState(null);

	const table = BuildStatisticTransactionTableSchema({ setTransactionData });

	return (
		<>
			<div className="font-medium text-2xl">Semua Transaksi Pembelian</div>

			<div className="rounded-md bg-slate-100 mt-2">
				<BaseTable datas={data?.data} column={table} loading={isLoading} />
				<Pagination {...data} setPage={setPage} />
			</div>

			{transactionData ? <PopupTransactionDetail data={transactionData} setData={setTransactionData} /> : ""}
		</>
	);
};
