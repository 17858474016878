import { useMutation, useQuery } from "@tanstack/react-query";
import { Loading } from "src/components/web";
import { apiDeleteDiscountVoucher, apiGetDiscountVoucher } from "src/services/AccountingService";
import { ModalDiscountVoucher } from "./ModalDiscountVoucher";
import { useState } from "react";
import { BaseTable } from "src/components/admin/Base-table";
import { BuildDiscountVoucherTableSchema } from "src/configs/table/discountVoucherTable.schema";
import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
} from "src/components/ui/alert-dialog";
import { toast } from "react-toastify";

export const DiscountVoucher = () => {
	const [showModal, setShowModal] = useState(false);
	const [dataUpdate, setDataUpdate] = useState(null);

	const table = BuildDiscountVoucherTableSchema({ setShowModal, setDataUpdate });

	const { data, isLoading, refetch } = useQuery<any>([`partner`, `disount`, `voucher`], apiGetDiscountVoucher);

	const { mutate: deleteDiscountVoucher, isLoading: loadingDelete } = useMutation(async (id) =>
		apiDeleteDiscountVoucher({ id })
	);

	return isLoading ? (
		<Loading className="mt-5" />
	) : (
		<>
			<div className="mt-5 space-y-8 border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:pb-0">
				<div>
					<div className="px-4 sm:px-0 flex justify-between items-center">
						<div>
							<h3 className="text-lg font-medium leading-7 text-gray-900">Diskon & Kupon</h3>
							<p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Diskon dan kupon</p>
						</div>
						<div>
							<button
								type="button"
								onClick={() => {
									setShowModal(true);
								}}
								className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
							>
								Tambah Diskon & Kupon
							</button>
						</div>
					</div>
					<AlertDialog>
						<AlertDialogContent>
							<AlertDialogHeader>
								<AlertDialogTitle className="font-medium">Konfirmasi</AlertDialogTitle>
								<AlertDialogDescription>
									Mohon konfirmasi hapus diskon & kupon
									<span className="font-medium">{dataUpdate?.title}</span>
								</AlertDialogDescription>
							</AlertDialogHeader>
							<AlertDialogFooter>
								<AlertDialogCancel className="rounded-md bg-gray-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600">
									Batal
								</AlertDialogCancel>
								<AlertDialogAction
									disabled={loadingDelete}
									className="rounded-md bg-red-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
									onClick={() =>
										deleteDiscountVoucher(dataUpdate.id, {
											onSuccess: (_) => {
												toast.success("Berhasil menghapus diskon & kupon");
												refetch();
											},
											onError: (error: any) => {
												toast.error(
													error?.response?.data?.message || "Gagal menghapus diskon & kupon"
												);
											},
										})
									}
								>
									Hapus
								</AlertDialogAction>
							</AlertDialogFooter>
						</AlertDialogContent>
						<div className="mt-2 flow-root">
							<div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
								<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
									<div className="relative">
										<div className="rounded-md bg-slate-100">
											<BaseTable datas={data?.data?.data} column={table} loading={isLoading} />
										</div>
									</div>
								</div>
							</div>
						</div>
					</AlertDialog>
				</div>
			</div>

			{showModal && (
				<ModalDiscountVoucher
					show={showModal}
					data={dataUpdate}
					refetch={refetch}
					onClose={() => {
						setShowModal(false);
						setDataUpdate(null);
					}}
				/>
			)}
		</>
	);
};
