import { useEffect } from "react";
import { apiGetMasterData } from "src/services";
import { MasterData } from "src/interface";
import { NewsListing, EventCarouselListing, Banner, Loading, Sponsors } from "src/components/web";
import { useToken } from "src/utils";

import { useQuery } from "@tanstack/react-query";
import { ContentSection } from "src/components/web/ContentSection";

function LandingPage() {
	const { isLoading, data } = useQuery<MasterData>({
		queryKey: ["master-data"],
		queryFn: apiGetMasterData,
	});

	const { setToken } = useToken();

	useEffect(() => {
		if (data?.token) {
			setToken(data.token);
		}
	}, [data, setToken]);

	return isLoading ? (
		<div className="text-center pt-20">
			<Loading title="Loading" />
		</div>
	) : (
		<div className="px-5 lg:px-0">
			<div>
				<Banner image={data?.banner_url} />
			</div>

			<div className={`${data?.content ? "bg-slate-100 mb-24" : "mb-10"}`}>
				<ContentSection data={data?.content} />
			</div>

			{data?.events?.filter((event) => event?.status_id)?.length ? (
				<div className="mb-24">
					<EventCarouselListing
						events={data?.events?.filter((event) => event?.status_id)}
						loading={isLoading}
					/>
				</div>
			) : (
				""
			)}
			<div className="mb-24">
				<NewsListing news={data?.news} loading={isLoading} />
			</div>

			{data.sponsors.length > 0 && (
				<div className="bg-slate-100">
					<div className="max-w-screen-xl mx-auto py-16">
						<Sponsors data={data.sponsors} />
					</div>
				</div>
			)}
		</div>
	);
}

export default LandingPage;
