import { TicketRequest, Zone } from "src/interface";
import ApiService from "./ApiService";
import { AppConfig } from "src/configs";

export async function apiGetZone(): Promise<Zone[]> {
  return ApiService.fetchData({
    url: `partner/zone`,
    method: "get",
  }).then((res: any) => res?.data?.data as Zone[]);
}

export async function apiGetOrderStatus() {
  return ApiService.fetchData({
    url: `partner/order_status`,
    method: "get",
  }).then((res: any) => res?.data?.data as any[]);
}

export async function apiGetTicketStatus() {
  return ApiService.fetchData({
    url: `partner/ticket_status`,
    method: "get",
  }).then((res: any) => res?.data?.data as any[]);
}

export async function apiGetPaymentMethod() {
  return ApiService.fetchData({
    url: `partner/payment_method`,
    method: "get",
  }).then((res: any) => res?.data?.data as any[]);
}

export async function apiGetTicketType(): Promise<Zone[]> {
  return ApiService.fetchData({
    url: "partner/type",
    method: "get",
  }).then((res: any) => res?.data?.data as Zone[]);
}

export async function apiPostTicket({
  id,
  data,
}: {
  id: string;
  data: TicketRequest;
}) {
  return ApiService.fetchData({
    url: `partner/event/ticket/${id}`,
    method: "post",
    data,
  });
}

export async function apiPutTicket({
  id,
  data,
}: {
  id: string;
  data: TicketRequest;
}) {
  return ApiService.fetchData({
    url: `partner/event/ticket/type/${id}`,
    method: "put",
    data,
  });
}

export async function apiUpdateStatusTicket({
  id,
  data,
}: {
  id: number;
  data: { status_id: number };
}) {
  return ApiService.fetchData({
    url: `partner/event/category/${id}`,
    method: "put",
    data,
  });
}

export async function apiPutActivateTicket({
  data,
  id,
  token,
}: {
  data: any;
  id: string;
  token: string;
}) {
  return ApiService.fetchData({
    url: `activation/ticket/${id}`,
    method: "put",
    data,
    headers: {
      mastertoken: AppConfig.masterToken,
      subdomain: AppConfig.subDomain,
      usertoken: token,
    },
  }).then((res: any) => res?.data?.data as any);
}

export async function apiGetTicketPortal() {
  return ApiService.fetchData({
    url: "partner/check/in",
    method: "get",
  }).then((res: any) => res?.data?.data as any);
}

export async function apiDeleteTicket({ id }: { id: string }) {
  return ApiService.fetchData({
    url: `partner/event/ticket/type/${id}`,
    method: "delete",
  });
}
