import { useQuery } from "@tanstack/react-query";
import { Loading } from "src/components/web";
import { apiGetUserProfile } from "src/services";
import { _getDate, _getTime, useToken } from "src/utils";

function Dashboard() {
	const { token } = useToken("token");
	const { data: userDetails, isLoading: loadingUser } = useQuery<any>([`partner`, `profile`], apiGetUserProfile, {
		enabled: !!token,
	});

	return (
		<>
			{loadingUser ? (
				<Loading />
			) : (
				<div className="px-5 lg:px-0">
					<div className=" pb-5 mb-5 flex justify-between items-center">
						<div>
							<h1 className="text-3xl font-medium mb-1 ">
								{userDetails?.data?.data?.firstname}, selamat datang di Dashboard
							</h1>
							<p className="text-slate-400">
								Login terakhir anda {_getDate(userDetails?.data?.data?.updated_at, "")}{" "}
								{_getTime(userDetails?.updated_at, "")}
							</p>
						</div>
						<img src={userDetails?.partner?.logo_url} alt="" className="w-12" />
					</div>
					<div className="flex">
						<div className="w-96 rounded-md p-4 relative bg-slate-50 mr-10">
							<img className="mb-5" src="/person_desk.png" alt=""></img>
							<p className="font-medium mb-2">{userDetails?.data?.data?.partner?.partner_name}</p>
							<p className="line-clamp-4">{userDetails?.data?.data?.partner?.about}</p>
						</div>
						<div className="h-full">
							<div className="w-48 h-48 rounded-md p-4 relative bg-slate-50 mb-10">
								<span className="text-8xl text-slate-800">
									{userDetails?.data?.data?.partner?.events?.length}
								</span>
								<p className="absolute bottom-5 text-slate-800">Event terdaftar</p>
							</div>
							<div className="w-48 h-48 rounded-md p-4 relative bg-slate-50">
								<span className="text-8xl text-slate-800">
									{userDetails?.data?.data?.partner?.news?.length}
								</span>
								<p className="absolute bottom-5 text-slate-800">Berita di website</p>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
}

export default Dashboard;
