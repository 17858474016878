import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery } from "@tanstack/react-query";
import { serialize } from "object-to-formdata";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Loading } from "src/components/web";
import { Loading as GlobalLoading } from "src/components/global";
import { User } from "src/interface";
import { MobileNumberComponent, TextComponent } from "src/partials/ticketing/detail/DetailInputComponent";
import { apiGetPartner, apiGetUserProfile, apiPostPartnerAccount, apiPutPartnerAccount } from "src/services";
import { apiGetRole } from "src/services/RoleService";
import { z } from "zod";
import { Permission } from "src/utils/permissions";

const AccountSchema = z.object({
	firstname: z.string().nonempty("Nama depan wajib diisi"),
	lastname: z.string().nonempty("Nama belakang wajib diisi"),
	title: z.string().nonempty("Titel wajib diisi"),
	mobile_number: z.string().refine((value) => /^\d{10,15}$/.test(value), {
		message: "Format nomor handphone tidak sesuai",
	}),
	email: z.string().email("Format email salah").nonempty("Email wajib diisi"),
	picture_path: z.any(),
	role: z.string().nonempty("Role wajib diisi"),
});

const AccountCreatedSchema = AccountSchema.extend({
	password: z
		.string()
		.nonempty("Password wajib diisi")
		.min(8, "Password harus mengandung minimal 8 karakter")
		.refine((value) => /[A-Z]/.test(value), {
			message: "Password harus mengandung minimal 1 karakter huruf besar",
		})
		.refine((value) => /[a-z]/.test(value), {
			message: "Password harus mengandung minimal 1 karakter huruf kecil",
		})
		.refine((value) => /[0-9]/.test(value), {
			message: "Password harus mengandung minimal 1 angka",
		})
		.refine((value) => /[^A-Za-z0-9]/.test(value), {
			message: "Password harus mengandung minimal 1 simbol",
		}),
	password_confirmation: z
		.string()
		.nonempty("Konfirmasi password wajib diisi")
		.min(8, "Password harus mengandung minimal 8 karakter")
		.refine((value) => /[A-Z]/.test(value), {
			message: "Konfirmasi password harus mengandung minimal 1 karakter huruf besar",
		})
		.refine((value) => /[a-z]/.test(value), {
			message: "Konfirmasi password harus mengandung minimal 1 karakter huruf kecil",
		})
		.refine((value) => /[0-9]/.test(value), {
			message: "Konfirmasi password harus mengandung minimal 1 angka",
		})
		.refine((value) => /[^A-Za-z0-9]/.test(value), {
			message: "Konfirmasi password harus mengandung minimal 1 simbol",
		}),
}).refine((data) => data.password === data.password_confirmation, {
	message: "Password tidak sama dengan konfirmasi password",
	path: ["confirm"],
});

type AccountForm = z.infer<typeof AccountSchema>;
type AccountCreatedForm = z.infer<typeof AccountCreatedSchema>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-redeclare
export const AccountForm = ({ data, refetch }: { data?: User; refetch?: any }) => {
	const defaultValue: AccountCreatedForm = {
		firstname: data?.firstname || "",
		lastname: data?.lastname || "",
		title: data?.title || "Mr.",
		mobile_number: data?.mobile_number || "",
		email: data?.email || "",
		picture_path: data?.picture_path || "",
		password: "",
		password_confirmation: "",
		role: data?.roles?.[0]?.id?.toString() || "",
	};

	const {
		register,
		handleSubmit,
		control,
		setValue,
		getValues,
		formState: { errors },
	} = useForm<AccountCreatedForm>({
		resolver: zodResolver(data?.id ? AccountSchema : AccountCreatedSchema),
		defaultValues: defaultValue,
	});

	const picture_path = useWatch({
		control,
		name: "picture_path",
	});

	const { mutate: putUser, isLoading: loadingPutUser } = useMutation(async ({ id, data }: { id: string; data: any }) => await apiPutPartnerAccount({ id, data }));

	const { mutate: postUser, isLoading: loadingPostUser } = useMutation(async ({ data }: { data: any }) => await apiPostPartnerAccount({ data }));

	const { refetch: refetchPartner } = useQuery<any>([`partner`, `website`], apiGetPartner);

	const { refetch: refetchUser } = useQuery<any>([`partner`, `profile`], apiGetUserProfile);

	const { data: userDetails, isLoading: loadingUser } = useQuery<any>([`partner`, `profile`], apiGetUserProfile);

	const { data: role, isLoading: loadingRole } = useQuery<any>([`partner`, `user-role`], apiGetRole);

	const navigate = useNavigate();

	return (
		<>
			{loadingRole || loadingUser ? (
				<GlobalLoading />
			) : (
				<form
					onSubmit={handleSubmit((value: AccountForm) => {
						const formData = serialize({
							...value,
							picture_path: typeof value?.picture_path === "object" ? value?.picture_path?.[0] : value?.picture_path,
						});
						if (data?.id) {
							putUser(
								{ id: data?.id, data: formData },
								{
									onSuccess: (res) => {
										toast.success("Ubah akun berhasil");
										refetchPartner();
										refetchUser();
										refetch?.();
									},
									onError: (res) => {
										toast.error("Terjadi kesalahan");
									},
								}
							);
						} else {
							postUser(
								{ data: formData },
								{
									onSuccess: (res) => {
										toast.success("Tambah akun berhasil");
										refetchPartner();
										refetchUser();
										navigate("/admin/setting");
									},
									onError: (res: any) => {
										const errorMessage = res?.response?.data?.error?.password?.[0];
										toast.error(errorMessage ?? "Terjadi kesalahan");
									},
								}
							);
						}
					})}
				>
					<div>
						<div className="sm:flex sm:items-center">
							<div className="sm:flex-auto">
								<h1 className="text-3xl text-gray-900 font-medium">Akun profile</h1>
								<p className="mt-2 text-sm text-gray-700">Pengaturan data akun profile</p>
							</div>
						</div>
					</div>

					<div className="flex flex-row-reverse gap-8">
						<div>
							<div className="bg-slate-100 rounded-md py-10 w-[347px] flex items-center justify-center flex-col gap-6">
								<div className="w-[152px] bg-white rounded-full h-[152px] overflow-hidden">
									<img
										src={typeof picture_path === "object" ? URL.createObjectURL(picture_path?.[0]) : picture_path || `/img/user-profile.png`}
										alt="profilepicture"
										className="object-cover w-full h-full"
									/>
								</div>
								<div className="flex items-center justify-center flex-col">
									<label htmlFor="picture_path" className="cursor-pointer bg-blue-600 text-white text-sm px-4 py-2 rounded-md">
										Upload gambar profile
									</label>
									<input type="file" id="picture_path" name="picture_path" {...register("picture_path")} accept="image/jpeg, image/png" className="invisible w-0 h-0" />
								</div>
							</div>
						</div>
						<div className="flex-1">
							<div className="space-y-8 border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:pb-0">
								<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
									<label htmlFor="firstname" className="block text-sm font-medium leading-8 text-gray-900 sm:pt-1.5">
										Titel
									</label>
									<div className="mt-2 sm:col-span-2 sm:mt-0">
										<select
											{...register("title")}
											className={`block w-full rounded-md shadow-sm outline-none sm:max-w-xs sm:text-sm ${
												errors?.title?.message ? `border-red-500` : "border-gray-300"
											} disabled:bg-gray-200`}
										>
											<option value={null} disabled>
												Pilih title
											</option>
											{["Mr.", "Mrs."].map((v: string, i) => (
												<option key={`${v}-${i}-title`} value={v}>
													{v}
												</option>
											))}
										</select>
										{errors?.title?.message ? <p className="text-red-500 text-xs italic mt-2">{errors?.title?.message}</p> : ""}
									</div>
								</div>
								<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
									<label htmlFor="firstname" className="block text-sm font-medium leading-8 text-gray-900 sm:pt-1.5">
										Nama depan
									</label>
									<div className="mt-2 sm:col-span-2 sm:mt-0">
										<input
											id="firstname"
											name="firstname"
											type="text"
											autoComplete="firstname"
											{...register("firstname")}
											className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs md:max-w-none sm:text-sm sm:leading-8"
										/>
										{errors?.firstname?.message ? <p className="text-red-500 text-xs italic mt-2">{errors?.firstname?.message}</p> : ""}
									</div>
								</div>
								<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
									<label htmlFor="lastname" className="block text-sm font-medium leading-8 text-gray-900 sm:pt-1.5">
										Nama belakang
									</label>
									<div className="mt-2 sm:col-span-2 sm:mt-0">
										<input
											id="lastname"
											name="lastname"
											type="text"
											autoComplete="lastname"
											{...register("lastname")}
											className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs md:max-w-none sm:text-sm sm:leading-8"
										/>
										{errors?.lastname?.message ? <p className="text-red-500 text-xs italic mt-2">{errors?.lastname?.message}</p> : ""}
									</div>
								</div>
								<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
									<label htmlFor="lastname" className="block text-sm font-medium leading-8 text-gray-900 sm:pt-1.5">
										Nomor Handphone
									</label>
									<div className="mt-2 sm:col-span-2 sm:mt-0">
										<MobileNumberComponent
											title={""}
											id={"mobile_number"}
											setValue={setValue}
											getValues={getValues}
											name={"mobile_number"}
											errors={(errors?.mobile_number?.message as string) || undefined}
										/>
									</div>
								</div>
								<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
									<label htmlFor="lastname" className="block text-sm font-medium leading-8 text-gray-900 sm:pt-1.5">
										Alamat Email
									</label>
									<div className="mt-2 sm:col-span-2 sm:mt-0">
										<input
											id="email"
											name="email"
											type="email"
											autoComplete="email"
											{...register("email")}
											className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs md:max-w-none sm:text-sm sm:leading-8"
										/>
										{errors?.email?.message ? <p className="text-red-500 text-xs italic mt-2">{errors?.email?.message}</p> : ""}
									</div>
								</div>

								{Permission(userDetails?.data?.data?.roles, [2]) && data?.id !== userDetails?.data?.data?.id ? (
									<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
										<label htmlFor="lastname" className="block text-sm font-medium leading-8 text-gray-900 sm:pt-1.5">
											Hak Akses
										</label>
										<div className="mt-2 sm:col-span-2 sm:mt-0">
											<select
												{...register("role")}
												className={`block w-full rounded-md shadow-sm outline-none sm:max-w-xs sm:text-sm ${
													errors?.title?.message ? `border-red-500` : "border-gray-300"
												} disabled:bg-gray-200`}
											>
												<option value={null} disabled>
													Pilih Hak Akses
												</option>
												{role?.data?.data?.map((v, i) => (
													<option key={`${v?.id}-${i}-role`} value={v.id}>
														{v.title}
													</option>
												))}
											</select>
											{errors?.role?.message ? <p className="text-red-500 text-xs italic mt-2">{errors?.role?.message}</p> : ""}
										</div>
									</div>
								) : (
									""
								)}

								{data?.id ? (
									""
								) : (
									<>
										<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
											<label htmlFor="lastname" className="block text-sm font-medium leading-8 text-gray-900 sm:pt-1.5">
												Password
											</label>
											<div className="mt-2 sm:col-span-2 sm:mt-0">
												<TextComponent
													title={""}
													id={`password`}
													register={register(`password`)}
													name={`password`}
													type={"password"}
													errors={(errors?.password?.message as string) || undefined}
												/>
											</div>
										</div>
										<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
											<label htmlFor="lastname" className="block text-sm font-medium leading-8 text-gray-900 sm:pt-1.5">
												Konfirmasi password
											</label>
											<div className="mt-2 sm:col-span-2 sm:mt-0">
												<TextComponent
													title={""}
													id={`password_confirmation`}
													register={register(`password_confirmation`)}
													name={`password_confirmation`}
													type={"password"}
													errors={(errors?.password_confirmation?.message as string) || (errors?.["confirm"]?.message as string) || undefined}
												/>
											</div>
										</div>
									</>
								)}
								<div className="flex pt-6 items-center justify-center w-full">
									<button
										disabled={loadingPutUser || loadingPostUser}
										type="submit"
										className="rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 disabled:cursor-not-allowed disabled:bg-blue-200"
									>
										{loadingPutUser || loadingPostUser ? <Loading /> : data?.id ? "Perbaharui data profile" : "Tambah data profile"}
									</button>
								</div>
							</div>
						</div>
					</div>
				</form>
			)}
		</>
	);
};
