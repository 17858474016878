import { DATE_FORMAT_DD_MMM_YYYY } from "src/constants";
import { News } from "src/interface";
import { _getDate, _getTime } from "src/utils";

interface NewsCardProps {
	news: News;
	_onReadMoreClick: (data) => void;
}

export const NewsCard = ({ news, _onReadMoreClick }: NewsCardProps) => {
	return (
		<div key={news.id}>
			<div className="h-60 mb-5 relative bg-slate-300">
				<img
					className="absolute w-full h-full top-0 left-0 object-cover"
					src={news.image_url}
					alt={news.title}
				/>
			</div>
			<p className="text-sm uppercase text-gray-400 font-medium mb-2">
				{_getDate(news?.date, "", DATE_FORMAT_DD_MMM_YYYY)} {_getTime(news?.date, "")}
			</p>
			<h3 className="font-medium line-clamp-2">{news.title}</h3>
			<p className="text-gray-600 mb-5 line-clamp-4">{news.description}</p>
			<button
				type="button"
				onClick={() => _onReadMoreClick(news)}
				className="inline-flex items-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
			>
				Read more
			</button>
		</div>
	);
};
