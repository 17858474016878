import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface LoadingProps {
  title?: string;
  className?: string;
}

export const Loading = ({ title, className }: LoadingProps) => {
  return (
    <div className={className}>
      <FontAwesomeIcon
        icon={faSpinnerThird as IconProp}
        className="animate-spin mr-1"
      />
      {title || "Sebentar, loading dulu..."}
    </div>
  );
};
