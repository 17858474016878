export const shortCurrency = (value: number) => {
	if (isNaN(+value)) return 0;

	const nValue = +value;

	if (nValue >= 1e14) {
		return (nValue / 1e12)?.toFixed(0) + " T";
	} else if (nValue >= 1e12) {
		return (nValue / 1e12)?.toFixed(0) + " T";
	} else if (nValue >= 1e9) {
		return (nValue / 1e9)?.toFixed(0) + " B";
	} else if (nValue >= 1e6) {
		return (nValue / 1e6)?.toFixed(0) + " M";
	} else if (nValue >= 1e3) {
		return (nValue / 1e3)?.toFixed(0) + " K";
	} else {
		return nValue?.toFixed(0);
	}
};
